import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, SpaceBetween, Button, FormField, Input, Textarea, Tabs, Link, Spinner, TextContent,
} from '@amzn/awsui-components-react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Breadcrumbs, Navigation } from '../commons/navigation';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT, SET_ROLE_GUIDELINES_OTHER_ROLE } from '../global-state';
import Overview from '../pdp/Overview';
import AssessmentCategory from './AssessmentCategory';
import { API } from 'aws-amplify';
import { fetchGuildelines, setNotificationHelper } from '../commons/utils';
import DashboardTab from './DashboardTab';

export default function AssessmentView()  {

    const [navOpen, setNavOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const globalState = useContext(GlobalStateContext)
    let employeeId = globalState.viewas_user_id ? globalState.viewas_user_id : 'current';
    //if (!employeeId) employeeId = globalState.user.userNameClean();
    const isManager = globalState.user.isManager() || globalState.user.isAdmin();;


    /*useEffect(() => {
        if (!globalState.assessmentId && !isManager){
            navigate('/assessment/self');
            return (<></>);
        }
    }, []);*/

    const activeHref = '#/assessment';

    let content = <Content globalState={globalState} employeeId={employeeId} />;
    if (isManager && !employeeId){
        content = <TextContent>Error: No employee ID provided</TextContent>
    }

    if (!globalState.role_guidelines || !globalState.role_guidelines.guidelines) return (<Spinner />);


    return (
        <AppLayout
          navigation={<Navigation activeHref={activeHref} />}
          breadcrumbs={<Breadcrumbs items={ [{ text: 'My dashboard', href: '#/assessment' }] } />}
          content={content}
          tools={<Tools />}
          navigationOpen={navOpen}
          onNavigationChange={({detail}) => {
            setNavOpen(detail.open);
          }}
          headerSelector="#appHeader"
          stickyNotifications={true}
          notifications={ <Flashbar items={globalState.alerts || []} /> }
        />
    )  


}


const Content = ({globalState, employeeId}) => {

    const [tabs, setTabs] = useState([]);
    const [activeUserId, setActiveUserId] = useState(null);
    const [activeAssessmentId, setActiveAssessmentId] = useState(null);
    const [activeRoleGuidelines, setActiveRoleGuidelines] = useState(null);
    const [ready, setReady] = useState(false);  //set to true when we have both assessmentId and roleGuidelines ready
    const [reloadTrigger, setReloadTrigger] = useState({});
    const [randReload, setRandReload] = useState(Math.random());
    const [loading, setLoading] = useState(true);
    const [randReloadExpectationsTable, setRandReloadExpectationsTable] = useState(Math.random());
    
    const dispatch = useContext(GlobalDispatchContext)

    //start with default guidelines and categories (if manager will ask for different role/level - it will be reloaded later)
    const initialState = {}
    const initialCategories = globalState.role_guidelines.guidelines.map(gc => { return { label: gc.text, value: gc.id, } });
    initialCategories.push({ label: 'Personal', value: 'PERSONAL', })
    initialCategories.forEach(c => {
        initialState[c.value] = [];
    });
    const [categoryItems, setCategoryItems] = useState(initialState);
    const [activeCategories, setActiveCategories] = useState(initialCategories);

    const navigate = useNavigate();

    const isManager = globalState.user.isManager() || globalState.user.isAdmin();

    const ltabs = [/*{
        label: 'Overview',
        id: 'overview',
        content: <Overview />
    }*/];

    const fixCategories = (guidelines) => {
        //fix the active category and category items
        const ci = {}
        const categs = guidelines.map(gc => { return { label: gc.text, value: gc.id, } });
        if (categs.filter(c => c.value==='PRESONAL').length==0) categs.push({ label: 'Personal', value: 'PERSONAL', })
        setActiveCategories(categs);
        categs.forEach(c => {
            ci[c.value] = [];
        });
        setCategoryItems(ci);        
    }

    const breakWithError = (errorMessage) => {
        //alert(errorMessage);
        setNotificationHelper('error', errorMessage , dispatch, 'An error has occured');
        setReady(true);
    }

    const reloadAssessment = () => {
        //load the assessment (we assume we have one, otherwise the app redirects to self assessment screen)
        //console.log(isManager, employeeId);
        let user_id = 'current';
        let assessment_id = globalState.assessmentId;
        if (isManager && employeeId!='current'){
            console.log("Manager asking to see the assessment of " + employeeId);
            //we need to get this employee's assessment ID, and the guidelines for their level
            user_id = employeeId;            
            //get the assessment ID for this employee
            API.get('TikaAPI', `employees/${employeeId}/assessments`, {}).then(item => {
                const a = item && item.length>0 ? item[0] : null;
                const aid = a ? a.id : null;
                if (aid) {
                    setActiveAssessmentId(aid);
                } else {
                    console.log("ERROR: No is in assessment!");
                    breakWithError(`No assessment found for ${employeeId}`);
                    return;
                }
                //make sure the role guidelines for this role and level are in global cache, and if not - load it
                if (a && a.roleLevel){
                    if (a.roleLevel != globalState.roleGuidelinesDefaultRoleLevel){
                        if (!globalState.otherRoleGuidelines[a.roleLevel]){
                            //console.log(`${a.roleLevel} is not in cache, need to load`);
                            const t = a.roleLevel.split('_');
                            fetchGuildelines(t[0], t[1]).then(guidelines => {
                                //cache globally
                                dispatch({type: SET_ROLE_GUIDELINES_OTHER_ROLE, payload: {
                                    roleLevel: a.roleLevel,
                                    data: guidelines,
                                  }
                                });
                                //console.log("SET_ROLE_GUIDELINES_OTHER_ROLE dispatched", guidelines);
                                //cache locally
                                setActiveRoleGuidelines(guidelines);
                                //fix categories
                                fixCategories(guidelines.guidelines);
                            }).catch(e => console.log(e));
                        
                        } else {
                            //cache locally
                            setActiveRoleGuidelines(globalState.otherRoleGuidelines[a.roleLevel]);
                            //fix categories
                            fixCategories(globalState.otherRoleGuidelines[a.roleLevel].guidelines);
                        }
                    } else {
                        //it is the same as default (the one already loaded) - set accordingly
                        setActiveRoleGuidelines(globalState.role_guidelines);
                    }
                } else {
                    console.log("ERROR: No roleLevel in assessment!");
                    breakWithError(`No roleLevel in assessment`);
                    return;
                }
                //


            });
        } else {
            setActiveAssessmentId(assessment_id);
            setActiveRoleGuidelines(globalState.role_guidelines);
        }
        setActiveUserId(user_id);
        
    };

    useEffect(() => {
        reloadAssessment();
    }, [globalState.assessmentId]);
  

    useEffect(()=>{
        if (activeUserId && activeAssessmentId && activeRoleGuidelines){
            setReady(true);
            setLoading(false);
        }
    }, [activeUserId, activeAssessmentId, activeRoleGuidelines, randReload]);

    useEffect(()=>{
        if (!ready) return ()=>{};

        if (!activeAssessmentId && !isManager) {
            console.log('Error: no assessment ID found! Redirecting to self assessment');
            navigate('/assessment/self');
            return () => {};
        }

        API.get('TikaAPI', `employees/${activeUserId}/assessments/${activeAssessmentId}/assessment-items`, {}).then(a => {
            //get the custom targets (Expectations)
            //console.log(a);
            const expectations = a.filter(i => (i.refItemId=='CUSTOM_TARGET' || i.refItemId=='EXPECTATION'));
            //console.log('expectations:', expectations);
            //console.log('haveriko', a.filter(i => i.refItemId=='SA_6_CUAD_BRG_OPT'));

            //call the API to get achievement count per guideline items type
            //API.get('TikaAPI', 'achievements/counters', {}).then(counters => {
            API.get('TikaAPI', `employees/${activeUserId}/counters`, {}).then(counters => {                
                //console.log('counters',counters, activeCategories);
                let tempItems = {};
                let categoryLabels = {};
                activeCategories.forEach(c => {
                    //get the items for this category:
                    categoryLabels[c.value] = c.label;
                    const items = a.filter(i => (i.refItemId!='CUSTOM_TARGET' && i.refItemId!='EXPECTATION' && c.value===activeRoleGuidelines.itemToInfoMap[i.refItemId].categoryId)).map(i => { 
                        //console.log('CCC', counters, i.refItemId, counters[i.refItemId]);
                        return {
                            assessmentItemId: i.id,
                            guidelineItem: activeRoleGuidelines.itemToInfoMap[i.refItemId].itemText,
                            guidelineItemId: i.refItemId,
                            unreviewedArtifacts: counters[i.refItemId] ? counters[i.refItemId].unreviewed : 0,
                            underReviewArtifacts: counters[i.refItemId] ? counters[i.refItemId].underReview : 0,
                            supportingArtifacts: counters[i.refItemId] ? counters[i.refItemId].promoted : 0,
                            newArtifacts: counters[i.refItemId] ? counters[i.refItemId].newItems : 0,
                            rank: i.rank ? i.rank.score : 0,
                            notes: i.notes,
                            isTarget: i.tags && i.tags.target && i.tags.target===true ? true : false,
                            actionPlan: i.actionPlan,
                        }
                    });
                    if (items.length==0){
                        items.push({empty: true});
                    } else {
                        //console.log('not empty,', items.filter(i => !i.guidelineItemId=='SA_6_CUAD_BRG_OPT'));
                    }
                    tempItems[c.value] = items;
                }); //end of for loop
                tempItems['CUSTOM_TARGET'] = expectations;
                tempItems['CATEGORY_LABELS'] = categoryLabels;
                setCategoryItems( tempItems );
       
            }).catch(err => {console.error(err)});
        }).catch(err => {console.log(err);});
    }, [ready]);

    const buildTabs = () => {
        ltabs.length=0;
        activeCategories.forEach(c => {
            ltabs.push({
                label: c.label,
                id: c.value,
                content: <DashboardTab key={c.value} category={c.value} globalState={globalState} 
                    categoryItems={categoryItems} setCategoryItems={setCategoryItems}
                    currentAssessmentId={activeAssessmentId} loading={loading}
                    categoryCode={c.value} setReloadTrigger={setReloadTrigger} />
            });    
        });
        setTabs(ltabs);
    }

    useEffect(()=>{
        buildTabs();
    },[categoryItems]);


    useEffect(() => {
        if (reloadTrigger && reloadTrigger.reload) {
          setLoading(true);
          setRandReloadExpectationsTable(Math.random()); //for employees table
          setReady(false);
          setRandReload(Math.random()); //for all tab reload
          reloadAssessment();
        }
      }, [reloadTrigger])

    //console.log('categoryItems:', categoryItems);
    
    let header_suffix = '';
    if (isManager && globalState.viewas_user_id) header_suffix = ` (for user ${globalState.viewas_user_id})`
    
    useEffect(() => {
        buildTabs();
    }, [randReloadExpectationsTable])

    return (
        <Container header={
            <Header variant="h2" description="Your Tika dashboard" /*actions={
                <Button onClick={()=>{
                    setLoading(current => {console.log(!current); return !current});
                    setRandReloadExpectationsTable(Math.random());
                    console.log('changed loading to', loading);
                    
                }}>T</Button>}*/
             >
              My Dashboard {header_suffix}
            </Header>
          }
        >
            <Tabs
                tabs={tabs}
            ></Tabs>

        </Container>
    )
}


const Tools = () => (
    <HelpPanel header={<h2>My Dashboard</h2>}>
      <p>Dashboard that summarizes your expectations, deliverables and the results of you self assessment</p>
    </HelpPanel>
  );
  