import React, { useState, useEffect, useContext, useCallback, useReducer } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, ExpandableSection,
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { DataStoreClass } from '@aws-amplify/datastore';
import {API} from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import ActionItemsModal from './ActionItemsModal';


/**
 * Determine whether the given `input` is iterable.
 *
 * @returns {Boolean}
 */
function isIterable(input) {  
  if (input === null || input === undefined) {
    return false
  }

  return typeof input[Symbol.iterator] === 'function'
}


const cloneCategoryItems = (ci) => {
  let newObj = {};
  for (var prop in ci) {
    if (Object.prototype.hasOwnProperty.call(ci, prop)) {
      if (isIterable(ci[prop])){
        newObj = {...newObj, [prop]: [...ci[prop]]};
      } else {
        //this is probably the 'CATEGORY_LABELS' property, clone it differently
        newObj = {...newObj, [prop]: ci[prop]};
      }
    }
  }
  return newObj;
}

export default function AssessmentCategory({category, globalState, categoryItems, setCategoryItems, currentAssessmentId})  {

      const [modalVisible, setModalVisible] = useState(false);
      const [activeAssessmentId, setActiveAssessmentId] = useState(null);
      const [activeAssessmentItemId, setActiveAssessmentItemId] = useState(null);
      const [currentItem, setCurrentItem] = useState({});

      if (categoryItems[category].length==0){
        //console.log("not yet...", categoryItems);
        return (<Spinner />);
      } else {
        //console.log("now ok...");
      }

      //<ColumnLayout columns={5}>
      /*
      <Grid gridDefinition={[
        { colspan: {default: 12, xs: 6, s: 4, m: 3, l: 2} },
        { colspan: {default: 12, xs: 6, s: 4, m: 3, l: 2} },
        { colspan: {default: 12, xs: 6, s: 4, m: 3, l: 2} },
        { colspan: {default: 12, xs: 6, s: 4, m: 3, l: 2} },
        { colspan: {default: 12, xs: 6, s: 4, m: 3, l: 2} },
      ]} >
    */

    //let items = generateItems(category, globalState);
    let assessmentId = currentAssessmentId;


    const showActionItemsModal = (assessmentId, assessmentItemId, guidelineItem) => {
      setActiveAssessmentId(assessmentId);
      setActiveAssessmentItemId(assessmentItemId);
      setCurrentItem(guidelineItem);
      setModalVisible(true);
    }

    const clearModalStateValues = () => {
      setActiveAssessmentItemId(null);
    }

    const closeModal = () => {
      clearModalStateValues();
      setModalVisible(false);
    }

    const updateItemsState = (assessmentItemId, newActionItems) => {
      const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
      if (itemIndex<=-1) return false;
      categoryItems[category][itemIndex].actionPlan = newActionItems;
      setCategoryItems(cloneCategoryItems(categoryItems));
    }

    /*const saveActionItems = () => {
      const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(activeAssessmentItemId);
      if (itemIndex<=-1) return false;
      let marker = {
          tags: {
            target: categoryItems[category][itemIndex].isTarget,
            actionItems: tempActionItems,
          }
        }
      API.patch('TikaAPI', 'employees/current/assessments/' + activeAssessmentId + '/assessment-items/' + activeAssessmentItemId, { body: marker }).then(resp => {
        categoryItems[category][itemIndex].actionPlan = tempActionItems;
        setCategoryItems(cloneCategoryItems(categoryItems));
        closeModal();
      }).catch(error => {console.error(error); clearModalStateValues(); });
    }*/

    return (
      <>

        <ActionItemsModal category={category} globalState={globalState}
                  categoryItems={categoryItems} setCategoryItems={setCategoryItems}
                  modalVisible={modalVisible} setModalVisible={setModalVisible}
                  currentItem={currentItem} updateItemsState={updateItemsState} />        

        <div className='container'>
          {[1,2,3,4,5].map(i => { return (
            <ScoreColumn category={category} score={i} key={i}
                  assessmentId={assessmentId} categoryItems={categoryItems} setCategoryItems={setCategoryItems}
                  showActionItemsModal={showActionItemsModal} />
          )})}
        </div>
      </>

    
      
    )
}


const ScoreColumn = ( {category, score, assessmentId, categoryItems, setCategoryItems, showActionItemsModal} ) => {

  let scoreToTitle = (score) => {
    switch (score) {
      case 1: return 'Not at All';
      case 2: return 'Very Little';
      case 3: return 'Somewhat';
      case 4: return 'Regularly';
      case 5: return 'To a Great Extent';
      default:
        return 'Unknown score';
    }
  }

  return (
      <Container className='column'
        header={<Header variant="h6">
                    {scoreToTitle(score)}
                </Header>
        }
      >

        <GuidelineColumn category={category} score={score} assessmentId={assessmentId} 
            categoryItems={categoryItems} setCategoryItems={setCategoryItems}
            showActionItemsModal={showActionItemsModal} />
        
      </Container>
  )
}


const GuidelineColumn = ({category, score, assessmentId, categoryItems, setCategoryItems, showActionItemsModal }) => {

  const navigate = useNavigate();


  const changeScore = useCallback((assessmentId, assessmentItemId, newScore) => {
    //update backend
    API.patch('TikaAPI', 'employees/current/assessments/' + assessmentId + '/assessment-items/' + assessmentItemId,
      { body: {
          rank: {
            score: newScore
          }
       }
      }).then(resp => {
        //update the state so that UI will reflect the change
        const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
        if (itemIndex<=-1) {
          console.log('item index:', itemIndex, categoryItems, categoryItems[category]);
          return false;
        }
        let tmp = categoryItems;
        tmp[category][itemIndex].rank = newScore;
        setCategoryItems(cloneCategoryItems(tmp));        
    }).catch( error => {console.error(error);} );
  
  }, []);

  const setTarget = (assessmentId, assessmentItemId, target=true) => {
    const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
    if (itemIndex<=-1) return false;
    let marker = {
        tags: {
          target: target,
        }
      }
    API.patch('TikaAPI', 'employees/current/assessments/' + assessmentId + '/assessment-items/' + assessmentItemId, { body: marker }).then(resp => {
      categoryItems[category][itemIndex].isTarget = target;
      setCategoryItems(cloneCategoryItems(categoryItems));        
    }).catch( error => {console.error(error);} );
  }


  const showActionItems = (assessmentId, assessmentItemId) => {
    const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
    if (itemIndex<=-1) return false;
    const ap = categoryItems[category][itemIndex];
    console.log(ap);
    showActionItemsModal(assessmentId, assessmentItemId, ap);
  }
  
  const addAchievement = (assessmentId, assessmentItemId) => {
    const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
    if (itemIndex<=-1) return false;
    const ap = categoryItems[category][itemIndex];
    navigate(`/achievements/achievement`, {state:{ guidelineItemFilter: ap.guidelineItemId}} ) ;
  }

  return (
    <Cards
      cardDefinition={{
        header: item => { 
          
          let achievementCounters = <TextContent className='fadedHeader'><small>No achievements</small></TextContent>;
          const atLeastOne = item.unreviewedArtifacts || item.underReviewArtifacts || item.supportingArtifacts;
          if (atLeastOne) {
            achievementCounters = 
            <div style={{position: 'relative'}}>
                <SpaceBetween direction='horizontal' size='xxs'>
                    {/*<div title='Unreviewed Artifacts'><Badge color='grey' >{item.unreviewedArtifacts}</Badge></div>*/}
                    <div title='Artifacts Under Review'><Badge color='blue'>{item.underReviewArtifacts}</Badge></div>
                    <div title='Supporting Artifacts'><Badge color='green'>{item.supportingArtifacts}</Badge></div>
                    {item.newArtifacts>0 && <TextContent className='fadedHeader'><small className="red">New!</small></TextContent>}
                    <a href="/#/achievements" onClick={(e)=> {
                      e.preventDefault();
                      console.log(item);
                      navigate(`/achievements`, {state:{ guidelineItemFilter: item.guidelineItemId }} ) ;
                    }}><span className="defaultLink"></span></a>
                </SpaceBetween>
                </div>
          }
          //console.log("item:::", item.notes);
          return (
            <div>
              <div style={{float: 'right', marginTop: '-10px', marginRight: '-15px'}}>
                
                <SpaceBetween direction='horizontal' size='xxs'>
                  {achievementCounters}
                  <ButtonDropdown
                    items={[
                      { text: 'Actions', 
                        items: [
                          /*item.isTarget ? { id: "unsetTarget", text: "Clear Target" } : { id: "setTarget", text: "Set As Target" },
                          { id: "setActionItems", text: "Set Action Items" },*/
                          //{ id: "addExpectation", text: "Add New Expectation" },
                          { id: "addAchievement", text: "Add New Deliverable" },
                        ]
                      },
                      { text: 'Move', 
                        items: [
                          { id: "moveLeft", text: "Move Left", disabled: score==1 },
                          { id: "moveRight", text: "Move Right", disabled: score==5 },
                        ]
                      }
                    ]}
                    ariaLabel="Update Item"
                    variant="icon"
                    onItemClick={ ({detail}) => {
                        switch (detail.id) {
                          case 'moveLeft': changeScore(assessmentId, item.assessmentItemId, score-1); break;
                          case 'moveRight': changeScore(assessmentId, item.assessmentItemId, score+1); break;
                          case 'setTarget': setTarget(assessmentId, item.assessmentItemId); break;
                          case 'unsetTarget': setTarget(assessmentId, item.assessmentItemId, false); break;
                          case 'setActionItems': showActionItems(assessmentId, item.assessmentItemId); break;
                          case 'addAchievement': addAchievement(assessmentId, item.assessmentItemId); break;
                          default:
                            break;
                        };
                      }
                    }
                  />

                </SpaceBetween>

              </div>
              {/*item.isTarget && 
                <div style={{float: 'left', marginTop: '-10px'}}>
                  <SpaceBetween direction='horizontal' size='xxs'>
                    <Icon name="angle-right-double" size="small" variant="success" />
                    <TextContent className='target'><small>Target!</small></TextContent>
                  </SpaceBetween>
                </div>
              */}
            </div>
        )}, //end of header
        sections: [
          {
            id: "guidelineItem",
            content: item => <div style={{paddingBottom: '5px'}}>
                <div>{item.guidelineItem}</div>
                <div style={{position: 'absolute', right: '10px', marginTop: 2}}>
                  <SpaceBetween direction='horizontal' size='xxs'>
                    {item.actionPlan && item.actionPlan.filter(e => e.status&&e.status!='archived').length>0 ? 
                        <div>
                          <a href="#" onClick={(e) => {showActionItems(assessmentId, item.assessmentItemId); e.preventDefault();}}>
                            <Icon name="contact" size="small" variant="link" />
                          </a>
                        </div>
                    : <></> }
                    {item.isTarget ? 
                      <SpaceBetween direction='horizontal' size='xxxs'>
                        <Icon name="heart" size="small" variant="link" />
                      </SpaceBetween>
                    : <></> }
                  </SpaceBetween>
                </div>
                {item.notes && 
                    <div>
                        <ExpandableSection variant="footer" header="Notes">
                            <Textarea
                                placeholder="Self assessment notes"
                                value={item.notes}
                                onChange={event => {} }
                            />
                        </ExpandableSection>                      
                    </div>
                    }
              </div>
          },
        ]
      }} //end of card definition
      cardsPerRow={[
        { cards: 1 },
        { minWidth: 4500, cards: 2 }
      ]}
      items={categoryItems[category].filter(x => x.rank===score)}
      loadingText="Loading resources"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No items in this category.
          </Box>
        </Box>
      }
    />
  )
}


