/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AppLayout,
  SideNavigation,
  Container,
  Header,
  HelpPanel,
  Grid,
  Box,
  TextContent,
  SpaceBetween,
  Tabs,
  Table,
  Button,
  BreadcrumbGroup
} from '../aws-ui-components';

import '../styles/intro.scss';
import '../styles/servicehomepage.scss';
import { Navigation } from './commons/navigation';

// This is not meant to be a template, rather it is the
// introduction page that you see upon loading the webserver.
export default function AdminEditGuidelines() {
  return (
    <AppLayout
      navigation={<Navigation />}
      content={<Content />}
      tools={<Tools />}
      disableContentPaddings={true}
      headerSelector="#appHeader"
    />
  );
}

const Content = () => (
  <div>
      <Box margin="xxl" padding="l">
          <BreadcrumbGroup
            items={[
              { text: "Admin", href: "#/admin" },
              { text: "Edit role guidelines", href: "#/admin/edit-guidelines" }
            ]}
            ariaLabel="Breadcrumbs"
          />
          <div>
            <h1>Edit role guidelines</h1>
            <Box margin = {{ top: "xl" }}>
              Hello there
            </Box>
          </div>

      </Box>
  </div>
);



const Tools = () => (
  <HelpPanel header={<h2>Help panel</h2>}>
    <p>Help content goes here</p>
  </HelpPanel>
);
