import React, { useState, useEffect, useContext, useCallback, useReducer, useRef } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, TextFilter, Pagination, DatePicker, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { DataStoreClass } from '@aws-amplify/datastore';
import {API} from 'aws-amplify';
import { useNavigate } from "react-router-dom";


export default function ExpectationModal({assessmentId, modalVisible, setModalVisible, expectation,
                                          setExpectation, setReloadTrigger, category, categoryItems,
                                          editMode, categoryCode})  {

    const globalState = useContext(GlobalStateContext)

    const [err1, setErr1] = useState('');
    const [err2, setErr2] = useState('');
    const [err3, setErr3] = useState('');
    const [loading, setLoading] = useState(false);


    const closeModal = () => {
      setErr1('');
      setModalVisible(false);
    };

    const addExpectation = () => {
      const assessmentItems = [
        {
          refItemId: "EXPECTATION",
          refItemType: "expectations",
          tags: {
            'expectation': true,
            contributionCategory: categoryCode,
            ...expectation.tags,
          },
          rank: {
            score: 5
           },
        }
      ];
      API.post('TikaAPI', 'employees/current/assessments/' + assessmentId + '/assessment-items', {body: assessmentItems}).then(items => {
        setLoading(false);
        //reload, to refresh with the new item
        setReloadTrigger({reload: true});
        closeModal();
      }).catch(error => { setLoading(false); alert('Error creating the expectation (check logs for details)'); console.log(error); });

    }

    const editExpectation = () => {
      //console.log(expectation.id, expectation.assessmentId);
      const change = {
        tags: {
          'expectation': true,
          contributionCategory: categoryCode,
          ...expectation.tags,
        }
      };
      API.patch('TikaAPI', 'employees/current/assessments/' + expectation.assessmentId + '/assessment-items/' + expectation.id, { body: change }).then(resp => {
        setLoading(false);
        //reload, to refresh with the updated item
        setReloadTrigger({reload: true});
        closeModal();
      }).catch(error => { setLoading(false); alert('Error updating the expectation (check logs for details)'); console.log(error); });
  
    }


    const getCategoryButtons = () => {
      let categ_labels = categoryItems ? categoryItems.CATEGORY_LABELS : null;
      let categ = category;
      if (!categoryItems || !categoryItems.CATEGORY_LABELS) {
        //console.log('trying from global state:', globalState.globalCategories);
        if (globalState.globalCategories) {
          categ_labels = globalState.globalCategories;
        } else {
          return <></>;
        }
      }
      if (!categ && expectation && expectation.tags && expectation.tags.contributionCategory) {
        categ = categ_labels[expectation.tags.contributionCategory];
        //console.log('eee', categ);
      }

      let categ_buttons = [];
      Object.keys(categ_labels).forEach(function(key,index) {
        //console.log(key,categoryItems.CATEGORY_LABELS[key], category);

        categ_buttons.push(
          <Button key={key}
            disabled={categ!=categ_labels[key]}
            variant={categ===categ_labels[key]?'primary':'normal'}
            onClick={(e)=>{
              e.preventDefault();
              /*if (activeCategories.includes(c.id)){
                setActiveCategories(activeCategories.filter(ca => ca!=c.id)); 
              } else {
                var tmp = activeCategories;
                tmp.push(c.id);
                setActiveCategories(tmp); 
                forceUpdate();
              }*/
            }}
          >
            
            {categ_labels[key]}
  
          </Button>
        );


      });

      return categ_buttons;
    }

    const categ_buttons = getCategoryButtons();

    return (
        <Modal
          onDismiss={() => closeModal()}
          visible={modalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => closeModal()}>Cancel</Button>
                <Button variant="primary" onClick={()=> {
                  setErr1(''); setErr2(''); setErr3('');
                  if (!expectation.tags.title){
                    setErr1('Please provide a title that describes your Expectation');
                    return;
                  } else {
                    setErr1('');
                  }
                  if (parseInt(expectation.tags.attainment)<0 || parseInt(expectation.tags.attainment)>100){
                    setErr2('Please provide a valid attainment number');
                    return;
                  } else {
                    setErr2('');
                  }
                  if (!expectation.tags.dueDate){
                    setErr3('Please provide a due date for your Expectation');
                    return;
                  } else {
                    setErr3('');
                  }
                  
                  (editMode && expectation.id && expectation.assessmentId) ? editExpectation() : addExpectation();

                }}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header={<Header description={editMode ? 'Edit your expectation': 'Create a new expectation'}>{editMode ? 'Edit' : 'Create'} Expectation</Header>}
        >

            {loading ? <Spinner />
             :            
              <Container>
                <SpaceBetween direction="vertical" size="s">

                  <FormField label="Title" description="The title for the expectation" errorText={err1}>
                    <Input placeholder="Expectation title" value={expectation.tags.title} onChange={(e) =>{
                      setExpectation({...expectation, tags: {...expectation.tags, title: e.detail.value} })
                    }} />
                  </FormField>

                  <FormField label="Description" description="Detailed description of the expectation">
                      <Textarea
                          placeholder="Expectation detailed description"
                          value={expectation.tags.description}
                          onChange={e => setExpectation({...expectation, tags: {...expectation.tags, description: e.detail.value} }) }
                      />
                  </FormField>

                <FormField label="Area of Contribution (Optional)" description="What Area of Contribution this Expectation relates to?">

                      <SpaceBetween direction="horizontal" size="xxs">
                        {categ_buttons}
                      </SpaceBetween>

                </FormField>

                  <FormField label="Due Date (Optional)" description="Optional due date for this expectation" errorText={err3}>
                    <DatePicker value={expectation.tags.dueDate} 
                      isDateEnabled={date =>
                        date.getTime() >= (new Date().getTime()-24*3600*1000)
                      }
                      onChange={(e) =>{
                        setExpectation({...expectation, tags: {...expectation.tags, dueDate: e.detail.value} })
                      }} />
                  </FormField>

                  <FormField label="Attainment" description="How much of this expectation was attained?"
                     errorText={err2} constraintText={<React.Fragment>Min: 0, max: 100</React.Fragment>} >
                        <SpaceBetween direction='horizontal' size='xxxs'>
                          <div style={{width: '100px'}}>
                            <Input placeholder="" value={expectation.tags.attainment || 0} inputMode="numeric"  onChange={(e) =>{
                              setExpectation({...expectation, tags: {...expectation.tags, attainment: e.detail.value} })
                            }}  />
                          </div>
                          <div style={{paddingTop: '5px'}}>%</div>
                        </SpaceBetween>
                        
                  </FormField>

                </SpaceBetween>

              </Container>
            }

        </Modal>

    )
}


