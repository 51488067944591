import React, { useState, useEffect, useContext } from 'react';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, SpaceBetween, Button, FormField, Input, Textarea, Tabs, Link, Spinner,
  Box, Grid, 
} from '@amzn/awsui-components-react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Breadcrumbs, Navigation } from '../commons/navigation';
import { GlobalStateContext, GlobalDispatchContext, SET_VIEWAS_USER_ID } from '../global-state';
import {API} from 'aws-amplify';
import '../../styles/assessments.scss';

export default function PeopleSelector()  {

    const navigate = useNavigate();

    const globalState = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);

    let content = <ContentSelectAboutWho globalState={globalState} dispatch={dispatch} />;

    if (!globalState.role_guidelines || !globalState.role_guidelines.guidelines) return (<Spinner />);

    return (
        <AppLayout
          navigation={<Navigation activeHref="#/manager/people" />}
          breadcrumbs={<Breadcrumbs items={ [{ text: 'People', href: '#/manager/people' }] } />}
          content={content}
          tools={<Tools />}
          headerSelector="#appHeader"
          stickyNotifications={true}
          notifications={ <Flashbar items={globalState.alerts || []} /> }
        />
    )  


}

const ContentSelectAboutWho = ({globalState, dispatch}) => {

    const employees = globalState.directReports ? globalState.directReports : [];
    //console.log(employees);
    const items = [];
    employees.forEach(employeeId => {
        const e = 
            <div key={employeeId} style={{float: 'left', marginRight: '30px'}}>
                <div style={{position: 'relative'}}>
                    <Container style={{maxWidth: '200px'}}>
                        <SpaceBetween direction='vertical' size='m'>
                            <div>
                                <img style={{height: '120px'}} src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${employeeId}`} />
                            </div>
                            <div style={{textAlign: 'center'}}>
                                {employeeId}
                            </div>
                        </SpaceBetween>
                    </Container>
                    <a href={`#/assessment/reports/${employeeId}`} onClick={(e) => {
                                    e.preventDefault();
                                    //store the assessment in global state
                                    dispatch({type: SET_VIEWAS_USER_ID, payload: employeeId});
                                }}>
                                    <span className="defaultLink"></span>                            
                                </a>
                </div>                    
            </div>
        items.push(e);
    })
        

    return (
        <Container header={
            <Header variant="h2" description='Select user to "View As"' >
              Select Employee
            </Header>
          }
        >
            <p>Select employee to &quot;View As&quot;:</p>
            <Container className='clearfix'>
                {items}
            </Container>
        </Container>
    );

}




const Tools = () => (
    <HelpPanel header={<h2>Help panel</h2>}>
      <p>Help content goes here</p>
    </HelpPanel>
  );
  