import React, { useState, useEffect, useContext, useCallback } from "react";
import { Box, Grid, Button } from "@amzn/awsui-components-react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";

import { GlobalStateContext, GlobalDispatchContext, SET_USER_GLOBAL, SET_ROLE_GUIDELINES, SET_DIRECT_REPORTS, SET_ASSESSMENT_ID } from './global-state'

import Home from "./Home.jsx";
import PerformanceReviewMain from "./PerformanceReviewMain.jsx";
import PerformanceReviewCreateReview from "./PerformanceReviewCreateReview.jsx";
import AdminMain from "./AdminMain.jsx";
import AdminEditGuidelines from "./AdminEditGuidelines.jsx";
import AchievementsTable from './achievements/AchievementsTable';
import Achievement from './achievements/Achievement';


import "@amzn/awsui-global-styles/polaris.css";

import { Auth, Hub, API, Amplify, Analytics, formSection, googleSignInButton } from "aws-amplify";

import config from "../config/aws-exports";
import {isInGroup, setNotificationHelper, fetchGuildelines} from './commons/utils';
import AssessmentView from "./assessment/AssessmentView";
import SelfAssessment from "./assessment/wizard/SelfAssessment";
import { User } from './commons/common-components';
import DevelopmentPlan from "./pdp/DevPlan";
import Insights from "./manager/Insights";
import PeopleSelector from "./manager/PeopleSelector";
import { resourceManageTagsBreadcrumbs } from "./commons/breadcrumbs";
import Cookies from 'js-cookie';

Amplify.configure(config);
Amplify.API._restApi._api._options.endpoints.forEach(ep=> {
  ep['custom_header'] = async () => { return { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` } };
});
Analytics.enable();

//window.LOG_LEVEL = 'DEBUG';

/*
var open = window.XMLHttpRequest.prototype.open,  
  send = window.XMLHttpRequest.prototype.send;

function openReplacement(method, url, async, user, password) {  
  this._url = url;
  return open.apply(this, arguments);
}

function sendReplacement(data) {  
  if(this.onreadystatechange) {
    this._onreadystatechange = this.onreadystatechange;
  }
 
  console.log('Request sent');
  
  this.onreadystatechange = onReadyStateChangeReplacement;
  return send.apply(this, arguments);
}

function onReadyStateChangeReplacement() {  
  
  console.log('Ready state changed to: ', this.readyState);
  /*
    0: request not initialized
    1: server connection established
    2: request received
    3: processing request
    4: request finished and response is ready  
  
  if(this._onreadystatechange) {
    return this._onreadystatechange.apply(this, arguments);
  }
}

window.XMLHttpRequest.prototype.open = openReplacement;  
window.XMLHttpRequest.prototype.send = sendReplacement;
*/
//fix the API configuration
//Amplify.API.endoit AchievementsAPI

const AppMain = () => {

  const [user, setUser] = useState(null);
  const [roleLevelSelected, setRoleLevelSelected] = useState(Cookies.get('currentRoleName')!=undefined);

  const globalState = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  
  const navigate = useNavigate();

  const notif = () => setNotificationHelper('error', 'Problem loading FDs/LPs/role guidelines!', dispatch, 'Error initializing application configuration');

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => {
            setUser(userData); //set locally
          });
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then(async (userData) => {
      //console.log(userData);
      setUser(userData); //set locally
      //console.log(userData);
      let userObject = new User(userData);
      dispatch({type: SET_USER_GLOBAL, payload: userObject}) //set globally
      //fix employees if manager
      setEmployees(userObject, dispatch);
      //cache global config (like role guidelines, functional dimensions, LPs)
      //console.log('start caching', new Date());

    });
  }, []);

  useEffect(() => {
    async function init() {
      await cacheGlobalConfig(globalState.user, globalState).then((res) => {
        //console.log('end caching', new Date());
        //check if there is already an assessment. if not - redirect to self assessment page
      }).catch((err) => { notif()} );    
    }

    console.log('Initializing app config');  
    init();
  }, [roleLevelSelected]);


  useEffect(() => {
    async function next(){
      if (!await assessmentExists(globalState.user)){
        //console.log('Should redirect to self assessment!');
        //navigate('/assessment/self');
      }  
    }    
    next();
  }, [roleLevelSelected]);


  const setEmployees = (user, dispatch) => {
    if (user.isManager() || isInGroup(user, 'Admin')){
      const clean = user.userNameClean();
      API.get('TikaAPI', 'employees/' + clean + '/reports', {}).then(item => {
        if (item && item.Item && item.Item.reports.length>0){
          dispatch({type: SET_DIRECT_REPORTS, payload: item.Item.reports}) //set globally
        }
      }).catch(error => { notif(); console.log(error); });    
    }
  }

  const assessmentExists = async (user) => {
    const x = await API.get('TikaAPI', 'employees/current/assessments', {}).then(item => item);
    const res = x && x.length>0;
    if (res) {
      //store the assessment in global state
      dispatch({type: SET_ASSESSMENT_ID, payload: x[0].id});
    }
    return res;
  }


  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => {return userData})
      .catch((error) => {console.log("Not signed in"); throw error;});
  }

  function cacheGlobalConfig(user, globalState){

    const role = Cookies.get('currentRoleName');
    const level = Cookies.get('currentRoleLevel');
    const roleLevel = role + '_' + level;

    //first check, if already exists - return;
    if (globalState.role_guidelines) return new Promise((resovle, reject) => resovle(true));

    //if we don't have role and level selected, reject
    if (roleLevelSelected==undefined || !role) return new Promise((resovle, reject) => resovle(false));

    return new Promise((resovle, reject) => {
      fetchGuildelines(role, level).then(guidelines => {
        dispatch({type: SET_ROLE_GUIDELINES, payload: {
            roleLevel: roleLevel,
            data: guidelines,
          }
        });
        console.log("SET_ROLE_GUIDELINES dispatched", guidelines);
      }).catch(e => reject(e));
    });
      

      /*
      API.get('TikaAPI', 'guidelines/role/' + config.defaultRole, {}).then(response => {
        if (response){ console.log(response);
          dispatch({type: SET_ROLE_GUIDELINES, payload: response});
        } else {
          notif();
        }
      }).catch(error => {
        notif();
        console.log(error.response);
      });*/

  }


  let content =  <div>
                  <Routes>
                    <Route path="/" element={<Home />} />

                    {roleLevelSelected && 
                      <>
                        <Route path="/achievements" element={<AchievementsTable />} />
                        <Route path="/achievements/achievement" element={<Achievement />} >
                          <Route path=":achievementId" element={<Achievement />} />
                        </Route>
                        <Route path="/achievements/reports" element={<AchievementsTable />} />
                        
                        <Route path="/assessment" element={<AssessmentView />} />
                        <Route path="/assessment/self" element={<SelfAssessment />} />
                        <Route path="/assessment/reports" element={<AssessmentView />} >
                          { /*<Route path=":employeeId" element={<AssessmentView />} /> */}
                        </Route>

                        <Route path="/manager/insights" element={<Insights />} />
                        <Route path="/manager/people" element={<PeopleSelector />} />
                    
                        <Route path="/pdp" element={<DevelopmentPlan />} />
                      </>
                    }


                  </Routes>
              </div>

  
  if (!globalState.user){
    content = <Grid gridDefinition={[{ colspan: 6, offset: 3 }]}>
                <Box className="login-form" textAlign="center" color="inherit">
                  <h1 color="#ff9900;">Welcome to TIKA - the Personal Development Hub</h1>
                  <Button
                    variant="primary"
                    onClick={() =>
                      Auth.federatedSignIn({ provider: "AMZN" })
                    }
                  >
                    Login With Amazon Midway
                  </Button>
                </Box>
              </Grid>;
  }


  return (
    <div>
      {content}
    </div>
  );
};


export default AppMain;
