import React from 'react';
import {
  Alert,
  AppLayout,
  Box,
  Link,
  Button,
  Header,
  SpaceBetween,
  SideNavigation,
  Badge,
  Icon,
  Flashbar
} from '@amzn/awsui-components-react';
import { appLayoutLabels, externalLinkProps } from './labels';
import { useNotifications } from './use-notifications';
import { getHeaderCounterText, getServerHeaderCounterText } from './tableCounterStrings';

export const navHeader = { text: 'CloudFront', href: '#/' };
export const navItems = [
  {
    type: 'section',
    text: 'Reports and analytics',
    items: [
      { type: 'link', text: 'Distributions', href: '#/distributions' },
      { type: 'link', text: 'Cache statistics', href: '#/cache' },
      { type: 'link', text: 'Monitoring and alarms', href: '#/monitoring' },
      { type: 'link', text: 'Popular objects', href: '#/popular' },
      { type: 'link', text: 'Top referrers', href: '#/referrers' },
      { type: 'link', text: 'Usage', href: '#/usage' },
      { type: 'link', text: 'Viewers', href: '#/viewers' }
    ]
  },
  {
    type: 'section',
    text: 'Private content',
    items: [
      { type: 'link', text: 'How-to guide', href: '#/howto' },
      { type: 'link', text: 'Origin access identity', href: '#/origin' }
    ]
  }
];



const SIMULATE_MANAGER = false;

export function User(user) {
  this.attributes = SIMULATE_MANAGER ?
  {...user.attributes, ['custom:isManager']: 0}
  :
  user.attributes;
  this.username = user.username;
  this.idTokenPayload = user.signInUserSession.idToken.payload;
}
User.prototype.isManager = function() {return this && this.attributes && parseInt(this.attributes['custom:isManager'])===0};
User.prototype.userNameClean = function() {return this.username.split('_')[1]};
User.prototype.isInGroup = function(group) {return this.idTokenPayload['cognito:groups'].includes(group)};
User.prototype.isAdmin = function() {return this.isInGroup('Admin')};


function getCounter(props) {
  if (props.counter) {
    return props.counter;
  }
  if (!props.totalItems) {
    return null;
  }
  if (props.serverSide) {
    return getServerHeaderCounterText(props.totalItems, props.selectedItems);
  }
  return getHeaderCounterText(props.totalItems, props.selectedItems);
}

export const TableHeader = props => {
  return (
    <Header
      variant={props.variant}
      counter={getCounter(props)}
      info={props.updateTools && <InfoLink onFollow={props.updateTools} />}
      description={props.description}
      actions={props.actionButtons}
    >
      {props.title}
    </Header>
  );
};


export const InfoLink = ({ id, onFollow }) => (
  <Link variant="info" id={id} onFollow={onFollow}>
    Info
  </Link>
);

// a special case of external link, to be used within a link group, where all of them are external
// and we do not repeat the icon
export const ExternalLinkItem = ({ href, text }) => (
  <Link href={href} ariaLabel={`${text} ${externalLinkProps.externalIconAriaLabel}`} target="_blank">
    {text}
  </Link>
);

export const TableNoMatchState = props => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can&apos;t find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const TableEmptyState = ({ resourceName }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>{resourceName}s loading...</b>
      </div>
      {/*
      <div>
        <b>No {resourceName.toLowerCase()}s</b>
        <Box variant="p" color="inherit">
          No {resourceName.toLowerCase()}s associated with this resource.
        </Box>
      </div>
      <Button>Create {resourceName.toLowerCase()}</Button>
      */}
    </SpaceBetween>
  </Box>
);




const defaultOnFollowHandler = ev => {
  // keep the locked href for our demo pages
  ev.preventDefault();
};


export function Notifications() {
  const notifications = useNotifications();
  if (notifications.length === 0) {
    return null;
  }
  return <Flashbar items={notifications} />;
}

export function DevSameOriginWarning() {
  const { hostname, protocol } = document.location;
  const amazonSubdomain = /.amazon.com$/.test(hostname) || /.a2z.com$/.test(hostname);
  const sameOrigin = protocol === 'https:' && amazonSubdomain;

  if (!sameOrigin) {
    return (
      <Alert header="You need to host this page in compliance with same-origin policy" type="error">
        <span>
          The dashboard will not work properly unless the page is hosted:
          <ul>
            <li>over https</li>
            <li>on amazon.com or a2z.com subdomains</li>
          </ul>
          Use startHttps script <Box variant="code">sudo npm run startHttps</Box> from examples package to achieve this
        </span>
      </Alert>
    );
  }
  return null;
}

export const Counter = ({ children }) => {
  return <Box variant="awsui-value-large">{children}</Box>;
};
