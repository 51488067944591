import React, { useState, useEffect, useContext } from 'react';
import {
  Header, Box, CounterLink, Link, Grid, BarChart, Container, SpaceBetween, Button, ExpandableSection, ButtonDropdown, 
  FormField, Input, Textarea, ColumnLayout, Spinner, Checkbox, Icon, TextContent, Table, Badge, ProgressBar, StatusIndicator, 
} from '@amzn/awsui-components-react';

import '../../styles/assessments.scss';
import ActionItemsModal from '../assessment/ActionItemsModal';
import TargetModal from './TargetModal';
import {API} from 'aws-amplify';
import moment from 'moment'
import ExpectationModal from '../assessment/ExpectationModal';

export default function DevPlanTable({userPdp, setPdpData, globalState, forAssessmentId, setReloadTrigger, activeRoleGuidelines})  {

    const [content, setContent] = useState(<Spinner />);

    const [modalVisible, setModalVisible] = useState(false);
    const [activeAssessmentId, setActiveAssessmentId] = useState(null);
    const [activeAssessmentItemId, setActiveAssessmentItemId] = useState(null);
    const [currentItem, setCurrentItem] = useState({});
    const [startWithAdd, setStartWithAdd] = useState(false);
    
    const [targetModalVisible,setTargetModalVisible] = useState(false);
    const [customTarget,setCustomTarget] = useState({});
    const [expectationModalVisible,setExpectationModalVisible] = useState(false);
    const [expectation,setExpectation] = useState({tags: {}});
    
    const isManager = globalState.user.isManager() || globalState.user.isAdmin();;


    const categMap = [];
    categMap.push({
        code: 'PERSONAL',
        label: 'Personal',
    });
    const categs = activeRoleGuidelines ? activeRoleGuidelines.guidelines.map(c => {
        return {
            code: c.id,
            label: c.text
        }
    }) : [];
    categMap.push(  ...categs );

    const getCategoryLabel = (categoryCode) => {
      const f = categMap.filter(c => c.code==categoryCode);
      return f.length==1 ? f[0].label : categoryCode;
    }

    const getItemDescription = (categoryId, refItemId) => {
        if (activeRoleGuidelines.guidelines.filter(c => c.id===categoryId).length==0) return '';
        return  activeRoleGuidelines.guidelines
            .filter(c => c.id===categoryId)[0]
            .items.filter(i => i.id===refItemId)[0].text;
    }

    const getTableItemsFromPdp = (pdp) => {
        const res = [];
        const items = pdp.map( c => {
          //console.log(c);
          //add the guideline item:
          //if (c.targets.length>0){
          c.targets.forEach( ct => {
            //add the guideline item with status Target
            //console.log('ct:',ct);
            res.push({
                guidelineItem: ct.refItemId=='CUSTOM_TARGET' ? ct.tags.title : getItemDescription(c.category.id, ct.refItemId),
                actionItem: '',
                areaOfContribution: '',
                status: 'Target',
                areaOfContribution: c.category.label,
                isTarget: true,
                target: ct,
            })    
            //add its action plan
            const actionPlan = [];
            //console.log('ap', t.actionPlan);
            ct.actionPlan && ct.actionPlan.forEach( ai => {
              ai.status!='archived' && actionPlan.push({
                    guidelineItem: ai.text,
                    status: ai.status,
                    dueDate: ai.dueDate,
                    actionItem: '',
                    areaOfContribution: c.category.label,
                    target: ct,
                });
            });
            res.push(...actionPlan);
        });

        //eventually, look for actionItems that do not have a guideline item as target (in the actionItems field of the pdp) 
        c.actionItems.forEach( ap => {
          if (!ap.tags && ap.actionPlan && ap.actionPlan.filter(e => e.status&&e.status!='archived').length>0) {
            //console.log('action plan wihout target!', ap, getItemDescription(c.category.id, ap.refItemId));
            res.push({
              guidelineItem: getItemDescription(c.category.id, ap.refItemId),
              actionItem: '',
              areaOfContribution: '',
              status: 'nontarget',
              areaOfContribution: c.category.label,
              isTarget: true,
              target: ap,
            })    
            //add its action plan
            const actionPlan = [];
            //console.log('ap', t.actionPlan);
            ap.actionPlan && ap.actionPlan.forEach( ai => {
                actionPlan.push({
                    guidelineItem: ai.text,
                    status: ai.status,
                    dueDate: ai.dueDate,
                    actionItem: '',
                    areaOfContribution: c.category.label,
                    target: ap,
                });
            });
            res.push(...actionPlan);
          }
        });

      })

      return res;
    }


    const showActionItemsModal = (assessmentId, assessmentItemId, guidelineItem, add=false) => {
      setActiveAssessmentId(assessmentId);
      setActiveAssessmentItemId(assessmentItemId);
      setCurrentItem(guidelineItem);
      if (add) setStartWithAdd(true);
      //console.log("startWithAdd", startWithAdd);
      setModalVisible(true);
    }

    const clonePdp = (ci) => {
      const res = [];
      ci.forEach(x => {
        let newObj = {};
        for (var prop in x) {
          if (Object.prototype.hasOwnProperty.call(x, prop)) {
            newObj = {...newObj, [prop]: [...x[prop]]};
          }
        }
        res.push(newObj);
      });
      return res;
    }

    //redraw after some action items were modified for a specific assessment item
    const updateItemsState = (assessmentItemId, tempActionItems) => {
      //console.log(assessmentItemId, tempActionItems, userPdp);
      const pdp = JSON.parse(JSON.stringify(userPdp));
      //set the action items (actionPlan) for our assessment ID
      let iIndex, jIndex = 0;
      pdp.forEach((x,i) => {
        x.targets.forEach((t,j) => {
          if (t.id==assessmentItemId){
            t.actionPlan = tempActionItems;
            iIndex = i;
            jIndex = j;
            pdp[iIndex].targets[jIndex].actionPlan = tempActionItems;
            //console.log('setting', pdp[iIndex].targets[jIndex], tempActionItems);
          }
        })
      });
      setPdpData(JSON.parse(JSON.stringify(pdp)));
    }

    useEffect(() => {

        const titleCell = item => {
          let text = item.guidelineItem ? item.guidelineItem : '';
          if (!text && item.target.tags && item.target.tags.title){
              text = item.target.tags.title;
          }
          const t = item.isTarget ? 
            <span className={`${item.status==='Target' ? 'pdpHeaderRow' : 'pdpHeaderRowLighter'}`} style={{fontStyle: 'italic'}}>
              <SpaceBetween direction='horizontal' size='s'>
                {/*{item.status==='Target' && <Icon name="heart" size="small"
                      variant={item.areaOfContribution=='Custom Targets' ? 'success' : 'link'} className='pdpRowIcon' />}*/}
                <span>{text}</span>
              </SpaceBetween>
            </span>
            :
            <span style={{paddingLeft: 20}}> {text || "-"} </span> ;
          return t;
        }; 


        const targetStatusCell = item => {
          //console.log(item.guidelineItem, 'status:' + item.status, 'isTarget:' + item.isTarget);
          let color = 'blue';
          switch (item.status) {
            case 'done':
              color = 'green'; break;
            case 'archived':
              color = 'grey'; break;
            default: break;
          }
          const isExpectation = item.target.refItemId === 'EXPECTATION';
          return item.isTarget ? 
              isExpectation ? 
                <span className={'pdpHeaderRow'}>
                  <span style={{ color: '#adacac', paddingLeft: '5px'}}> {item.target.tags.attainment || 0}% </span>
                  {/*<ProgressBar
                            value={item.target.tags.attainment}
                            /*additionalInfo={`0 deliverables associated`}*/
                        /*/>*/}
                </span>
              :
                <span className={`${item.status==='Target' ? 'pdpHeaderRow' : 'pdpHeaderRowLighter'}`}>
                  {/*<span style={{color: '#adacac', fontStyle: 'italic'}} 
                    className={item.areaOfContribution==='Custom Targets' ? 'successVariantGreenLighter' : ''}>
                    {item.status}
                  </span>*/}
                  {item.status && 
                    <span title="Target">
                      <Icon name="heart" size="small" variant={item.areaOfContribution=='Custom Targets' ? 'success' : 'link'} />
                    </span>
                  }
                </span>
            :
            <Badge color={color}>{item.status}</Badge>;
        }



        const dueDateCell = item => {
          const isExpectation = item.target.refItemId === 'EXPECTATION';
          let dd = isExpectation ? item.target.tags.dueDate : '';
          if (dd){
            dd = moment(dd).format('MMM D, Y');
          }
          return (
            item.isTarget ? 
              <span className={`${item.status==='Target' ? 'pdpHeaderRow' : 'pdpHeaderRowLighter'}`}>
                {dd}
              </span>
            :
            <span style={{color: '#666'}}><i>{item.dueDate}</i></span> || "-"
          )
        }

        const aocCell = item => {
          const isExpectation = item.target.refItemId === 'EXPECTATION';
            const aoc = isExpectation ? getCategoryLabel(item.target.tags.contributionCategory) : item.areaOfContribution;
          //if (isExpectation) console.log(item);
          return (
            item.isTarget ?
              <span className={`${item.status==='Target' ? 'pdpHeaderRow' : 'pdpHeaderRowLighter'}`}>{aoc}</span>
             :
              <span style={{ color: '#666' }}><i>{aoc}</i></span> || "-"
          )
        }

        const actionsCell = item => {
          //const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
          const isExpectation = item.target.refItemId === 'EXPECTATION';

          let currentGuidelineItem = {};
          currentGuidelineItem.guidelineItem = item.guidelineItem;
          currentGuidelineItem.actionPlan = item.target.actionPlan || [];
          currentGuidelineItem.id = item.target.id;
          currentGuidelineItem.assessmentItemId = item.target.id;

          let warning = <></>;
          if (item.dueDate) {
            const daysDiff = (new Date(item.dueDate).getTime() - new Date().getTime()) / 1000 / 3600 / 24;
            if (daysDiff < 30 && item.status != 'done'){
              warning = <StatusIndicator type="warning">
                Warning
              </StatusIndicator>
            }
          }

          return item.isTarget ? 
            <span className={`${item.status==='Target' ? 'pdpHeaderRow' : 'pdpHeaderRowLighter'}`}>

              <ButtonDropdown
                items={[
                  { id: "actionItems", text: "Action Items" },
                  ... isExpectation ?  [{ id: "editExpectation", text: 'Edit Expectation'}]  : [],
                  ... (item.areaOfContribution==='Custom Targets' && !isExpectation) ? [{ id: "editTarget", text: "Edit Target" }] : [],
                  ... item.areaOfContribution==='Custom Targets' ? [{ id: "deleteTarget", text: (isExpectation ? "Delete Expectation" : "Delete Target") }] : [],
                ]}
                ariaLabel="Actions"
                variant="icon"
                expandToViewport
                onItemClick={ ({detail}) => {
                  //console.log('item', item);
                  switch (detail.id) {
                    case 'actionItems':
                      showActionItemsModal(item.target.assessmentId, item.target.id, currentGuidelineItem, true);
                      break;
                    case 'editTarget':
                      setCustomTarget({id: item.target.id, assessmentId: item.target.assessmentId, text: item.guidelineItem});
                      setTargetModalVisible(true);
                      break;
                    case 'editExpectation':
                      //setCustomTarget({id: item.target.id, assessmentId: item.target.assessmentId, text: item.guidelineItem});
                      setExpectation(item.target);
                      setExpectationModalVisible(true);
                      break;
                    case 'deleteTarget':
                      if (confirm('Are you sure you want to delete this Target and all its Action Items?')){
                        API.del('TikaAPI', 'employees/current/assessments/' + item.target.assessmentId + '/assessment-items/' + item.target.id).then(resp => {
                          setReloadTrigger({reload: true});
                        }).catch(error => { alert('Error deleting the custom target (check logs for details)'); console.log(error); });                            
                      }
                      break;                    
                  }
                }}
              />
              
              {/*<Button variant="link" iconName="edit" onClick={e => {
                //console.log(item.target.assessmentId);
                showActionItemsModal(item.target.assessmentId, item.target.id, currentGuidelineItem, true);
              }}>Action Items</Button>*/}

            </span>
            :
            /*<SpaceBetween direction="horizontal"><Button>Mark Done</Button></SpaceBetween>*/ warning
        };

        if (userPdp.length>0 && userPdp[0] != 1){
            const items = getTableItemsFromPdp(userPdp);
            //console.log('items:', items);

            const expectationsColumnDef = [
              {
                id: "expectationTitle",
                header: "Expectation/Action Item",
                cell: titleCell,
                sortingField: "guidelineItem",
              },
              {
                id: "status",
                header: "Status",
                cell: targetStatusCell,
                sortingField: "status"
              },
              {
                id: "dueDate",
                header: "Due Date",
                cell: dueDateCell,
                width: 130,
              },
              {
                id: "areaOfContribution",
                header: "Area Of Contribution",
                cell: aocCell,
                minWidth: 120,
              },
              {
                id: "actions",
                header: "Actions",
                cell: actionsCell,
                minWidth: 116,
              }

            ];

            const targetsColumnDef = [
              {
                id: "guidelineItem",
                header: "Guildeline/Action Item",
                cell: titleCell,
                sortingField: "guidelineItem",
              },
              {
                id: "atteninment",
                header: "Status",
                cell: targetStatusCell,
                sortingField: "status"
              },
              {
                id: "dueDate",
                header: "Due Date",
                cell: dueDateCell,
                width: 118,
              },
              {
                id: "areaOfContribution",
                header: "Area Of Contribution",
                cell: aocCell,
                minWidth: 120,
              },
              {
                id: "actions",
                header: "Actions",
                cell: actionsCell,
                minWidth: 116,
              }

            ];

            const targetItems = items.filter(e => e.status && e.status!='archived' && e.target.refItemId!="EXPECTATION");
            const targetsTable = targetItems.length>0 ? <Table
                header={<DevPlanTableHeader expectations={false} isManager={isManager} globalState={globalState} setModalVisible={setTargetModalVisible} customTarget={customTarget} setCustomTarget={setCustomTarget} />}
                columnDefinitions={targetsColumnDef}
                //selectionType="single"
                items={targetItems}
                loadingText="Loading resources"
                sortingDisabled
                wrapLines
                empty={
                  <Container>
                      <TextContent><b>You personal development plan is empty, as no items were marked as target.</b></TextContent>
                      <TextContent>Please start by setting items as target in 
                          &quot;<a href="#/assessment">What you do</a>&quot;
                          , to see your plan</TextContent>
                  </Container>
                }
              />
            : <></>;

             const expectationsTable = <Table
             header={<DevPlanTableHeader expectations={true} isManager={isManager} globalState={globalState} setModalVisible={setTargetModalVisible} customTarget={customTarget} setCustomTarget={setCustomTarget} />}
             columnDefinitions={expectationsColumnDef}
             //selectionType="single"
             items={items.filter(e => e.target && e.target.refItemId==="EXPECTATION")}
             loadingText="Loading resources"
             sortingDisabled
             wrapLines
             empty={
               <Container>
                   <TextContent><b>You expectation list is empty.</b></TextContent>
                   <TextContent>Please start by setting expectations <a href="#/assessment">here</a>
                    </TextContent>
               </Container>
             }
           
            />

            const bothTables = <>{expectationsTable}{targetsTable}</>
            setContent(bothTables);

        } else {
            if (userPdp[0] == 1) { //trick for notifying we are still loading...
                setContent(<Spinner />);
            } else {
                setContent(<Container>
                                <TextContent><b>Your development plan is empty.</b></TextContent>
                                <TextContent>Please start by setting expectations in &quot;<a href="#/assessment">My dashboard</a>&quot;
                                </TextContent>
                            </Container>);
            }
        }
    }, [userPdp]);

    return  <>
      <ActionItemsModal globalState={globalState}
            modalVisible={modalVisible} setModalVisible={setModalVisible}
            currentItem={currentItem} 
            updateItemsState={updateItemsState} />

      <TargetModal assessmentId={forAssessmentId} modalVisible={targetModalVisible} 
        setModalVisible={setTargetModalVisible} customTarget={customTarget} setCustomTarget={setCustomTarget}
        setReloadTrigger={setReloadTrigger} />

      <ExpectationModal assessmentId={forAssessmentId} modalVisible={expectationModalVisible} 
          setModalVisible={setExpectationModalVisible} expectation={expectation} setExpectation={setExpectation}
          setReloadTrigger={setReloadTrigger} editMode={true} />



      <Container disableContentPaddings>

        {content}

      </Container>

    </>
}


const DevPlanTableHeader = ({expectations,isManager, globalState, setModalVisible, customTarget, setCustomTarget}) => {

  let header_suffix = '';
  if (isManager && globalState.viewas_user_id) header_suffix = ` (for user ${globalState.viewas_user_id})`

  return (
    <Header
      variant={'awsui-h1-sticky'}
      description={expectations ? 'List of your expectations' : 'List of your targets in the different contribution categories'}
      actions={
        <SpaceBetween size="xs" direction="horizontal">

          { expectations ? 
            <></>
            :
            <></>
            /* 
            <Button variant="primary" onClick={()=>{
              setCustomTarget({});
              setModalVisible(true);
            }}>Add Custom Target</Button>
            */
          }

        </SpaceBetween>
      }
    >
      
          {expectations ? 'Expectations and Plan' : 'My Targets'} {header_suffix}

    </Header>
  )

}
