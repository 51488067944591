import React, { useState, useEffect, useContext, useCallback, useReducer } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, StatusIndicator, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT, CLEAR_DELIVERABLE_FILTER } from '../global-state';
import { useNavigate } from "react-router-dom";
import AssessmentCategory from './AssessmentCategory';
import moment from 'moment';


export default function DeliverablesSimpleTable({category, deliverables, loading})  {

  const navigate = useNavigate();
  const globalState = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);


  let deliverablesHeader = 'Deliverables';
  if (globalState.deliverableFilter && globalState.deliverableFilter.ids){
    deliverablesHeader = <SpaceBetween key="hedaer" direction="horizontal" size="xxs">
        <div>Deliverables (Filtered by {globalState.deliverableFilter.ids.length} items)</div>
        <Link onFollow={(event) => {
          dispatch({type: CLEAR_DELIVERABLE_FILTER, paylod: {}});
        }}>clear filter</Link>
      </SpaceBetween>
    deliverables = deliverables.filter(item => globalState.deliverableFilter.ids.includes(item.id));
  }


    return (
      <Table
        columnDefinitions={[
          {
            id: "title",
            header: "Title",
            cell: item => 
              <Link onFollow={(event) => {
                  //console.log('click!');
                  //const item = event.detail.item;
                  //navigate(`/achievements/achievement/${event.detail.item.id}`)
                  navigate(`/achievements/achievement/${item.id}`, {state:{ achievement: item, sourcePage: 'DASHBOARD' }} ) ;
                }}
              >{item.title}</Link>
              || "-",
          },
          {
            id: "status",
            header: "Status",
            cell: item => {
              let res = <StatusIndicator type="in-progress">Open</StatusIndicator>;
              if (item.status === 'PROMOTED') {
                  res = <StatusIndicator type="success">Done</StatusIndicator>;
              }
              return (
                res
              )
            }
            
          },
          {
            id: "updated",
            header: "Updated",
            cell: item => moment(item.updatedTimestamp).fromNow() || "-"
          }
        ]}
        items={deliverables}
        loading={loading}
        loadingText="Loading deliverables..."
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No deliverables to display.
            </Box>
            <Button disabled={globalState.viewas_user_id} onClick={()=>{
              navigate("/achievements/achievement", {state:{ sourcePage: 'DASHBOARD' }});
            }}>Create Deliverable</Button>
          </Box>
        }
        header={<Header 
          variant='h3'
          description={`Your '${category}' deliverables` }
          actions={
            <SpaceBetween direction='horizontal' size='l'>
              <Button disabled={globalState.viewas_user_id} variant='primary' onClick={()=>{
              navigate("/achievements/achievement", {state:{ sourcePage: 'DASHBOARD' }});
            }}>Create Deliverable</Button>
            </SpaceBetween>
          }
        >
          {deliverablesHeader}
        </Header>}
      />
    
    )
}

