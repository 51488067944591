import React, { useState, useEffect, useContext, useCallback, useReducer } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { useNavigate } from "react-router-dom";
import AssessmentCategory from './AssessmentCategory';
import ExpectationsSimpleTable from './ExpectationsSimpleTable';
import DeliverablesSimpleTable from './DeliverablesSimpleTable';
import {API} from 'aws-amplify';
import ActionItemsModal from './ActionItemsModal';


export default function DashboardTab({category, globalState, categoryItems, setCategoryItems, 
                                      currentAssessmentId, loading, categoryCode,
                                      setReloadTrigger})  {


    const [achievements, setAchievements] = useState([]);
    const [achvLoading, setAchvLoading] = useState(true);
    const [achvLoaded, setAchvLoaded] = useState(false);

    const assessmentId = currentAssessmentId;
    const isManager = globalState.user && (globalState.user.isManager() || globalState.user.isAdmin());
    let employeeId = isManager ? globalState.viewas_user_id : null;
    if (!employeeId) employeeId = globalState.user.userNameClean();
    
    const categoryLabel = categoryItems && categoryItems['CUSTOM_TARGET'] ? 
      categoryItems['CATEGORY_LABELS'][category]  
      : '';

    useEffect(() => {
      if (achvLoaded || !categoryItems['CUSTOM_TARGET'] ) return;
      API.get('TikaAPI', `employees/${employeeId}/achievements`, {}).then(response => {
        //console.log('ACH',response, response.Items.sort(compare));
        if (response && response.Items && response.Items.length>0) {
          let filter = a => (a.guidelineItems && a.guidelineItems.length>0 && a.guidelineItems.filter(gi => gi.category_id==category).length>0);
          if (category==='PERSONAL') filter = a => (a.guidelineItems==undefined);
          //console.log('CATEGGGG',category, response.Items.sort(compare).filter(filter));
          const ach = response.Items.sort(compare).filter(filter);
          setAchievements(ach);
          //count achvmnts (deliverables) per expectation
          const expectations = categoryItems && categoryItems['CUSTOM_TARGET'] ? 
            categoryItems['CUSTOM_TARGET']
              .filter(e => (e.tags.contributionCategory && e.tags.contributionCategory==category))
          : [];
          if (expectations.length>0){
            expectations.forEach(e => {
              e.supportingDeliverables=[];
              ach.forEach(a => {
                if (a.expectations && a.expectations.includes(e.id)){
                  //console.log('Found Achv!',a,'that is for exp',e);
                  e.supportingDeliverables.push(a.id);
                }
              });
            });
            //console.log('ACH',ach);
            //console.log('setting setCategoryItems(categoryItems);', categoryItems);
            setCategoryItems(categoryItems);
          }
          //end of count

        } else {
          setAchievements([]);
        }
        setAchvLoading(false);
      }).catch(error => {
        console.log(error);
        setAchvLoading(false);
      });
      setAchvLoaded(true);

    }, [achievements,categoryItems]);


    function compare( a, b ) {
      let tsA = new Date(a.updatedTimestamp);
      let tsB = new Date(b.updatedTimestamp);
      if ( tsA > tsB ){
        return -1;
      }
      if ( tsA < tsB ){
        return 1;
      }
      return 0;
    }
    
    return (
      <>

        <SpaceBetween direction='vertical' size='l'>
          <ExpectationsSimpleTable category={categoryLabel} loading={loading} 
              assessmentId={assessmentId} categoryItems={categoryItems} categoryCode={categoryCode}
              setReloadTrigger={setReloadTrigger} setCategoryItems={setCategoryItems} />
          <DeliverablesSimpleTable category={categoryLabel} deliverables={achievements} loading={achvLoading} />
          {categoryCode=='PERSONAL' ? 
            <></>
            :
            <div style={{padding: 0}}>
              <div id="assessmentDashboard" style={{width: '100%', overflowX: 'scroll', overflow: '-moz-scrollbars-horizontal'}}>
                      <div style={{minWidth: '1540px'}}>
                        <AssessmentCategory category={category} globalState={globalState} 
                                  categoryItems={categoryItems} setCategoryItems={setCategoryItems} currentAssessmentId={currentAssessmentId} />
                      </div>
              </div>
            </div>
          }
        </SpaceBetween>
      </>
    
      
    )


}

