import {SET_GLOBAL_ALERT, SET_ROLE_GUIDELINES_OTHER_ROLE} from '../global-state'
import { API } from "aws-amplify";
import { Button } from '@amzn/awsui-components-react';
import React from 'react';

//See here: https://polaris.a2z.com/components/flashbar/ for details
export const setNotificationHelper = (
    _type,
    content,
    globalDispatch,
    header=null,
    action=null,
    dismissible=true,
    dismissLabel='Dismiss'
) => {
    
    let onDismiss = () => {
        globalDispatch({type: SET_GLOBAL_ALERT, payload: []})
    }

    let notification = [{
        type: _type,
        header: header,
        content: content,
        action: action,
        dismissible: dismissible,
        dismissLabel: dismissLabel,
        onDismiss: onDismiss,
        id: "message_1"
    }];
    globalDispatch({type: SET_GLOBAL_ALERT, payload: notification})



}



export const toSelectOption = (value, label) => {
    return {
        label: label ? label : value,
        value: value,
    }
}


export const isInGroup = (user, group) => {
    const groups = user.idTokenPayload['cognito:groups'];
    return groups.includes(group);
};

export const fetchGuildelines = async(role, level) => {
    return new Promise((resovle, reject) => {
        //start with functional dimensions
        API.get('TikaAPI', 'functional-dimensions', {}).then(fd => {
          if (fd){
            //leadership principles:
            API.get('TikaAPI', 'leadership-principles', {}).then(lp => {
              if (lp){
                //we have fd and lp - get the role guidelines
                //API.get('TikaAPI', 'guidelines', {queryStringParameters: {role_id: 'SA', level: '6'}}).then(guidelines => {
                API.get('TikaAPI', 'guidelines', {queryStringParameters: {role_id: role, level: level}}).then(guidelines => {
                  if (guidelines){
                    //join all into one json and set in global state:
                    //console.log(guidelines);
                    let categs = []; let itemToInfoMap = {};
                    guidelines.forEach(element => {
                      let categ = {text: element.category.name, id: element.category.id, items: []};
                      if (!(categs.find(c => c.id===element.category.id))){
                        categs.push(categ);
                      }
                    });
                    categs.forEach(c => {
                      guidelines.forEach(gi => {
                        if (gi.category.id===c.id) {
                          c.items.push({
                            id: gi.id,
                            text: gi.text,
                            guidelineCategory: gi.category.id,
                            guidelineCategoryText: gi.category.name,
                            role: gi.roleId + ' L' + gi.level,
                            ranks: gi.ranks,
                          });
                          //also update the map, for faster lookups wherever needed later
                          itemToInfoMap[gi.id] = {
                            categoryId: gi.category.id,
                            itemText: gi.text,
                          }
                        }
                      })
                    });
                    let res = {
                      functional_dimensions: fd.map(d => {return {text: d.title, id: d.id}}),
                      leadership_principles: lp.map(l => {return {text: l.title, id: l.id}}),
                      guidelines: categs,
                      itemToInfoMap: itemToInfoMap,
                    }
                    resovle(res);
                  } else {
                    //for if guidelines
                    reject('Error loading guidelines');
                  }
                }).catch(error => { reject('Error loading guidelines'); console.log(error); });    
              } else {
                //for if lp
                reject('Error loading leadership principles');
              }
            }).catch(error => { reject('Error loading leadership principles'); console.log(error); });
          } else {
            //for if fd
            reject('Error loading functional dimensions');
          }
        }).catch(error => {  reject('Error loading functional dimensions'); console.log(error); });
      });
}

export const loadAssessment = (globalState, employeeId='current', dispatch) => {
  return new Promise((resovle, reject) => {
    const isManager = globalState.user.isManager() || globalState.user.isAdmin();
    let user_id = 'current';
    let assessment_id = globalState.assessmentId;
    if (isManager && employeeId!='current'){
        console.log("Manager asking to see the assessment of " + employeeId);
        //we need to get this employee's assessment ID, and the guidelines for their level        
        //get the assessment ID for this employee
        API.get('TikaAPI', `employees/${employeeId}/assessments`, {}).then(item => {
            const a = item && item.length>0 ? item[0] : null;
            const aid = a ? a.id : null;
            assessment_id = '';
            if (aid) {
              assessment_id = aid;
            } else {
                console.log("ERROR: No is in assessment!");
                reject(`No assessment found for ${employeeId}`);
            }
            //make sure the role guidelines for this role and level are in global cache, and if not - load it
            if (a && a.roleLevel){
                if (a.roleLevel != globalState.roleGuidelinesDefaultRoleLevel){
                    if (!globalState.otherRoleGuidelines[a.roleLevel]){
                        console.log(`${a.roleLevel} is not in cache, need to load`);
                        const t = a.roleLevel.split('_');
                        fetchGuildelines(t[0], t[1]).then(guidelines => {
                            //cache globally
                            dispatch({type: SET_ROLE_GUIDELINES_OTHER_ROLE, payload: {
                                roleLevel: a.roleLevel,
                                data: guidelines,
                              }
                            });
                            console.log("SET_ROLE_GUIDELINES_OTHER_ROLE dispatched", guidelines);
                            //cache locally
                            resovle({guidelines: guidelines, assessmentId: assessment_id});
                            //fix categories
                            //fixCategories(guidelines.guidelines);
                        }).catch(e => console.log(e));
                    
                    } else {
                        //cache locally
                        resovle({guidelines: globalState.otherRoleGuidelines[a.roleLevel], assessmentId: assessment_id});
                        //fix categories
                        //fixCategories(globalState.otherRoleGuidelines[a.roleLevel].guidelines);
                    }
                } else {
                    //it is the same as default (the one already loaded) - set accordingly
                    resovle({guidelines: globalState.role_guidelines, assessmentId: assessment_id});
                  }
            } else {
                console.log("ERROR: No roleLevel in assessment!");
                reject(`No roleLevel in assessment`);
            }
            //
  
  
        });
    } else {
      resovle({guidelines: globalState.role_guidelines, assessmentId: assessment_id});
    }
  
  });

}



export const loadExpectations = (userId, assessmentId) => {
  return new Promise((resovle, reject) => {
    API.get('TikaAPI', `employees/${userId}/assessments/${assessmentId}/assessment-items`, {}).then(a => {
      const expectations = a.filter(i => i.refItemId=='EXPECTATION');
      resovle(expectations);
    }).catch((err) => reject(err));
  
  });

}