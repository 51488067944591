import React from 'react';
import { Badge, StatusIndicator, SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import { CUSTOM_PREFERENCE_OPTIONS, Preferences } from '../table/table-config';
import moment from 'moment'

export const AchievementsPreferences = props => (
  <Preferences
    pageSizeOptions={[
      { value: 10, label: '10 instances' },
      { value: 30, label: '30 instances' },
      { value: 50, label: '50 instances' }
    ]}
    visibleContentOptions={[
      {
        label: 'Main instance properties',
        options: [
          { id: 'id', label: 'Question ID', editable: false },
          { id: 'question', label: 'Question' },
        ]
      }
    ]}
    {...props}
  />
);

export const getStatusCell = (status) => {
  switch (status) {
    /*
    case 'UNREVIEWED': case 'DRAFT':
      return <StatusIndicator type="pending">Pending Review</StatusIndicator>
      break;
    case 'UNDER_REVIEW':
      return <StatusIndicator type="in-progress">Under Review</StatusIndicator>
      break;
    case 'NON_SUPPORTING':
      return <StatusIndicator type="error">Non Supporting</StatusIndicator>
      break;
    */
    case 'PROMOTED': case 'REVIEWED': //'REVIEWED' is the old way of marking approval
      return <StatusIndicator type="success">Done</StatusIndicator>
      break;
    default:
      return <StatusIndicator type="in-progress">Open</StatusIndicator>
      break;
  }
}



export const FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Achievement Status',
    key: 'status',
    groupValuesLabel: 'Achievement Status',
    operators: [':', '!:', '=', '!='],
  }
];

function getCategs(globalState, guideline_items){
  /*let getDefaultColor = (text) => {
    switch (text) {
      case 'Continuous Professionalism': return 'grey';
      case 'Advancing Organizational Capabilities': return 'blue';
      case 'Customer Advisor': return 'red';
      case 'Thought Leadership': return 'green';
      default:
        return 'grey';
    }
  }*/
  let res = [];
  if (!globalState.role_guidelines || !guideline_items) return res;
  const categs_included = [];
  guideline_items.forEach(e => {
    let categ = globalState.role_guidelines.guidelines.filter(categ => categ.id===e.category_id);
    if (categ.length==1){
      let x = <Badge key={categ[0].id} color={/*getDefaultColor(categ[0].text)*/'blue'}>{categ[0].text}</Badge>;
      if (!categs_included.includes(categ[0].id)){
        res.push(x);
        categs_included.push(categ[0].id);
      }
    }
  })
  return res;

}

export const getColumnDefinitions = (globalState, withEmployeeId = false) => { 
  return [
    ...withEmployeeId ? [{
      id: 'owner',
      header: 'Owner',
      //cell: item => <Link href="#">{item.id}</Link>
      cell: item => (item.ownerName ? item.ownerName : item.owner)
    }] : [],
    {
      id: 'title',
      header: 'Title',
      //cell: item => <Link href="#">{item.id}</Link>
      cell: item => item.title
    },
    {
      id: 'status',
      header: 'Status',
      cell: item => getStatusCell(item.status)
    },
    {
      id: 'contrib',
      header: 'Contribution Categories',
      cell: item => {
        let categs = getCategs(globalState, item.guidelineItems);
        return (
          <SpaceBetween direction='horizontal' size='xxs'>
            {categs}
          </SpaceBetween>
        )
      }
    },
    {
      id: 'expectations',
      header: 'Expectations',
      cell: item => {
        let res = '-';
        if (item.expectations){
          res = [];
          item.expectations.forEach(e => {
            //console.log(item.expectationInfo);
            if (item.expectationInfo){
              const inf = item.expectationInfo.filter(ei => ei && ei.expectationId == e);
              if (inf && inf.length==1){
                res.push(<Badge key={e} color='green'>{inf[0].expectationTitle}</Badge>)
              } else {
                res.push(<TextContent key={e}>-</TextContent>);
              }
            } else {
              res.push('-');
            }
          });
          return res;          
        } else {
          return res;
        }
        
      },
    },
    {
      id: 'updated',
      header: 'Updated',
      cell: item => moment(item.updatedTimestamp).fromNow()
    },
  ];
};


export const SEARCHABLE_COLUMNS = ['id', 'title', 'status', 'updated', 'owner', 'ownerName'];


/*
export const COLUMN_DEFINITIONS_MAIN = [
  {
    id: 'id',
    header: 'Question ID',
    cell: item => <Link href="#">{item.id}</Link>
  },
  {
    id: 'state',
    header: 'Instance state',
    cell: item => (
      <>
        <StatusIndicator type={item.state === 'Activated' ? 'success' : 'error'}> {item.state} </StatusIndicator>
      </>
    )
  },
  {
    id: 'type',
    header: 'Instance type',
    cell: item => item.type
  },
  {
    id: 'publicDns',
    header: 'Public DNS',
    cell: item => item.publicDns
  },
  {
    id: 'monitoring',
    header: 'Monitoring',
    cell: item => item.monitoring
  }
];
*/

export const COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE = [
  {
    id: 'type',
    header: 'Type',
    cell: item => item.type
  },
  {
    id: 'protocol',
    header: 'Protocol',
    cell: item => item.protocol
  },
  {
    id: 'portRange',
    header: 'Port range',
    cell: item => item.portRange
  },
  {
    id: 'source',
    header: 'Source',
    cell: item => item.source
  },
  {
    id: 'description',
    header: 'Description',
    cell: item => item.description
  }
];

export const SELECTION_LABELS = {
  itemSelectionLabel: (data, row) => `select ${row.id}`,
  allItemsSelectionLabel: () => 'select all',
  selectionGroupLabel: 'Question selection'
};

export const DEFAULT_PREFERENCES = {
  pageSize: 20,
  visibleContent: ['question', 'domain'],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};