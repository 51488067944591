import React, {useContext} from 'react';
import { BreadcrumbGroup, Button, HelpPanel, Icon, SpaceBetween } from '@amzn/awsui-components-react';
import { resourcesBreadcrumbs } from '../commons/breadcrumbs';
import { ExternalLinkItem, TableHeader } from '../commons/common-components';
import { useNavigate } from "react-router-dom";
import {API} from 'aws-amplify';
import {setNotificationHelper} from '../commons/utils'
import {GlobalStateContext, GlobalDispatchContext} from '../global-state'


export const Breadcrumbs = () => (
  <BreadcrumbGroup items={resourcesBreadcrumbs} expandAriaLabel="Show path" ariaLabel="Breadcrumbs" />
);

export const FullPageHeader = ({
  resourceName = 'Achievements',
  createButtonText = 'Create Achievement',
  ...props
}) => {
  const isOnlyOneSelected = props.selectedItems.length === 1;

  const navigate = useNavigate();

  const globalState = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  return (
    <TableHeader
      variant="awsui-h1-sticky"
      title={resourceName}
      actionButtons={
        <SpaceBetween size="xs" direction="horizontal">
          <Button onClick={()=>{
            props.setModalVisible(true);
          }}>Export as Body of Work</Button>          
          <Button disabled={!isOnlyOneSelected} onClick={()=>{
            if (!props.selectedItems || props.selectedItems.length!=1) return;
            let item = props.selectedItems[0];
            navigate(`/achievements/achievement/${item.id}`, {state:{ achievement: item }} ) ;
            //navigate("/achievements/achievement");
          }}>View details</Button>
          <Button disabled={props.selectedItems.length === 0} onClick={(e)=>{
            if (!props.selectedItems || props.selectedItems.length!=1) return;
            let item = props.selectedItems[0];
            API.del('TikaAPI', 'achievements/' + item.id, {}).then(response => {
              props.deleteCallback(item.id);
              setNotificationHelper('success', 'Delete Achievement with ID ' + item.id, dispatch, 'Achievement successfully deleted');
            }).catch(err => {
              console.log(err);
              setNotificationHelper('error', 'Could not delete Achievement with ID ' + item.id, dispatch, 'Unable to delete Achievement');
            })
           }} >Delete</Button>
          <Button variant="primary" onClick={()=>{
            navigate("/achievements/achievement");
          }}>{createButtonText}</Button>
        </SpaceBetween>
      }
      {...props}
    />
  );
};

export const ToolsContent = () => (
  <HelpPanel
    header={<h2>Distributions</h2>}
    footer={
      <>
        <h3>
          Learn more{' '}
          <span role="img" aria-label="Icon external Link">
            <Icon name="external" />
          </span>
        </h3>
        <ul>
          <li>
            <ExternalLinkItem
              href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html"
              text="Working with distributions"
            />
          </li>
          <li>
            <ExternalLinkItem
              href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-returned.html"
              text="Values that CloudFront displays on the console"
            />
          </li>
        </ul>
      </>
    }
  >
    <p>
      View your current distributions and related information such as the associated domain names, delivery methods, SSL
      certificates, and more. To drill down even further into the details, choose the name of an individual
      distribution.
    </p>
  </HelpPanel>
);
