import { Checkbox } from "@amzn/awsui-components-react";
import React, { useEffect, useContext, useState } from "react";
import {
    Multiselect
} from "../../aws-ui-components";
import { loadAssessment, loadExpectations } from "../commons/utils";

import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';


const ExpectationsMultiselect = ({preselectedOptions, onSelectedOptionsChange, maxSelectedItems}) => {

    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)

    const [loading, setLoading] = useState(true);
    const [expectations, setExpectations] = useState([]);
    const [categCodesToLabel, setCategCodesToLabel] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const getCategoryLabel = (categoryCode) => {
        return categCodesToLabel.filter(c => c.code==categoryCode)[0].label;
    }

    const getItems = () => {
        return expectations.map(e => {
            return {
                label: e.tags.title,
                value: e.id,
                tags: [getCategoryLabel(e.tags.contributionCategory)],
                /*options: categ.items.map(gi => {
                    return {
                        label: gi.text,
                        value: gi.id,
                        description: categ.text,
                    }
                }),*/
            }
        });
    }

    useEffect(() => {
        //console.log(1);
        loadAssessment(globalState, 'current', dispatch).then((assessment) => {
            //quick parse and build a CATEG_CODE=>CATEG_LABEL map (used later for the expectation's tags)
            const categToLabel = assessment.guidelines.guidelines.map(c => {
                return {
                    code: c.id,
                    label: c.text
                }
            });
            categToLabel.push({code:'PERSONAL', label: 'Personal'});
            setCategCodesToLabel(categToLabel)
            //we have the assessment (with ID and everything). we mainly needed the ID really. now load the expectations
            loadExpectations('current',assessment.assessmentId).then(expectations => {
                setExpectations(expectations);
                setLoading(false);
            });
        });
    }, [loading]);

    useEffect(()=>{
        if (preselectedOptions && preselectedOptions.length>0) {
            const selected = [];
            getItems().forEach(e => {
                if (preselectedOptions.includes(e.value)) selected.push(e);
            });
            setSelectedOptions(selected);
        }
    },[expectations])


    return (
      <Multiselect
        selectedOptions={selectedOptions}
        onChange={({ detail }) => {
            if (selectedOptions.length>=maxSelectedItems && detail.selectedOptions.length>(maxSelectedItems-1)) {
                return false;
            }
            setSelectedOptions(detail.selectedOptions);
            //callback the parent form
            if (onSelectedOptionsChange){
                onSelectedOptionsChange(detail.selectedOptions);
            }
          }
        }
        statusType={loading?'loading':'finished'}
        loadingText="Loading Expectations"
        deselectAriaLabel={e => `Remove ${e.label}`}
        options={getItems()}
        placeholder="Choose the most relevant items"
        selectedAriaLabel="Selected"
      />
    );
}

export default ExpectationsMultiselect;
