import React, { useState, useEffect, useContext } from 'react';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, 
  SpaceBetween, Button, FormField, Input, Textarea, Tabs, Link, Spinner, TextContent, 
} from '@amzn/awsui-components-react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Breadcrumbs, Navigation } from '../commons/navigation';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT, SET_ROLE_GUIDELINES_OTHER_ROLE, SET_GLOBAL_CATEGORIES } from '../global-state';
import DevPlanTable from './DevPlanTable';
import {API} from 'aws-amplify';
import { fetchGuildelines, setNotificationHelper } from '../commons/utils';
import Chrono from './Chrono';
import CounterDashboard from './CounterDashboard';
import moment from 'moment';

const CUSTOM_TARGET_CATEG = {label: 'Custom Targets', value: 'CUSTOM_TARGETS'};

export default function DevelopmentPlan()  {

    const navigate = useNavigate();
    const location = useLocation();
    const [navOpen, setNavOpen] = useState(false);

    const globalState = useContext(GlobalStateContext)
    const employeeId = globalState.viewas_user_id ? globalState.viewas_user_id : 'current';
    const isManager = globalState.user.isManager() || globalState.user.isAdmin();;

    //redirect to self-assessment if not a manager and no assessment ID is available
    useEffect(() => {
        if (!globalState.assessmentId && !isManager){
          console.log("No assessment ID found, navigating to self assessment");
          navigate('/assessment/self');
        }
    }, []);

    const content = (globalState.role_guidelines && globalState.role_guidelines.guidelines && globalState.assessmentId) ? 
        <Content globalState={globalState} employeeId={employeeId} /> : <><Spinner /></>;

    return (
        <AppLayout
          navigation={<Navigation activeHref='#/pdp' />}
          breadcrumbs={<Breadcrumbs items={ [{ text: 'My plan', href: '#/pdp' }] } />}
          content={content}
          tools={<Tools />}
          navigationOpen={navOpen}
          onNavigationChange={({detail}) => {
            setNavOpen(detail.open);
          }}
          headerSelector="#appHeader"
          stickyNotifications={true}
          notifications={ <Flashbar items={globalState.alerts || []} /> }
        />
    )  


}

const Content = ({globalState, employeeId}) => {

    const isManager = globalState.user.isManager() || globalState.user.isAdmin();;
    const [pdpData, setPdpData] = useState([1]);
    const [loading, setLoading] = useState(true);
    const [activeUserId, setActiveUserId] = useState(null);
    const [activeAssessmentId, setActiveAssessmentId] = useState(null);
    const [activeRoleGuidelines, setActiveRoleGuidelines] = useState(null);
    const [ready, setReady] = useState(false);  //set to true when we have both assessmentId and roleGuidelines ready
    const [reloadTrigger, setReloadTrigger] = useState({});
    const [randReload, setRandReload] = useState(Math.random());
    const [expectationStats, setExpectationStats] = useState(null);

    const navigate = useNavigate();
    const dispatch = useContext(GlobalDispatchContext)

    
    //start with default guidelines and categories (if manager will ask for different role/level - it will be reloaded later)
    let initialCategories = globalState.role_guidelines.guidelines.map(gc => { return { label: gc.text, value: gc.id, } });
    initialCategories = [CUSTOM_TARGET_CATEG].concat(initialCategories);
    const [activeCategories, setActiveCategories] = useState(initialCategories);


    //for a given guidelines, fix the active category and category items
    const fixCategories = (guidelines) => {
      let categs = guidelines.map(gc => { return { label: gc.text, value: gc.id, } });
      //add the "custom target" category (custom things you add with your manager)
      categs = categs.concat([CUSTOM_TARGET_CATEG]);
      setActiveCategories(categs);
    }


    const breakWithError = (errorMessage) => {
      //alert(errorMessage);
      setNotificationHelper('error', errorMessage , dispatch, 'An error has occured');
      setReady(true);
    }
    
    //main loading function - load the assessment items, check what's set as target and what are the action items - and save all to an array
    //finishes when setting all state variables: activeUserId, activeAssessmentId, activeRoleGuidelines - which will trigger another hook later to set "ready"
    const reloadDevelopmentPlan = () => {
      let user_id = 'current';
      let assessment_id = globalState.assessmentId;
      //logic for if manager asking PDP of employee:
      if (isManager && employeeId!='current'){
          console.log("Manager asking to see the PDP of " + employeeId);
          //we need to get this employee's assessment ID, and the guidelines for their level
          user_id = employeeId;            
          //get the assessment ID for this employee
          API.get('TikaAPI', `employees/${employeeId}/assessments`, {}).then(item => {
              const a = item && item.length>0 ? item[0] : null;
              const aid = a ? a.id : null;
              if (aid) {
                setActiveAssessmentId(aid);
              } else {
                console.log("ERROR: No id in assessment!");
                breakWithError(`No assessment found for ${employeeId}`);
                return;
              }                
              //make sure the role guidelines for this role and level are in global cache, and if not - load it
              if (a && a.roleLevel){
                  if (a.roleLevel != globalState.roleGuidelinesDefaultRoleLevel){
                      if (!globalState.otherRoleGuidelines[a.roleLevel]){
                          console.log(`${a.roleLevel} is not in cache, need to load`);
                          const t = a.roleLevel.split('_');
                          fetchGuildelines(t[0], t[1]).then(guidelines => {;                           
                              //cache globally
                              dispatch({type: SET_ROLE_GUIDELINES_OTHER_ROLE, payload: {
                                  roleLevel: a.roleLevel,
                                  data: guidelines,
                                }
                              });
                              console.log("SET_ROLE_GUIDELINES_OTHER_ROLE dispatched", guidelines);
                              //cache locally
                              setActiveRoleGuidelines(guidelines);
                              //fix categories
                              fixCategories(guidelines.guidelines);
                            }).catch(e => console.log(e));
                      
                      } else {
                        //set locally
                        setActiveRoleGuidelines(globalState.otherRoleGuidelines[a.roleLevel]);
                        //fix categories
                        fixCategories(globalState.otherRoleGuidelines[a.roleLevel].guidelines);
                      }
                  } else {
                      //it is the same as default (the one already loaded) - set accordingly
                      setActiveRoleGuidelines(globalState.role_guidelines);
                      //fixCategories(globalState.role_guidelines);
                  }              
              } else {
                console.log("ERROR: No roleLevel in assessment!");
                breakWithError(`No roleLevel in assessment`);
                return;
              }
          });
      } else {
          setActiveAssessmentId(assessment_id);
          setActiveRoleGuidelines(globalState.role_guidelines);
          //fixCategories(globalState.role_guidelines);
      }
      setActiveUserId(user_id);
    }

    //main loading function - fires on globalState.assessmentId change, loads all the items and compiles into a PDP
    useEffect(() => {
      reloadDevelopmentPlan();
    }, [globalState.assessmentId]);

    useEffect(() => {
      if (reloadTrigger && reloadTrigger.reload) {
        console.log('reloading');
        setReady(false);
        setRandReload(Math.random());
        reloadDevelopmentPlan();
      }
    }, [reloadTrigger])

    //when all activeUserId, activeAssessmentId, activeRoleGuidelines are set - set "ready" to true
    useEffect(()=>{
      if (activeUserId && isNaN(activeUserId) && activeAssessmentId && activeRoleGuidelines){
          setReady(true);
      } else {
      }
    }, [activeUserId, activeAssessmentId, activeRoleGuidelines, randReload]);


    function sortByDateProperty(arr) {
      arr.sort((a, b) => new Date(a.tags.dueDate) - new Date(b.tags.dueDate));
      return arr;
    }

    const calcStatistics = (assessmentItems) => {
      const expectations = assessmentItems.filter(i => i.refItemId=='EXPECTATION');
      const sorted = sortByDateProperty(expectations);
      //console.log('# expectations:', sorted.length);
      const expectationDates = {};
      const stats = {
        expectationsAtRisk: 0,
        upcomingActionItems: 0,
        overdueActionItems: 0,
      };
      sorted.forEach(e => {
        if (e.tags.dueDate){
          const d = new Date(e.tags.dueDate);
          if (!expectationDates[d.getFullYear()]) {expectationDates[d.getFullYear()] = new Array()};
          expectationDates[d.getFullYear()].push(e);

          //expectations at risk - those that are past due and their attaninment is less than 30
          //const dayDiff = Math.round((d.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
          const isOld = moment(e.tags.dueDate).isBefore(moment().add(30, 'days'))
          const attainment = e.tags.attainment ? parseInt(e.tags.attainment) : 0;
          if (isOld && attainment<30) {
            //console.log('risk', e.tags.title, dayDiff, attainment);
            stats.expectationsAtRisk++;
          }
        }

        //upcoming and overdue action items - action items that are due within 30 days or past due
        if(e.actionPlan && e.actionPlan.length>0){
          e.actionPlan.forEach(a => {
            if (!a.dueDate || a.status!=='open') return;
            const d = new Date(a.dueDate);
            const dayDiff = Math.round((d.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
            if (dayDiff < 0) {
              stats.overdueActionItems++;
            } else if (dayDiff <= 30) {
              stats.upcomingActionItems++;
            }
          });
        }

      });

      
      setExpectationStats({
        timeline: expectationDates,
        stats: stats,
      });

        
    };


    //fires when "ready" changes
    useEffect(()=>{
      //console.log('ready hook:', ready);
      if (!ready) return ()=>{};
      setLoading(true);
      API.get('TikaAPI', `employees/${activeUserId}/assessments/${activeAssessmentId}/assessment-items`, {}).then(a => {
          calcStatistics(a);
          const pdp = [];
          let categoryLabels = {};
          activeCategories.forEach(c => {
            //console.log('c', c, a);
            if (c.value==='CUSTOM_TARGETS') {
              const items = a.filter(i => (i.refItemId=='CUSTOM_TARGET' || i.refItemId=='EXPECTATION'));
              //console.log('custom targets/expectations',items);
              //items.forEach(c => {
                pdp.push({
                  category: {
                      id: /*c.tags.title*/'Custom Targets',
                      label: /*c.tags.title*/'Custom Targets',
                  },
                  targets: items,
                  actionItems: items.filter(i => i.actionPlan)
                });  
              //});  
            } else {
              categoryLabels[c.value] = c.label;
              const items = a.filter(i => (i.refItemId!='CUSTOM_TARGET' && i.refItemId!='EXPECTATION' && c.value===activeRoleGuidelines.itemToInfoMap[i.refItemId].categoryId) );
              const average = Math.round( items.reduce((acc, obj) => acc + obj.rank.score, 0) / items.length * 10) / 10;
              const targets = items.filter(i => i.tags && i.tags.target);
              const actionItems = items.filter(i => i.actionPlan);
              //console.log('t len',targets.length, targets);
              if (targets.length>0 || actionItems.length>0){
                //console.log('we have a target OR actionItems', targets, actionItems);
                pdp.push({
                  category: {
                      id: c.value,
                      label: c.label,
                  },
                  targets: targets,
                  actionItems: actionItems
                });
              } 
            }
              //console.log(c.label + ":", average, items, targets, actionItems);
          });
          //console.log('PDP',pdp);
          //console.log('categoryLabels', categoryLabels);
          dispatch({type: SET_GLOBAL_CATEGORIES, payload: categoryLabels});
          setPdpData(pdp);
          setLoading(false);
      }).catch(err => {console.log(err);});

    }, [ready]);


    /*const [a, seta] = useState(null);
    const [b, setb] = useState(null);

    useEffect(() => {
      console.log('a changed!', a);
      setb(Math.random());
    }, [a]);

    useEffect(() => {
      console.log('b changed!', b);
    }, [b]);*/


    let content;
    if (loading)
      content = <div style={{padding: '20px'}}>
                  <SpaceBetween direction='horizontal' size='s'>
                    <Spinner />
                    <TextContent>Loading your development plan...</TextContent>
                  </SpaceBetween>
                </div>;
    else
      content = <DevPlanTable userPdp={pdpData} setPdpData={setPdpData} globalState={globalState}
       forAssessmentId={activeAssessmentId} setReloadTrigger={setReloadTrigger} activeRoleGuidelines={activeRoleGuidelines} />;

    return (
      <>

        <CounterDashboard stats={expectationStats} userPdp={pdpData} setPdpData={setPdpData} />

        <Chrono expectations={expectationStats} activeRoleGuidelines={activeRoleGuidelines} userPdp={pdpData} setPdpData={setPdpData} />


        <Container disableContentPaddings>

          { content }

        </Container>
      </>
    )
}


const Tools = () => (
    <HelpPanel header={<h2>Help panel</h2>}>
      <p>Help content goes here</p>
    </HelpPanel>
  );
  