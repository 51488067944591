import React, { useState, useEffect, useContext } from 'react';
import {
  Header, AppLayout, CollectionPreferences, Wizard, Form, Flashbar, Container, SpaceBetween, Modal, 
  Button, FormField, Input, Textarea, Tabs, HelpPanel, Link, ColumnLayout, Box, RadioGroup, ExpandableSection, Spinner,
} from '@amzn/awsui-components-react';


import { Breadcrumbs, Navigation } from '../../commons/navigation';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../../global-state';
import { googleSignInButton } from 'aws-amplify';
import { setNotificationHelper } from '../../commons/utils';
import { API } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const roleLevel = Cookies.get('currentRoleName') ? (Cookies.get('currentRoleName') + '_' + Cookies.get('currentRoleLevel')) : undefined;

export default function AssessmentView()  {
    const globalState = useContext(GlobalStateContext)
    if (!globalState.role_guidelines || !globalState.role_guidelines.guidelines) return (<></>);

    return (
        <AppLayout
          navigation={<Navigation activeHref="#/assessment/self" />}
          breadcrumbs={<Breadcrumbs items={ [{ text: 'Self Assessment', href: '#/assessment/self' }] } />}
          content={<Content />}
          tools={<Tools />}
          headerSelector="#appHeader"
          stickyNotifications={true}
          notifications={ <Flashbar items={globalState.alerts || []} /> }
        />
    )  


}


const generateStep = (categ, categSelections, setCategSelections) => {

    let guidelineItems = [];

    categ.items.forEach( (item, index) => {
        guidelineItems.push(
            <Container key={index} header={<Header variant="h6" >{item.text}</Header>} >
                <SpaceBetween direction='vertical' size='xs'>

                        <RadioGroup
                            onChange={({ detail }) => setCategSelections( {...categSelections,
                                    [categ.id]: {
                                        ...categSelections[categ.id],
                                        [index]: {
                                            ...categSelections[categ.id][index],
                                            itemId: item.id,
                                            score: detail.value,
                                        }
                                    } } ) }
                            value={categSelections[categ.id][index] ? categSelections[categ.id][index].score : undefined}
                            items={item.ranks.map(rank => ({label: rank.text, value: rank.score}))}
                        />

                        <ExpandableSection variant="footer" header="Notes">
                            <Textarea
                                placeholder="Self assessment note"
                                value={categSelections[categ.id][index] ? categSelections[categ.id][index].notes : undefined}
                                onChange={event => setCategSelections( {...categSelections,
                                    [categ.id]: {
                                        ...categSelections[categ.id],
                                        [index]: {
                                            ...categSelections[categ.id][index],
                                            notes: event.detail.value,
                                        }
                                    } } ) }
                            />
                        </ExpandableSection>
                    </SpaceBetween>

             </Container>
        )
     })

    return (
        {
            title: categ.text,
            description: `Rate youself for the following ${categ.items.length} statements for ${categ.text}`,
            content: (
                <SpaceBetween direction="vertical" size="l">
                    {guidelineItems}
                </SpaceBetween>
            )
          }        
    )
}

const Content = () => {

    const globalState = useContext(GlobalStateContext);
    const dispatch = useContext(GlobalDispatchContext);

    const [loading, setLoading] = useState(false);

    let initialState = {};
    globalState.role_guidelines.guidelines.forEach(c=> {initialState[c.id] = {}})


    //TEMP - initial state with random ranks for all, to check submit
    /*globalState.role_guidelines.guidelines.forEach(c=> {
      c.items.forEach((gi,i) => {
        //console.log(gi);
        initialState[c.id][i] = {
          itemId: gi.id,
          score: Math.ceil(Math.random() * 5),
        }
      })
    });*/

    const [categSelections, setCategSelections] = useState(initialState);

    const categIndexMap = {};

    let steps = [];
    for (var i in globalState.role_guidelines.guidelines){
      let category = globalState.role_guidelines.guidelines[i];
      categIndexMap[i] = category;
      steps.push(generateStep(category,categSelections,setCategSelections));
    }

    const [activeStepIndex, setActiveStepIndex] = React.useState(0);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [resetDataModalVisible, setResetDataModalVisible] = React.useState(false);

    const navigate = useNavigate();

    const notif = (title, details, type_='error') => setNotificationHelper(type_, details, dispatch, title);

    useEffect(() => {
      //if there is already an assessment ID, load it (and disable save - as we don't support update from this self-assessment wizard at this point)
      if (globalState.assessmentId){
        console.log("Assessment ID already exists!", globalState.assessmentId);
        setLoading(true);
        let employeeId = globalState.viewas_user_id ? globalState.viewas_user_id : 'current';
        const isManager = globalState.user.isManager() || globalState.user.isAdmin();;
        if (!isManager) employeeId = 'current';
        //load assessment items
        API.get('TikaAPI', `employees/${employeeId}/assessments/${globalState.assessmentId}/assessment-items`, {}).then(a => {
          //set state accordingly
          globalState.role_guidelines.guidelines.forEach(categories => {
            categories.items.forEach((gi,i) => {
              const c = a.find(i => {
                //console.log(i.refItemId, gi.id);
                return i.refItemId===gi.id
              });
              if (c){
                //console.log(c);
                const score = c.rank.score;
                //console.log('score:',score, initialState[categories.id]);
                initialState[categories.id][i] = {
                  itemId: gi.id,
                  score: score,
                }
              }
            })
          });
          setLoading(false);
        }).catch(err => {console.log(err)});
      } else {
        setLoading(false);
      }
    }, [globalState.assessmentId]);

    if (loading) return <Spinner />

   

    const headerDescription =    
    <>
    Self assess how your performance is aligned with your role guildelines <br/> {globalState.assessmentId &&  <strong style={{color: 'red'}}> Self-Assessment already exists! Saving might overwrite existing data. </strong> }
    </>

    return (
      <>
        <Modal
          onDismiss={() => setModalVisible(false)}
          visible={modalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={()=>{setModalVisible(false)}}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header="Please rank all items"
        >
          All items should be ranked before moving to the next section.
        </Modal>

        <Modal
        onDismiss={() => setResetDataModalVisible(false)}
        visible={resetDataModalVisible}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="normal"
                onClick={() => {
                  console.log("close");
                  setResetDataModalVisible(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  
                  console.log("ok");
                  API.put('TikaAPI', 'employees/current/reset').then(items => {
                   
                    // remove client roles from cookies
                    Cookies.remove('currentRoleName');
                    Cookies.remove('currentRoleLevel');

                    notif('Remove current assessment','Self assessment is removed', 'success')
                    navigate('/');
                  }).catch(error => { 
                    notif('Error clear self assessment data','Could not remove self-assessment'); 
                    console.log(error); 
                  });

                  setResetDataModalVisible(false);
                }}
              >
                Ok
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Please confirm deletion"
      >
        Warning! All of your Tika data, including your Self-Assessment, Development Plan and Achievements will be permanently deleted. 
        <br/> <br/>
        Are you sure you want to proceed?
        
      </Modal>

        <Container header={
            <Header variant="h2" description={headerDescription} >
              Self Assessment
            </Header>
          }
        >
            <Wizard
             
              i18nStrings={{
                stepNumberLabel: stepNumber =>
                  `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                  `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) =>
                  `Skip to ${step.title}`,
                cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                submitButton: "Save Self Assessment",
                optional: "optional"
              }}
              onNavigate={({ detail }) => {
                if (detail.reason==='next'){
                  const currentCateg = categSelections[categIndexMap[detail.requestedStepIndex-1].id];
                  const expected = categIndexMap[detail.requestedStepIndex-1].items.length;
                  const actual =  Object.keys(currentCateg).length;
                  const all_filled = Object.values(currentCateg).filter(x => x.score === undefined).length==0; //all should have a 'score' property
                  if (actual < expected || !all_filled){
                    setModalVisible(true);
                  } else {
                    setActiveStepIndex(detail.requestedStepIndex)
                  }
                } else if (detail.reason==='previous'){
                  setActiveStepIndex(detail.requestedStepIndex)
                } else { //if (detail.reason==='step' && detail.requestedStepIndex<){
                  //console.log(detail);
                  //console.log(categSelections);
                }
              }}
              activeStepIndex={activeStepIndex}
              secondaryActions={
                globalState.assessmentId && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setResetDataModalVisible(true);
                      console.log("clear");
                    }}
                  >
                    Clear All Data
                  </Button>
                )
              }
              steps={ steps }
              onSubmit={(e) => {
                //if we already have an assessment ID - don't create another one, show a message
                /*if (globalState.assessmentId){
                  alert('Updating from the self assessment wizard is currently not supported. Please use your "What you do..." dashboard to update.')
                  return;
                }*/
                //check that indeed all steps are fully ranked
                const currentCateg = categSelections[categIndexMap[steps.length-1].id];
                const expected = categIndexMap[steps.length-1].items.length;
                const actual =  Object.keys(currentCateg).length;
                if (actual < expected){
                  setModalVisible(true);
                } else {
                  let tmpAssessmentId;
                  new Promise((resolve, reject) => {
                    if (globalState.assessmentId) {
                      tmpAssessmentId = globalState.assessmentId;
                      //delete the current non-expectations assessment items
                      API.del('TikaAPI', 'employees/current/assessments/' + tmpAssessmentId + '/assessment-items/non-expectations').then(res => {
                        console.log(res);
                        resolve(true);
                      }).catch(error => { notif('Error clearing current assessment','Could not clear current assessment items'); console.log(error); resolve(false); });
                    } else {
                      //we are here since we don't have an assessment. first, create one and get its id
                      API.post('TikaAPI', 'employees/current/assessments', { body: { roleLevel: roleLevel } }).then(assessment => {
                        console.log('created the new assessment',assessment);
                        tmpAssessmentId = assessment.id;
                        resolve(true);
                      }).catch(error => { notif('Error creating assessment','Could not create a new assessment and get its ID'); console.log(error); resolve(false); });
                    }  
                  }).then(res => {
                    if (!res) return;
                    //build an object that the API expects
                    let assessmentItems = [];
                    for (let [key, value] of Object.entries(categSelections)) {
                      for (let [key2, value2] of Object.entries(value)) {
                        let i = {
                          refItemId: value2.itemId,
                          rank: {
                            score: value2.score,
                          }
                        };
                        if (value2.notes){
                          i.notes = value2.notes;
                        }
                        assessmentItems.push(i)
                      }
                    }
                    API.post('TikaAPI', 'employees/current/assessments/' + tmpAssessmentId + '/assessment-items', {body: assessmentItems}).then(items => {
                      notif('Successfully saved self assessment','You self assessment was successfully saved!', 'success');
                      navigate('/assessment');
                      navigate(0);
                    }).catch(error => { notif('Error updating assessment','Could not populate with your self-assessment ranks'); console.log(error); });
                  })
                } //end of else for if actual < expected
              }}
            />
        </Container>
      </>
    )
}


const Tools = () => (
    <HelpPanel header={<h2>Help panel</h2>}>
      <p>Help content goes here</p>
    </HelpPanel>
  );
  