import React, { useEffect, useContext, useState } from "react";
import {
    Multiselect
} from "../../aws-ui-components";

import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';


const RoleGuidelinesMultiselect = (props) => {

    const globalState = useContext(GlobalStateContext)


    const getItems = () => {
        switch (props.itemType) {
            case 'functional-dimensions':
                if (!globalState.role_guidelines || !globalState.role_guidelines.functional_dimensions) return [];
                return globalState.role_guidelines.functional_dimensions.map(fd => {
                    return {
                        label: fd.text,
                        value: fd.id,
                    }
                });
                break;
            case 'leadership-principles':
                if (!globalState.role_guidelines || !globalState.role_guidelines.leadership_principles) return [];
                return globalState.role_guidelines.leadership_principles.map(fd => {
                    return {
                        label: fd.text,
                        value: fd.id,
                    }
                });
                break;
                
            default:
                if (!globalState.role_guidelines || !globalState.role_guidelines.guidelines) return [];
                return globalState.role_guidelines.guidelines.map(categ => {
                    return {
                        label: categ.text,
                        value: categ.id,
                        options: categ.items.map(gi => {
                            return {
                                label: gi.text,
                                value: gi.id,
                                description: categ.text,
                            }
                        }),
                    }
                }).filter(c => props.activeCategories.includes(c.value));
                break;
        }
    }


    //console.log(globalState.role_guidelines);
    let defaultState = [];
    if (globalState.role_guidelines && globalState.role_guidelines.guidelines && props.selectedOptions && props.selectedOptions.length>0) {
        //defaultState = globalState.role_guidelines.guidelines.filter(g => props.selectedOptions.includes(g.id));
        let options = getItems();
        options.forEach(categ => {
            if (categ.options) {
                categ.options.forEach(item => {
                    if (props.selectedOptions.includes(item.value)) defaultState.push(item);
                })
            } else {
                if (props.selectedOptions.includes(categ.value)) defaultState.push(categ);
            }
        });
        //console.log('defaultState', defaultState);
    }
    const [selectedOptions, setSelectedOptions] = useState(defaultState);

    return (
      <Multiselect
        selectedOptions={selectedOptions}
        onChange={({ detail }) => {
            if (selectedOptions.length>=props.maxSelectedItems && detail.selectedOptions.length>(props.maxSelectedItems-1)) {
                return false;
            }
            setSelectedOptions(detail.selectedOptions);
            //callback the parent form
            if (props.onSelectedOptionsChange){
                props.onSelectedOptionsChange(detail.selectedOptions);
            }
          }
        }
        deselectAriaLabel={e => `Remove ${e.label}`}
        options={getItems()}
        placeholder="Choose the most relevant items"
        selectedAriaLabel="Selected"
      />
    );
}

export default RoleGuidelinesMultiselect;
