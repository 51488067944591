import React, { useState, useEffect, useContext, useCallback, useReducer } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input, DatePicker, 
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, TextFilter, Pagination, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { DataStoreClass } from '@aws-amplify/datastore';
import {API} from 'aws-amplify';
import { useNavigate } from "react-router-dom";


export default function ActionItemModal({addAiModalVisible, setAddAiModalVisible, 
    tempActionItem, setTempActionItem, addActionItem, editActionItem, edit})  {

    const [err1, setErr1] = useState('');

    const closeModal = () => {
      setTempActionItem({});
      setAddAiModalVisible(false);
    }
    
    return (
        
        <Modal
          onDismiss={() => closeModal()}
          visible={addAiModalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => closeModal()}>Cancel</Button>
                <Button variant="primary" onClick={()=> {
                  if (!tempActionItem.text){
                    setErr1('Action item text is mandatory');
                    return;
                  } else {
                    setErr1('');
                  }
                  edit ? editActionItem() : addActionItem();
                }}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header={<Header description={`${edit ? 'Update an' : 'Create a new'} Action Item`}>Action Item</Header>} >

            <Container>
              <SpaceBetween direction="vertical" size="l">

                <FormField label="Action Item" description="The text of the action item" errorText={err1}>
                  <Input placeholder="Action item text" value={tempActionItem.text} onChange={(e) =>{
                    setTempActionItem({...tempActionItem, text: e.detail.value })
                  }} />
                </FormField>

                <FormField label="Due Date" description="Optional due date for this action item">
                  <DatePicker value={tempActionItem.dueDate} 
                    isDateEnabled={date =>
                      date.getTime() >= (new Date().getTime()-24*3600*1000)
                    }
                    onChange={(e) =>{
                      setTempActionItem({...tempActionItem, dueDate: e.detail.value })
                    }} />
                </FormField>


              </SpaceBetween>

            </Container>

        </Modal>

    )
}


