import React, { useState, useEffect, useContext, useReducer, useRef } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, SpaceBetween, Button, FormField, Input, Textarea, Tabs, ExpandableSection, TextContent, 
} from '@amzn/awsui-components-react';
import {API} from 'aws-amplify';

import '../../styles/servicehomepage.scss';
import { DEFAULT_PREFERENCES, AchievementsPreferences, COLUMN_DEFINITIONS_MAIN, SELECTION_LABELS } from './table-config';
import { TableEmptyState, TableNoMatchState } from '../commons/common-components';
import { getPanelContent, useSplitPanel } from './utils';
import { Breadcrumbs, Navigation } from '../commons/navigation';
import { FullPageHeader } from './table-common-components';
import { getFilterCounterText } from '../commons/tableCounterStrings';
import { appLayoutLabels, paginationLabels } from '../commons/labels';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import RoleGuidelinesMultiselect from '../role-guidelines-multiselect/RoleGuidelinesMultiselect';
import { useParams, useLocation } from "react-router-dom";
import Review from "../reviews/Review";
import {setNotificationHelper} from '../commons/utils';
import { getStatusCell } from './table-config';
import ExpectationsMultiselect from '../expectations-multiselect/ExpectationsMultiselect';

export default function Achievement()  {

    //const { item } = route.params;
    let {achievementId} = useParams();
    const location = useLocation();
    const achievement = location.state && location.state.achievement  ? location.state.achievement : null;

    const source_page = location.state && location.state.sourcePage ? location.state.sourcePage : null;

    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)

    const isManagerViewing = achievement && globalState.user && achievement.owner != globalState.user.userNameClean();
    //console.log('isManagerViewing', isManagerViewing);

    const activeHref = isManagerViewing ? '#/achievements/reports' : '#/achievements';

    //console.log(achievement);

    return (
      <AppLayout
        navigation={<Navigation activeHref={activeHref} />}
        breadcrumbs={<Breadcrumbs items={ [{ text: 'My key deliverables', href: '#/achievements' }, { text: 'Deliverable', href: '#/achievements/achievement' }] } />}
        content={<Content achievementItem={achievement} dispatch={dispatch} globalState={globalState} source_page={source_page} />}
        tools={<Tools />}
        headerSelector="#appHeader"
        stickyNotifications={true}
        maxContentWidth={1440}
        notifications={ <Flashbar items={globalState.alerts || []} /> }
      />

    );
}

const Content = ( { achievementItem, dispatch, globalState, source_page} ) => {
  const EMPTY_ERRORS = ['',''];
  const reviewRef = useRef();

  if (!globalState.role_guidelines || !globalState.role_guidelines.guidelines) return <></>;

  const location = useLocation();

  const [errors, setErrors] = useState(EMPTY_ERRORS);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const preSelectedGuidelineItems = [];
  const defaultGuidelineItemFromState = location.state && location.state.guidelineItemFilter ? location.state.guidelineItemFilter : '';
  const preSelectedExpectations = [];
  const defaultExpectationFromState = location.state && location.state.expectationFilter ? location.state.expectationFilter : '';
  //console.log('EXP', defaultExpectationFromState);

  let initialState = achievementItem ? achievementItem : {};
  let initialActiveCategoriesState = [];
  if (initialState.id && achievementItem.guidelineItems){ //if we are loading an achievement, initialize state
    achievementItem.guidelineItems.forEach(e => {
      let categ = globalState.role_guidelines.guidelines.filter(categ => categ.id===e.category_id);
      if (categ.length==1){
        if (!initialActiveCategoriesState.includes(categ[0].id)){
          initialActiveCategoriesState.push(categ[0].id);
        }
      }
    });
  } 
  if (!initialState.id && defaultGuidelineItemFromState){ //if creating a new achievement, and a filter to pre-select a guideline item was requested
    let categ = globalState.role_guidelines.itemToInfoMap[defaultGuidelineItemFromState].categoryId;
    if (categ){
      if (!initialActiveCategoriesState.includes(categ)){
        initialActiveCategoriesState.push(categ);
        preSelectedGuidelineItems.push(defaultGuidelineItemFromState);
        //fix to add category ID, and set in state (of the new achievement)
        const fixedOptions = [];
        fixedOptions.push({id: defaultGuidelineItemFromState, category_id: categ});
        //console.log(fixedOptions);
        initialState = {guidelineItems: fixedOptions};
      }
    }
  }
  if (!initialState.id && defaultExpectationFromState) { //if creating a new achievement, and a filter to pre-select expectations was requested
    preSelectedExpectations.push(defaultExpectationFromState);
    initialState = {expectations: preSelectedExpectations};
  }


  const [achievement, setAchievement] = useState(initialState);
  
  const [activeCategories, setActiveCategories] = useState(initialActiveCategoriesState);

  let is_update = initialState && initialState.id ? true : false;

  const handleFormSubmit = (event) => {
    event.preventDefault();

    //console.log(reviewRef.current.getReviewState());
    //return false;

    if (!achievement.title){
      errors[0] = 'Achievement topic is mandatory'
      setErrors(errors);
      return false;
    }
    setAchievement({ ...achievement } );
    //console.log(achievement);
    if (is_update){
      const finish = () => {
        setNotificationHelper('success', 'Update Deliverable with ID ' + initialState.id, dispatch, 'Deliverable successfully updated');
        navigation.navigate(source_page && source_page==='DASHBOARD' ? '#/assessment' : '#/achievements', { state: { ...achievement }  });
      }
      API.put('TikaAPI', 'achievements/' + initialState.id, {body: achievement}).then(response => {
        //update the review, if it exists:
        const review = reviewRef.current.getReviewState();
        if (achievement.reviewId && review && review.id) {
          API.put('TikaAPI', 'reviews/' + achievement.reviewId, {body: review}).then(response => {
            //console.log(response);
            finish();
          }).catch(error => console.error(error));              
        } else {
          finish();
        }
      }).catch(error => {
        console.log(error);
        setNotificationHelper('error', 'Could not update Deliverable, error:' + error.response, dispatch, 'Unable to update Deliverable');
      });
    } else {
      API.post('TikaAPI', 'achievements', {body: achievement}).then(response => {
        //console.log(response);
        setNotificationHelper('success', '', dispatch, 'Achievement successfully created');
        navigation.navigate('#/achievements', { state: { ...achievement }  });
      }).catch(error => {
        console.log(error.response);
        setNotificationHelper('error', 'Could not create Deliverable, error:' + error.response, dispatch, 'Unable to create Deliverable');
      });
    }
  }



  let categs = globalState.role_guidelines.guidelines.map(categ => { return { text: categ.text, id: categ.id}} );
  let categ_buttons = categs.map(c => { 
    return (
      <Button key={c.id} variant={activeCategories.includes(c.id)?'primary':'normal'} onClick={(e)=>{
        e.preventDefault();
        if (activeCategories.includes(c.id)){
          setActiveCategories(activeCategories.filter(ca => ca!=c.id)); 
        } else {
          var tmp = activeCategories;
          tmp.push(c.id);
          setActiveCategories(tmp); 
          forceUpdate();
        }
      }}>

        {c.text}

      </Button>
    )
  })

  //console.log(achievement);
  const isManagerViewing = achievement && globalState.user && achievement.owner != globalState.user.userNameClean();
  const ownerName = (isManagerViewing && achievement.ownerName) ? achievement.ownerName+'\'s ' : '';


  return (

    <Container header={
        <Header variant="h2" description={achievement && achievement.id ? "Update the Key Deliverable" : "Create a new Key Deliverable"} >
          {achievement && achievement.id ? "Update" : "Add"} {ownerName}Key Deliverable
        </Header>
      }
    >
      
      <form onSubmit={event => handleFormSubmit(event)}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => {
                  if (source_page && source_page=='DASHBOARD'){
                    navigation.navigate('#/assessment')
                  } else {
                    navigation.navigate('#/achievements')
                  }
                }}>
                Cancel
              </Button>
              <Button variant="primary">{achievement && achievement.id ? "Update" : "Create"} Deliverable</Button>
            </SpaceBetween>
          }
          errorText=""
        >
          <SpaceBetween size="l">

              <SpaceBetween size="xs">

                {is_update && 
                  <SpaceBetween direction='horizontal' size='xl'>
                    <TextContent>Deliverable Status:</TextContent>
                    {getStatusCell(achievement.status)}
                  </SpaceBetween>
                }

                <FormField label="Title of your Deliverable" description="The title of your Deliverable" errorText={errors[0]}>
                    <Input
                        placeholder="Type the title here"
                        value={achievement.title}
                        onChange={event => setAchievement({ ...achievement, title: event.detail.value })}                      
                    />
                </FormField>

                <FormField label="Area of Contribution" description="What Area of Contribution does this Deliverable demonstrate?">

                      <SpaceBetween direction="horizontal" size="xxs">
                        {categ_buttons}
                      </SpaceBetween>

                </FormField>

                {activeCategories.length>0 ? 
                  <FormField label="Contibution type" description="What Role Guidelines items does this Deliverable demonstrate? (select up to 3)">

                        <RoleGuidelinesMultiselect itemType="guidelines" maxSelectedItems={3}
                          selectedOptions={is_update ? 
                                            (achievement.guidelineItems ? achievement.guidelineItems.map(e=>e.id) : [])
                                            :
                                            preSelectedGuidelineItems
                                          }
                          activeCategories={activeCategories}
                          onSelectedOptionsChange={(options)=>{
                            const fixedOptions = [];
                            options.forEach(e => {
                              let categ = globalState.role_guidelines.guidelines.filter(categ => categ.items.filter(gi => gi.id===e.value).length==1);
                              fixedOptions.push({id: e.value, category_id: categ[0].id});
                            });
                            setAchievement({ ...achievement, guidelineItems: fixedOptions/*options.map(g => g.value)*/ })
                          }}
                        />
                  </FormField>
                      :
                      <></> 
                }

                <FormField label="Expectations (Optional)" description="What Expectation(s) does this Deliverable relate to?">

                      <ExpectationsMultiselect maxSelectedItems={3}
                        expectations={[]}
                        preselectedOptions={is_update ? achievement.expectations : preSelectedExpectations}
                        onSelectedOptionsChange={(options)=>{
                          //console.log('setting achievement.expectations, options:', options);
                          setAchievement({ ...achievement, expectations: options.map(g => g.value) })
                        }}
                      />
                </FormField>


                <FormField label="Functional Dimensions (Optional)" description="What Functional Dimensions does this Deliverable demonstrate? (select up to 3)">
                    <RoleGuidelinesMultiselect itemType="functional-dimensions" maxSelectedItems={3}
                      selectedOptions={achievement.functionalDimensions}
                      onSelectedOptionsChange={(options)=>{
                        setAchievement({ ...achievement, functionalDimensions: options.map(g => g.value) })
                      }}
                    />
                  </FormField>
    
                  <FormField label="Leadership Principles (Optional)" description="What Leadership Principles does this Deliverable demonstrate? (select up to 3)">
                    <RoleGuidelinesMultiselect itemType="leadership-principles" maxSelectedItems={3}
                      selectedOptions={achievement.leadershipPrinciples}
                      onSelectedOptionsChange={(options)=>{
                        setAchievement({ ...achievement, leadershipPrinciples: options.map(g => g.value) })
                      }}
                    />
                  </FormField>


                <FormField label="Deliverable Summary (Optional)" 
                    description={
                        <TextContent>
                          <p>
                            <small>
                              Describe details in either 
                              &nbsp;<a href="https://inside.amazon.com/en/Employment/Hiring/MakingGreatHiringDecisions/toolkit/Documents/STAR-Worksheet.doc" target="_new">STAR (Situation, Task, Action, Result)</a>
                              &nbsp;or
                              &nbsp;<a href="https://w.amazon.com/bin/view/SBI_Feedback_Model" target="_new">SBI (Situation, Behavior, Impact)</a>
                              &nbsp;format. 
                            </small>
                          </p>
                        </TextContent>
                    }>
                    <Textarea
                        placeholder="Deliverable description"
                        value={achievement.description}
                        onChange={event => setAchievement({ ...achievement, description: event.detail.value })}
                    />
                </FormField>

                <FormField label="Resources (Optional)" description="Links to relevant resources like quip docs, Salesforce activities etc.">
                      <Textarea
                          placeholder="Deliverable resources"
                          value={achievement.resources}
                          onChange={event => setAchievement({ ...achievement, resources: event.detail.value })}
                      />
                  </FormField>
    

              </SpaceBetween>

              
              {/*<ExpandableSection variant="footer" header="Extra details">
                <SpaceBetween size="s">
                </SpaceBetween>
              </ExpandableSection>*/}


              {achievement.id && 
                <ExpandableSection variant="footer" header="Review" defaultExpanded>
                  <Review ref={reviewRef} achievement={achievement} setAchievment={setAchievement} globalState={globalState} />
                </ExpandableSection>
              }

          </SpaceBetween>


        </Form>
      </form>
    </Container>
  );
}


const Tools = () => (
  <HelpPanel header={<h2>Help panel</h2>}>
    <p>Help content goes here</p>
  </HelpPanel>
);
