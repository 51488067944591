import React, { useState, useEffect, useContext } from 'react';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, 
  SpaceBetween, Button, FormField, Input, Textarea, Tabs, Link, Spinner, TextContent, ProgressBar, Badge, 
} from '@amzn/awsui-components-react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT, SET_ROLE_GUIDELINES_OTHER_ROLE } from '../global-state';
import {API} from 'aws-amplify';
import moment from 'moment'
import '../../styles/assessments.scss';

export default function Chrono({expectations, activeRoleGuidelines, userPdp, setPdpData})  {

    const [deliverables, setDeliverables] = useState([]);
    const [deliverablesLoaded, setDeliverablesLoaded] = useState(false);

    const globalState = useContext(GlobalStateContext)
    const isManager = globalState.user && (globalState.user.isManager() || globalState.user.isAdmin());
    let employeeId = isManager ? globalState.viewas_user_id : null;
    if (!employeeId) employeeId = globalState.user.userNameClean();

    useEffect(() => {
        API.get('TikaAPI', `employees/${employeeId}/achievements`, {}).then(response => {
            //console.log('ACH',response);
            if (response && response.Items && response.Items.length>0) {
                setDeliverables(response.Items);
                setDeliverablesLoaded(true);
            }
        }).catch(error => {
            console.log(error.response);
            setDeliverables([]);
            setDeliverablesLoaded(true);
        });
    
    }, []);

    const categMap = [];
    categMap.push({
        code: 'PERSONAL',
        label: 'Personal',
    });
    const categs = activeRoleGuidelines ? activeRoleGuidelines.guidelines.map(c => {
        return {
            code: c.id,
            label: c.text
        }
    }) : [];
    categMap.push(  ...categs );

    //redraw after some action items were modified for a specific assessment item
    const [filtered, setFiltered] = useState('');
    const [origData, setOrigData] = useState([]);


    const updatePdpData = (expectation) => {
        let pdp = [];
        //console.log('BEFORE',filtered, userPdp.length, pdp.length>0&&pdp[0].targets ? pdp[0].targets.length : 0);
        if (filtered===expectation.id){
            //clear the filter
            setFiltered('');
            pdp = JSON.parse(JSON.stringify(origData));
        } else if (filtered==''){
            //apply filter
            setFiltered(expectation.id);
            setOrigData(JSON.parse(JSON.stringify(userPdp)));
            const p = JSON.parse(JSON.stringify(userPdp));
            p.filter(e => e.category.id == 'Custom Targets');
            if (p.length>0) p[0].targets = p[0].targets.filter(t => t.id === expectation.id);
            //console.log(userPdp[0].targets)
            //console.log(p[0].targets)
            //console.log('Filtering!, p len:', p.length, 't len:', p[0].targets.length);;
            pdp = p;
        } else {
            //filter is not empty and is not our ID - apply a new filter with this ID
            setFiltered(expectation.id);
            //origData should have the whole list
            const p = JSON.parse(JSON.stringify(origData));
            p.filter(e => e.category.id == 'Custom Targets');
            p[0].targets = p[0].targets.filter(t => t.id === expectation.id);
            pdp = p;
        }
        //console.log('AFTER',filtered, userPdp.length, pdp.length, pdp.length>0&&pdp[0].targets ? pdp[0].targets.length : 0);
        setPdpData(JSON.parse(JSON.stringify(pdp)));
    }

    const MyYear = (props) => {
        return (
            <Container header={ <Header variant="h2" description={`Summarized timeline view of your expectations planned for ${props.year}`}>{props.year} Expectations</Header> } >
                <div id="assessmentDashboard" style={{width: '100%', overflowY: 'hidden', overflowX: 'scroll', overflow: '-moz-scrollbars-horizontal'}}>
                    <div style={{padding: '5px', height: 'max-content', width: 'max-content', whiteSpace: 'nowrap'}}>
                        <SpaceBetween size="l" direction='horizontal'>
                                    {props.children}
                        </SpaceBetween>
                    </div>
                </div>
            </Container>
        )
    }

    const getCategoryLabel = (categoryCode) => {
        const f = categMap.filter(c => c.code==categoryCode);
        return f.length==1 ? f[0].label : categoryCode;
    }
    

    const MyCard = ({expectation}) => {
        
        const date = expectation.tags.dueDate;
        const title = expectation.tags.title;
        const attainment = expectation.tags.attainment ? parseInt(expectation.tags.attainment) : 0;

        const datetitle = date ? /*date.toLocaleString('en-us', {month:'long'})*/moment(date).format('MMMM D') : '';
        const fronnow = date ? moment(date).fromNow() : '';
        const daysDiff = (new Date(date).getTime() - new Date().getTime()) / 1000 / 3600 / 24;

        //console.log(daysDiff, attainment, expectation);

        const maxTextLength = 30;
        const displayText = title.length>30 ? title.substring(0, maxTextLength) + '...' : title;

        let style = {};
        let suffix = '';
        if (daysDiff<30 && attainment<60) {
            style = {color: '#d13212'};
            suffix = '!';
        }

        let boxStyle = {};
        if (daysDiff<0 && attainment==100){
            boxStyle.opacity = '0.55';
        }

        const ap = expectation.actionPlan ? expectation.actionPlan : [];

        let deliverablesText = 'Loading deliverable count...';
        let deliverablesCount = deliverables.filter(d => d.expectations && d.expectations.includes(expectation.id)).length;
        if (deliverablesLoaded){
            deliverablesText = deliverablesCount + ' Deliverables associated';
        }

        if (filtered && filtered!=expectation.id){
            boxStyle.opacity = '0.25';
        }

        return (
            <div style={{ minWidth: 140, float: 'left', ...boxStyle }} className="chrono-card" onClick={e => {
                    //console.log('CLICK', filtered);
                    updatePdpData(expectation);
                }}>
                    <Container className="chrono-card" header={
                            <SpaceBetween size="xxs" direction='vertical'>
                                <div><b><label title={title}>{displayText}</label></b></div>
                                <div style={style}><i>{fronnow}{suffix}</i></div>
                                <div style={{transform: 'scale(0.75)', transformOrigin: '-15px'}}><Badge color='blue'>{getCategoryLabel(expectation.tags.contributionCategory)}</Badge></div>
                            </SpaceBetween>}
                            >
                        <SpaceBetween size="xxs" direction='vertical'>
                            <div>Due: {datetitle}</div>
                            <ProgressBar
                                value={attainment}
                                /*additionalInfo={`0 deliverables associated`}*/
                            />
                            <div>
                                {ap.filter(a => a.status==='open').length} AIs open
                            </div>
                            <div>
                                {ap.filter(a => a.status==='done').length} AIs done
                            </div>
                            <div>
                                {deliverablesText}
                            </div>
                        </SpaceBetween>
                    </Container>
                </div>
        )
    }

    let content = <Container header={ <Header variant="h2">Expectations Dashboard</Header> } >
                    <Spinner />
                </Container>
    
    const years = [];

    if (expectations && expectations.timeline){
        for (const prop in expectations.timeline) {
            years.push(
                <MyYear key={prop} year={prop}>
                    {expectations.timeline[prop].map(e => {
                        return (
                         <MyCard key={e.id} expectation={e} />
                        )
                    })}
                </MyYear>
            )
        }
        content = 
            <SpaceBetween direction='vertical' size='s'>
                {years}
            </SpaceBetween>
    }

    return (
        <div style={{paddingBottom: 10}}>
            {content}
        </div>
    )
}


                /*
                <MyYear year={"2023"}>
                        
                        <MyCard date={new Date('2023-12-31')} text={"Do 4 TLIs"} />
                        <MyCard date={new Date('2023-12-31')} text={"This is a very, very long text for this expectation"} />
                        <MyCard date={new Date('2023-12-31')} text={"Do 4 TLIs"} />
                        <MyCard date={new Date('2023-12-31')} text={"Do 4 TLIs"} />
                        <MyCard date={new Date('2023-12-31')} text={"Do 4 TLIs"} />
                        <MyCard date={new Date('2023-12-31')} text={"Do 4 TLIs"} />

                </MyYear>

                <div style={{paddingTop: 5}}>
                    <MyYear year={"2024"}>
                            
                            <MyCard text={"Do This"} />
                            <MyCard text={"Do That"} />

                    </MyYear>
                </div>
                */
