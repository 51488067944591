import React from 'react';
import AppConfig from './_app-config';
import AppMain from './AppMain';
import AppHeader from './AppHeader';

const App = () => {
  return (
    <AppConfig>
      <div>
        <div style={{position: 'sticky', top: 0, zIndex: 1002}}>
          <AppHeader />
        </div>
        <AppMain />
      </div>
    </AppConfig>
  )
};

export default App;
