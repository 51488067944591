import React, { useState, useEffect, useContext } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  SplitPanel,
  AppLayout,
  CollectionPreferences,
  Header,
  Pagination, Box,
  Table, Modal, 
  Flashbar,
  TextFilter, Button, Select, 
  SpaceBetween,
} from '@amzn/awsui-components-react';
import {API} from 'aws-amplify';

import '../../styles/servicehomepage.scss';
import { DEFAULT_PREFERENCES, AchievementsPreferences, getColumnDefinitions, SELECTION_LABELS, SEARCHABLE_COLUMNS } from './table-config';
import { TableEmptyState, TableNoMatchState } from '../commons/common-components';
import { getPanelContent, useSplitPanel } from './utils';
import { Breadcrumbs, Navigation } from '../commons/navigation';
import { FullPageHeader } from './table-common-components';
import { getFilterCounterText } from '../commons/tableCounterStrings';
import { appLayoutLabels, paginationLabels } from '../commons/labels';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ACHIEVEMENT_REVIEW_STATUS_CODES } from '../commons/common-data';


const defaultStatus = { value: '0', label: 'Any Status' };
const defaultContributionCategory = { value: '0', label: 'Any Category' };


export default function AchievementsTable({ navigation }) {

    const FILTER_STATUS_CODES = [defaultStatus, ...ACHIEVEMENT_REVIEW_STATUS_CODES];

    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const [toolsOpen, setToolsOpen] = useState(false);
    const [achievements, setAchievements] = useState([]);
    //const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const [navOpen, setNavOpen] = useState(false);
    const [statusFilter, setStatusFilter] = useState(defaultStatus);
    const [contibutionCategoryFilter, setContibutionCategoryFilter] = useState(defaultContributionCategory);
    const defaultGuidelineItemFilterFromState = location.state && location.state.guidelineItemFilter ? location.state.guidelineItemFilter : '';
    const [guidelineItemFilter, setGuidelineItemFilter] = useState(defaultGuidelineItemFilterFromState);
    
    const [loading, setLoading] = useState(true);
    const [modalVisible, setModalVisible] = React.useState(false);

    const [expectations, setExpectations] = React.useState(null);
    const [activeUserId, setActiveUserId] = useState(null);
    const [activeAssessmentId, setActiveAssessmentId] = useState(null);
    const [enriched, setEnriched] = useState(false);

    const isManager = globalState.user && (globalState.user.isManager() || globalState.user.isAdmin());
    let employeeId = isManager ? globalState.viewas_user_id : null;
    if (!employeeId) employeeId = globalState.user.userNameClean();

    const navigate = useNavigate();

    const employees = location.pathname.endsWith('/reports');//searchParams.get("employees") && searchParams.get("employees") === '1';

    useEffect(() => {
        if (!expectations) return () => { }
        let options = {};
        if (employees) options = { queryStringParameters: { employees: '1' } }
        API.get('TikaAPI', `employees/${employeeId}/achievements`, options).then(response => {
          //console.log(response, response.Items.sort(compare));
          if (response && response.Items){
            let tmpAchievements = response.Items.sort(compare);
            const achievementsWithExpectations = tmpAchievements.map(a => {
              if (a.expectations){
                const expectationInfo = a.expectations.map(e => {
                  const ei = expectations[e];
                  if (ei) {
                      return { expectationTitle: ei.tags.title, expectationId: e };
                  }
                });
                return { ...a, expectationInfo };
              } else {
                return { ...a};
              }
            });
            //setAchievements(achievementsWithExpe
            setAchievements(achievementsWithExpectations);
          } else {
            setAchievements([]);
          }
          setLoading(false);
        }).catch(error => {
          console.log(error);
          setLoading(false);
       });
    
    }, [expectations]);


    const reloadExpectations = () => {
      let user_id = 'current';
      let assessment_id = globalState.assessmentId;
      if (isManager && employeeId!='current'){
          //we need to get this employee's assessment ID, and the guidelines for their level
          user_id = employeeId;            
          //get the assessment ID for this employee
          API.get('TikaAPI', `employees/${employeeId}/assessments`, {}).then(item => {
              const a = item && item.length>0 ? item[0] : null;
              const aid = a ? a.id : null;
              if (aid) setActiveAssessmentId(aid);
          })
      } else {
         setActiveAssessmentId(assessment_id);
      }
      setActiveUserId(user_id);

    }

    useEffect(() => {
      reloadExpectations();
    }, [globalState.assessmentId]);

    useEffect(() => {
      if (!activeUserId || !activeAssessmentId) return ()=>{};
      //console.log('A',activeUserId, activeAssessmentId);
      API.get('TikaAPI', `employees/${activeUserId}/assessments/${activeAssessmentId}/assessment-items`, {}).then(response => {
        if (response){
          const expectationMap = {};
          const expectations = response.filter(i => (i.refItemId=='CUSTOM_TARGET' || i.refItemId=='EXPECTATION'));
          expectations.forEach(e => {
            expectationMap[e.id] = e;
          });
          //console.log(expectationMap);
          setExpectations(expectationMap);
        }
        
        //setLoading(false);
      }).catch(error => {
        console.log(error);
        //setLoading(false);
     });
  
    }, [activeUserId, activeAssessmentId]);;

        
    const FILTER_CONTRIBUTION_CATEGORIES = [defaultContributionCategory];
    if (globalState.role_guidelines && globalState.role_guidelines.guidelines) FILTER_CONTRIBUTION_CATEGORIES.push(...globalState.role_guidelines.guidelines.map(c => ({value: c.id, label: c.text})));


    function compare( a, b ) {
      let tsA = new Date(a.updatedTimestamp);
      let tsB = new Date(b.updatedTimestamp);
      if ( tsA > tsB ){
        return -1;
      }
      if ( tsA < tsB ){
        return 1;
      }
      return 0;
    }


    function matchesStatus(item, selectedStatus) {
      return selectedStatus === defaultStatus || item.status === selectedStatus.value;
    }
    
    function matchesContributionCategory(item, selectedContributionCategory) {
      return selectedContributionCategory === defaultContributionCategory || (item.guidelineItems && item.guidelineItems.filter(g=> g.category_id===selectedContributionCategory.value).length>0);
    }

    function matchesGuidelineItem(item, selectedGuidelineItem) {
      return selectedGuidelineItem==='' || (item.guidelineItems && item.guidelineItems.filter(g=> g.id===selectedGuidelineItem).length>0);
    }


    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
      achievements,
        {
          filtering: {
            empty: <TableEmptyState resourceName="Deliverable" />,
            noMatch: <TableNoMatchState onClearFilter={clearFilter} />,
            filteringFunction: (item, filteringText) => {
              if (!matchesStatus(item, statusFilter)) {
                return false;
              }
              if (!matchesContributionCategory(item, contibutionCategoryFilter)) {
                return false;
              }
              if (!matchesGuidelineItem(item, guidelineItemFilter)){
                return false;
              }
              const filteringTextLowerCase = filteringText.toLowerCase();
      
              return SEARCHABLE_COLUMNS.map(key => item[key]).some(
                value => typeof value === 'string' && value.toLowerCase().indexOf(filteringTextLowerCase) > -1
              );
            },
          },
          /*filtering: {
            empty: <TableEmptyState resourceName="Achievement" />,
            noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
          },*/
          /*propertyFiltering: {
            filteringProperties: FILTERING_PROPERTIES,
            empty: <TableEmptyState resourceName="Achievement" />,
            noMatch: (
              <TableNoMatchState onClearFilter={() => {
                  actions.setPropertyFiltering({ tokens: [], operation: 'and' });
                }}
              />
            ),
          },*/
          pagination: { pageSize: preferences.pageSize },
          sorting: {},
          selection: {}
        }
      );

    function clearFilter() {
      actions.setFiltering('');
      setStatusFilter(defaultStatus);
      setContibutionCategoryFilter(defaultContributionCategory);
    }

    const activeHref =  employees ? '#/achievements/reports' : '#/achievements';

    let header_suffix = '';
    if (isManager && globalState.viewas_user_id) header_suffix = ` (for user ${globalState.viewas_user_id})`

    return (
        <AppLayout
            navigation={<Navigation activeHref={activeHref} />}
            toolsOpen={toolsOpen}
            onToolsChange={({ detail }) => setToolsOpen(detail.open)}
            /*tools={toolsContent}
            toolsOpen={isToolsOpen}
            onToolsChange={onToolsChange}*/
            breadcrumbs={<Breadcrumbs items={ [{ text: 'My key deliverables', href: '#/achievements' }] } />}
            stickyNotifications={true}
            notifications={ <Flashbar items={globalState.alerts || []} /> }
            maxContentWidth={1440}
            navigationOpen={navOpen}
            onNavigationChange={({detail}) => {
              setNavOpen(detail.open);
            }}
            headerSelector="#appHeader"
            content={
              <>
                <Modal
                  onDismiss={() => setModalVisible(false)}
                  visible={modalVisible}
                  closeAriaLabel="Close modal"
                  footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" onClick={()=>{setModalVisible(false)}}>Ok</Button>
                      </SpaceBetween>
                    </Box>
                  }
                  header="Export as Body of Work"
                >
                  Exporting as Body of Work is on roadmap.
                </Modal>

                <Table
                    {...collectionProps}
                    header={
                        <FullPageHeader
                          resourceName={`My Key Deliverables ${header_suffix}`}
                          createButtonText="New Deliverable"
                          selectedItems={collectionProps.selectedItems}
                          totalItems={achievements}
                          updateTools={() => setToolsOpen(true)}
                          deleteCallback = {(id)=>{
                            let tmp = achievements.filter(a => a.id!=id);
                            setAchievements(tmp);
                          }}
                          setModalVisible={setModalVisible}
                        />
                    }
                    variant="full-page"
                    stickyHeader={true}
                    columnDefinitions={ getColumnDefinitions(globalState, employees) }
                    items={items}
                    selectionType="single"
                    onRowClick={(event) => {
                      const item = event.detail.item;
                      //navigate(`/achievements/achievement/${event.detail.item.id}`)
                      navigate(`/achievements/achievement/${item.id}`, {state:{ achievement: item }} ) ;
                    }}
                    ariaLabels={SELECTION_LABELS}
                    loading={loading}
                    loadingText="Loading achievements"
                    filter={
                      <div className="input-container">
                          <TextFilter
                            {...filterProps}
                            className="input-filter"
                            filteringAriaLabel="Filter achievements"
                            filteringPlaceholder="Find achievements"
                            countText={getFilterCounterText(filteredItemsCount)}
                          />
                          <Select
                            className="select-filter engine-filter"
                            options={FILTER_STATUS_CODES}
                            selectedAriaLabel="Selected"
                            selectedOption={statusFilter}
                            onChange={event => {
                              setStatusFilter(event.detail.selectedOption);
                            }}
                            ariaDescribedby={null}
                            expandToViewport={true}
                          />
                          <Select
                            className="select-filter engine-filter"
                            options={FILTER_CONTRIBUTION_CATEGORIES}
                            selectedAriaLabel="Selected"
                            selectedOption={contibutionCategoryFilter}
                            onChange={event => {
                              setContibutionCategoryFilter(event.detail.selectedOption);
                            }}
                            ariaDescribedby={null}
                            expandToViewport={true}
                          />
                      </div> 
                    }
                    wrapLines={preferences.wrapLines}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                    preferences={<AchievementsPreferences preferences={preferences} setPreferences={setPreferences} />}
                    empty={
                      <Box textAlign="center" color="inherit">
                        <b>No achievements</b>
                        <Box
                          padding={{ bottom: "s" }}
                          variant="p"
                          color="inherit"
                        >
                          No achievements available yet.
                        </Box>
                        <Button onClick={() => {
                          navigate("/achievements/achievement")
                        }}>Add Achievement</Button>
                      </Box>
                    }
                />
              </> 
            }
            ariaLabels={appLayoutLabels}
        />

    ); 
}


const SPLIT_PANEL_I18NSTRINGS = {
  preferencesTitle: 'Split panel preferences',
  preferencesPositionLabel: 'Split panel position',
  preferencesPositionDescription: 'Choose the default split panel position for the service.',
  preferencesPositionSide: 'Side',
  preferencesPositionBottom: 'Bottom',
  preferencesConfirm: 'Confirm',
  preferencesCancel: 'Cancel',
  closeButtonAriaLabel: 'Close panel',
  openButtonAriaLabel: 'Open panel',
  resizeHandleAriaLabel: 'Resize split panel'
};
