import React, { useState, useEffect, useContext } from 'react';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, SpaceBetween, Button, FormField, Input, Textarea, Tabs, Link, Spinner,
} from '@amzn/awsui-components-react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Breadcrumbs, Navigation } from '../commons/navigation';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import {API} from 'aws-amplify';
import InsightCategory from './InsightCategory';

export default function Insights()  {

    const navigate = useNavigate();
    const location = useLocation();

    const globalState = useContext(GlobalStateContext)

    let content = (globalState.role_guidelines && globalState.role_guidelines.guidelines) ? <Content globalState={globalState} /> : <Spinner />;

    return (
        <AppLayout
          navigation={<Navigation activeHref={'#/manager/insights'} />}
          breadcrumbs={<Breadcrumbs items={ [{ text: 'Team Insights', href: '#/manager/insights' }] } />}
          content={content}
          tools={<Tools />}
          headerSelector="#appHeader"
          stickyNotifications={true}
          notifications={ <Flashbar items={globalState.alerts || []} /> }
        />
    )  


}

const Content = ({globalState}) => {

    const categories = globalState.role_guidelines.guidelines.map(gc => { return { label: gc.text, value: gc.id, } });

    useEffect(() => {
    }, []);

    return (

        <Container /*disableContentPaddings*/ header={
            <Header variant="h2" description="[SKETCH, UNDER DEVELOPMENT] Insights from your team's data " >
              [---SKETCH, UNDER DEVELOPMENT---] My Team Insights
            </Header>
          }
        >
          {categories.map(c => {
            return (<InsightCategory key={c.value} category={c} globalState={globalState} />)
          })}
        </Container>
    )
}


const Tools = () => (
    <HelpPanel header={<h2>Help panel</h2>}>
      <p>Help content goes here</p>
    </HelpPanel>
  );
  