import React, { useReducer } from "react";
import GlobalState, { reducer } from "../global-state";

const initialState = {
  user: null,
  alerts: null,
  role_guidelines: null,
  directReports: null,
  assessmentId: null,
  loading: true,
  viewas_user_id: null,
  current_role: null,
  roleGuidelinesDefaultRoleLevel: null,
  otherRoleGuidelines: {},
  deliverablesFilter: {ids: []},
  globalExpectation: {},
  globalCategories: null,
};

function AppConfig(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalState initialState={state} dispatch={dispatch}>
      {props.children}
    </GlobalState>
  );
}

export default AppConfig;