import React, { useEffect, useContext } from "react";
import {
  AppLayout, Container, HelpPanel, Grid, Box, TextContent, Flashbar, SpaceBetween, Header, Icon, 
  Button, FormField, Select, ColumnLayout, Link, 
} from "../aws-ui-components";

import { API, container } from "aws-amplify";

import "../styles/intro.scss";
import "../styles/servicehomepage.scss";

import { GlobalStateContext, GlobalDispatchContext, SET_CURRENT_ROLE } from './global-state';

import { Navigation } from "./commons/navigation";
import { ExternalLinkItem } from "./commons/common-components";
import { externalLinkProps } from "./commons/labels";
import { useNavigate, useLocation } from "react-router-dom";

import Cookies from 'js-cookie';

export default function Home() {

  const globalState = useContext(GlobalStateContext)
  
  return (
    <AppLayout
      navigation={<Navigation activeHref="#/" />}
      content={<Content globalState={globalState} />}
      tools={<Tools />}
      disableContentPaddings={true}
      headerSelector="#appHeader"
      stickyNotifications={true}
      notifications={ <Flashbar items={globalState.alerts || []} /> }

    />
  );
}


const Content = ({globalState}) => {

  const dispatch = useContext(GlobalDispatchContext)

  const [selectedRole, setSelectedRole] = React.useState({ label: "Solutions Architect", value: "SA" });
  const [selectedLevel, setSelectedLevel] = React.useState(  { label: "5", value: "5" } );
  
  const [currentRoleName, setCurrentRoleName] = React.useState(Cookies.get('currentRoleName'));
  const [currentRoleLevel, setCurrentRoleLevel] = React.useState(Cookies.get('currentRoleLevel'));

  const location = useLocation();
  let appReady = Cookies.get('currentRoleName')!=undefined;
  if (location.state && location.state.changeRole) appReady = false; 
  const [appActive, setAppActive] =  React.useState(appReady);

  const navigate = useNavigate();


  useEffect(() => {
    if (!globalState.user) return;
    //console.log('User:', globalState.user);
    //console.log(currentRoleName, currentRoleLevel);
    try {
      if (!currentRoleName){ //not saved in cookie - set defaults in the select boxes based on the federated user attributes
        const descr = globalState.user.attributes["custom:description"];
        const level = globalState.user.attributes["custom:level"];
        if (descr.toUpperCase().indexOf('ACCOUNT MANAGER')>-1){
          setSelectedRole( { label: "Account Manager", value: "AM" } );
        } else if (descr.toUpperCase().indexOf('ARCHITECT')>-1 || descr.toUpperCase().indexOf('HEAD OF TECHNOLOGY')>-1){
          setSelectedRole( { label: "Solutions Architect", value: "SA" } );
        }
        if ( descr.toUpperCase().indexOf('SOL')>-1 && (descr.toUpperCase().indexOf('MGR')>-1 || descr.toUpperCase().indexOf('MANAGER')>-1) ){
          setSelectedRole( { label: "Solutions Architect Manager", value: "SA Manager" } );
        }
        setSelectedLevel({ label: level, value: level } );
       }
    } catch (e) {

    }
    
    //console.log('role:', role);
  }, [globalState.user]);

  let saveCurrentRole = (e) => {

    //TEMP - safeguard, for other roles and levels
    if (selectedRole.value!='SA' && selectedRole.value!='SA Manager'){
      alert('Sorry, at the moment this role is not supported. Please contact the TIKA team for assistance.');
      return;
    }
    if (selectedRole.value=='SA' && (selectedLevel.value=='8')){
      alert('Sorry, at the moment this level is not supported. Please contact the TIKA team for assistance.');
      return;
    }

    Cookies.set('currentRoleName', selectedRole.value);
    Cookies.set('currentRoleLevel', selectedLevel.value);   
    setAppActive(true);
    dispatch({type: SET_CURRENT_ROLE, payload: {role: selectedRole.value, level: selectedLevel.value} }) //set globally
    alert('Thanks for confirming your role and level. You will now be redirected to the self-assessment page');
    navigate('/assessment/self');
    navigate(0);
  }

  return (
    <Box margin={{ bottom: 'l' }}>
      <div className="custom-home__header">
        <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { offset: { l: 1, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              { colspan: { xl: 6, l: 5, s: 7, xxs: 0 }, offset: { l: 1, xxs: 1 } },
              { colspan: { xl: 2, l: 3, s: 3, xxs: 0 }, offset: { s: 0, xxs: 1 } },
            ]}
          >
            
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              {/* <span className="custom-home__category">AWS Builder Experience (BeXT)</span> */}
              &nbsp;
            </Box>
            
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="heading-xl" color="inherit">
              Tika - Personal Development Hub 
              </Box>
              <Box padding={{ bottom: 's' }} fontSize="heading-l" color="inherit">
              Own your performance and development plan
              </Box>
            </div>
            <div className="custom-home__header-cta">
              <Container>
                <SpaceBetween size="xl">
                  <Box variant="h2" padding="n">
                    Get Started
                  </Box>
                  <Box variant="p" padding="n">
                    To get started, first select your current Role and Level
                  </Box>
                  {!appActive && 
                    <SpaceBetween direction="vertical" size="xs">
                      <Box variant="h4" padding="n">Select your Role and Level:</Box>
                      <SpaceBetween direction="horizontal" size="xs">
                        <Box padding="n">
                          <Select
                                selectedOption={selectedRole}
                                onChange={({ detail }) =>
                                  setSelectedRole(detail.selectedOption)
                                }
                                options={[
                                  { label: "Solutions Architect", value: "SA" },
                                  { label: "Account Manager", value: "AM" },
                                  { label: "Solutions Architect Manager", value: "SA Manager"},
                                ]}
                                selectedAriaLabel="Selected"
                              />                        
                        </Box>
                        <Box padding="n">
                          <Select
                                selectedOption={selectedLevel}
                                onChange={({ detail }) =>
                                setSelectedLevel(detail.selectedOption)
                                }
                                options={[
                                  { label: "4", value: "4" },
                                  { label: "5", value: "5" },
                                  { label: "6", value: "6" },
                                  { label: "7", value: "7" },
                                  { label: "8", value: "8" },
                                ]}
                                selectedAriaLabel="Selected"
                              />                           
                        </Box>
                        <Box padding="n">
                          <Button href="#" onClick={(e) => saveCurrentRole(e)} variant="primary">
                            Save
                          </Button>
                        </Box>
                      </SpaceBetween>
                    </SpaceBetween>
                  }
                  {appActive && 
                    <Button href="/#/assessment/self" variant="primary">
                      My Self Assessment
                    </Button>
                  }
                </SpaceBetween>
              </Container>
            </div>
          </Grid>
        </Box>
      </div>

      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 2,  xxs: 10 }, offset: { l: 1, xxs: 1 } },
            
          ]}
        >
          <SpaceBetween size="xxl">
            <div>
            <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'm' }}>
              Benefits
            </Box>
            <Container>
            <Box variant="h5">
                At Amazon, we are expected to drive our own performance and development.<br/><br/>
                Amazon TIKA helps you answer consistently “yes” on:
                <ul>
                  <li style={{margin: '0 0 8px 0'}}>Do you clearly understand what is expected from you?</li>
                  <li style={{margin: '0 0 8px 0'}}>Do you clearly understand how your performance stands?</li>
                  <li style={{margin: '0 0 8px 0'}}>Does your manager have the visibility needed to evaluate your performance?</li>
                  <li style={{margin: '0 0 8px 0'}}>Do you have a clear plan and coaching on how you can continue to grow?</li>
                </ul>
              </Box>
              </Container>
              <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'm' }}>
                How does it work?
              </Box>
              <Container>
                <ColumnLayout columns={3} variant="default" borders="all">
               
                  <div>
                    <TextContent><small>Step 1</small></TextContent>
                    <Box variant="h4" padding={{ top: 'n' }}>
                    Self Evaluation
                    </Box>
                    <Box variant="p">
                      Start with a 10-minute self evaluation wizard to reflect your current strengths and possible areas for improvement according to your role guideline.
                    </Box>
                  </div>
                  <div>
                    <TextContent><small>Step 2</small></TextContent>
                    <Box variant="h4" padding={{ top: 'n' }}>
                      Identify Gaps
                    </Box>
                    <Box variant="p">
                      Review your self evaluation with your manager, discuss and get feedback on whether the development areas you have identified are best suited to improve your capabilities in your current role.
                    </Box>
                  </div>
                  <div>
                    <TextContent><small>Step 3</small></TextContent>
                    <Box variant="h4" padding={{ top: 'n' }}>
                      Define Plan
                    </Box>
                    <Box variant="p">
                      Use your manager’s knowledge and support to fine tune your actions and identify the best resources to help you reach your goals.
                    </Box>
                  </div>
                  <div>
                    <TextContent><small>Step 4</small></TextContent>
                    <Box variant="h4" padding={{ top: 'n' }}>
                      Record Achievements
                    </Box>
                    <Box variant="p">
                      Backup your self assessment and development plan by recording your achievements.
                    </Box>
                  </div>
                  <div>
                    <TextContent><small>Step 5</small></TextContent>
                    <Box variant="h4" padding={{ top: 'n' }}>
                      Manager Visibility & Feedback
                    </Box>
                    <Box variant="p">
                      Review new achievements and monitor your plan progressing with your manager.
                    </Box>
                  </div>
                  <div>
                    <TextContent><small>Step 6</small></TextContent>
                    <Box variant="h4" padding={{ top: 'n' }}>
                      Manager Evaluation
                    </Box>
                    <Box variant="p">
                      Conduct deep reviews of selected achievements with your manager to evaluate your accomplishments against the expectation bar.                    </Box>
                  </div>
                </ColumnLayout>
              </Container>
              <Container columns={1}textAlign="center" padding={{ bottom: 's', top: 'n' }}>
                <div width="100%">
                  <img src="images/flywheel.png" width="80%" />
                </div>
              </Container>
            </div>
          </SpaceBetween>
          
        </Grid>
      </Box>
    </Box>
  );
};


/*
const Content = ( {globalState} ) => {

  return (
    <div>
      <TextContent>
        <div style={{marginTop: '20px'}}>
          <Grid className="custom-home__header" disableGutters={true} gridDefinition={[{ colspan: 12 }]}>
            <Box margin="xxl" padding={{ vertical: "xl", horizontal: "l" }}>
              <div className="custom-home__header-title">
                <Box fontSize="display-l" fontWeight="bold" color="inherit">
                  Welcome to Tika
                </Box>
                <Box
                  fontSize="display-l"
                  padding={{ bottom: "s" }}
                  fontWeight="light"
                  color="inherit"
                >
                  Personal Development Hub
                </Box>
                <Box fontWeight="light">
                  <span className="custom-home__header-sub-title" />
                </Box>
              </div>
            </Box>
          </Grid>
        </div>

        <Box margin="xxl" padding="l">
          <SpaceBetween size="l">
            <div>
              <h1>What is Tika?</h1>
              <Container>
                
                <div>
                  That was a lot of steps, congrats on getting a succeesful
                  deployment! Happy Building!
                  <br />
                  This frontend is based on the Amazon Polaris UI - we used{" "}
                  <a
                    href="https://polaris.a2z.com/resources/polaris/bob_ross/"
                    target="_blank" rel="noreferrer"
                  >
                    Bob Ross
                  </a>{" "}
                  as a template.
                </div>
              </Container>
              <Container>
                <div>

                </div>
              </Container>
            </div>
          </SpaceBetween>
        </Box>
      </TextContent>
    </div>
  )

};
*/


const Tools = () => (
  <HelpPanel header={<h2>Help panel</h2>}>
    <p>Help content goes here</p>
  </HelpPanel>
);
