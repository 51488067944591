import React, { useState, useEffect, useContext } from 'react';
import {
  Header, Box, CounterLink, Link, Grid, BarChart, Container, SpaceBetween, Button, ExpandableSection,
  FormField, Input, Textarea, ColumnLayout, Spinner, Checkbox, Icon, TextContent, 
} from '@amzn/awsui-components-react';

import '../../styles/assessments.scss';
import InsightBlock from './InsightBlock';

export default function InsightCategory({category, globalState})  {


    const categ_questions = [];
    {globalState.role_guidelines.guidelines.filter(c => c.id===category.value).map(g => {
        g.items.map(gi => {
            categ_questions.push(
                <InsightBlock key={gi.id}
                    category={category.label}
                    dateRangeText="Last Month"
                    questionTitle={gi.text}
                    data={{}} 
                />                            
            );
        })
    })}


    return (
        <Container header={<Header variant="h2">{category.label}</Header>} disableContentPaddings>

            <ExpandableSection header="Summary" disableContentPaddings defaultExpanded>
                <InsightBlock 
                    category={category.label}
                    dateRangeText="Last Month"
                    questionTitle={`Global average for ${category.label}`}
                    data={{}} 
                />
            </ExpandableSection>

            <ExpandableSection header="Details" disableContentPaddings>
                {categ_questions}
            </ExpandableSection>


        </Container>
    );
}


