const host = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://" + window.location.hostname; 
 
const awsmobile = { 
    'aws_project_region': 'eu-west-1', 
    'aws_cognito_identity_pool_id':'eu-west-1:343b20b0-f804-4727-af15-74ff7ed17a41', 
    'aws_cognito_region': 'eu-west-1', 
    'aws_user_pools_id': 'eu-west-1_JEGOy7DTK', 
    'aws_user_pools_web_client_id': '6ilfr51b6p8p1pa3qrit66kkgo', 
    'oauth': { 
    'domain': 'tika-testing.auth.eu-west-1.amazoncognito.com', 
    'scope': ['profile', 'openid', 'aws.cognito.signin.user.admin'], 
    'redirectSignIn': window.location.protocol + '//' + window.location.host, 
    'redirectSignOut': window.location.protocol + '//' + window.location.host, 
    'responseType': 'code', 
    }, 
    'federationTarget': 'COGNITO_USER_POOLS',
    'aws_mobile_analytics_app_id': 'baabba2a6c734b85a53876aa6e835ca1', 
    'aws_mobile_analytics_app_region': 'eu-west-1',    
    'API': { 
        'endpoints': [ 
            { 
            'name': 'TikaAPI', 
            'endpoint': 'https://capi.testing.tika.sa.aws.dev/Testing/', 
            } 
        ]
    },
    'defaultRole': '98e01c1b-d4b6-4c84-bb57-504479f9dc65'
}

export default awsmobile;
