import React, { useState, useEffect, useContext, useCallback, useReducer, useRef } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, TextFilter, Pagination, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { DataStoreClass } from '@aws-amplify/datastore';
import {API} from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import ActionItemsTable from './ActionItemsTable';



export default function ActionItemsModal({globalState, modalVisible, setModalVisible, currentItem, updateItemsState})  {

    const tableChildRef = useRef();

    return (
        
        <Modal
          onDismiss={() => tableChildRef.current.closeModal()}
          visible={modalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => tableChildRef.current.closeModal()}>Cancel</Button>
                <Button variant="primary" onClick={()=> {
                  tableChildRef.current.saveActionItems();
                }}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header={<Header description={currentItem && currentItem.guidelineItem ? currentItem.guidelineItem : ''}>Set Action Items</Header>}
        >

              <ActionItemsTable ref={tableChildRef} globalState={globalState} 
                 modalVisible={modalVisible} setModalVisible={setModalVisible}
                 currentItem={currentItem} updateItemsState={updateItemsState} />


        </Modal>

    )
}


