import React, { useState, useEffect, useContext } from 'react';
import {
  Header, Box, CounterLink, Link, Grid, BarChart, Container, SpaceBetween, Button, ExpandableSection,
  FormField, Input, Textarea, ColumnLayout, Spinner, Checkbox, Icon, TextContent, 
} from '@amzn/awsui-components-react';

import '../../styles/assessments.scss';
import ActionItemsTable from '../assessment/ActionItemsTable';

export default function Overview({userPdp, globalState})  {

    const [content, setContent] = useState(<Spinner />);

    const getItemDescription = (categoryId, refItemId) => {
        return globalState.role_guidelines.guidelines
            .filter(c => c.id===categoryId)[0]
            .items.filter(i => i.id===refItemId)[0].text;
    }

    useEffect(() => {
        if (userPdp.length>0 && userPdp[0] != 1){
            const a = [];
            userPdp.forEach(c => {
                let n=0;
                //console.log(c);
                /*const actionItems = c.actionItems.map(t => { 
                    //console.log(t);
                    return (
                        <li key={t.refItemId} style={{marginBottom: '0px'}}>
                             <Grid gridDefinition={[{ colspan: { default: 12, xl: 10 } }]} >
                                <ActionItemsTable globalState={globalState} currentItem={t} 
                                    headerLabel={(t.tags && t.tags.target? "[T] " : "") + getItemDescription(c.category.id, t.refItemId)} />
                            </Grid>
                        </li>
                    )
                }) //end of actionItems */

                const targets =  c.targets.map(t => { 
                    //console.log(t);
                    return (
                        <li key={t.refItemId} style={{marginBottom: '0px'}}>
                             <Grid gridDefinition={[{ colspan: { default: 12, xl: 10 } }]} >
                                <ActionItemsTable globalState={globalState} currentItem={t} 
                                    headerLabel={(t.tags && t.tags.target? "[T] " : "") + getItemDescription(c.category.id, t.refItemId)} />
                            </Grid>
                        </li>
                    )
                }) //end of targets 


                const ais = [];
                /*c.actionItems.filter(ai => !ai.tags.target).map(ai => { 
                    //console.log(ai);
                    const actionItems = ai.tags.actionItems ? ai.tags.actionItems.split('\n') : [];
                    ais.push(...actionItems);
                });*/
                const actionItemsWithoutTarget = <ul className='no-bullets'>
                                                    {ais.map(a => <li key={n++}>
                                                                    <Checkbox>{a}</Checkbox>
                                                                  </li>)}
                                                 </ul>
                a.push(<ExpandableSection
                            variant="container"
                            key={c.category.id}
                            defaultExpanded={true}
                            header={<Header variant="h3">{c.category.label}</Header>}
                            disableContentPaddings
                            >
                            <ul className='no-bullets tight'>
                                {targets}
                            </ul>
                            {ais.length>0 && 
                            <ul>
                                <li>Action items with no target:</li>
                                {actionItemsWithoutTarget}
                            </ul>
                            }

                        </ExpandableSection>);
            });
            setContent(<>{a}</>);

        } else {
            if (userPdp[0] == 1) {
                setContent(<Spinner />);
            } else {
                setContent(<Container>
                                <TextContent><b>You personal development plan is empty, as no items were marked as target.</b></TextContent>
                                <TextContent>Please start by setting items as target in 
                                    &quot;<a href="#/assessment">What you do</a>&quot;
                                    , to see your plan</TextContent>
                            </Container>);
            }
        }
    }, [userPdp]);

    return content;
}


export function OldOverview()  {

    return (
        <Grid
            gridDefinition={[
            { colspan: { l: 12, m: 8, default: 12 } },
            { colspan: { l: 12, m: 6, default: 12 } },
            ]}
        >
        
            <Container
                className="custom-dashboard-container"
                header={
                    <Header variant="h2" description="High level view of your performance data">
                    Performance overview
                    </Header>
                }
                >
                <ColumnLayout columns="4" variant="text-grid">
                    <div>
                        <Box variant="awsui-key-label">Total Achievements</Box>
                        <Link variant="awsui-value-large" href="#/achievements">4</Link>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Achievements Under Review</Box>
                        <Link variant="awsui-value-large" href="#/achievements">2</Link>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Reviewed Achievements</Box>
                        <Link variant="awsui-value-large" href="#/achievements">1</Link>
                    </div>
                    <div>
                        <Box variant="awsui-key-label">Action Items</Box>
                        <Link variant="awsui-value-large" href="#">12</Link>
                    </div>
                </ColumnLayout>
            </Container>

            <PerformanceGraph />

        </Grid>

    )
}

const PerformanceGraph = () => {
    return (
        <Container
            header={<Header variant="h2" description="View performance for each category">
                        Performance categories
                    </Header>
            }
        >
            <BarChart style={{width: '100%', height: '100%'}}
                series={[
                    {
                        title: "Weighted score",
                        type: "bar",
                        data: [
                            { x: 'Customer Advisor', y: 75 },
                            { x: 'Continuous Professionalism', y: 85 },
                            { x: 'Thought Leadership', y: 40 },
                            { x: 'Advancing Organizational Capabilities', y: 38 },
                        ],
                        valueFormatter: e => e + "%"
                    },
                ]}
                xDomain={[
                    'Customer Advisor',
                    'Continuous Professionalism',
                    'Thought Leadership',
                    'Advancing Organizational Capabilities',
                ]}
                yDomain={[0, 101]}
                i18nStrings={{
                    filterLabel: "Filter displayed data",
                    filterPlaceholder: "Filter data",
                    filterSelectedAriaLabel: "selected",
                    legendAriaLabel: "Legend",
                    chartAriaRoleDescription: "line chart",
                    xTickFormatter: undefined,
                    yTickFormatter: undefined
                }}
                ariaLabel="Single data series line chart"
                errorText="Error loading data."
                height={200}
                hideFilter
                horizontalBars
                loadingText="Loading chart"
                recoveryText="Retry"
                xScaleType="categorical"
                xTitle="Guidelines Category"
                yTitle="Weighted score"
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No data available</b>
                    <Box variant="p" color="inherit">
                        There is no data available
                    </Box>
                    </Box>
                }
                noMatch={
                    <Box textAlign="center" color="inherit">
                    <b>No matching data</b>
                    <Box variant="p" color="inherit">
                        There is no matching data to display
                    </Box>
                    <Button>Clear filter</Button>
                    </Box>
                }
            />
        </Container>

    )
}