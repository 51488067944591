import React, { useState, useEffect, useContext, useCallback, useReducer } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, ProgressBar
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT, SET_DELIVERABLE_FILTER, SET_GLOBAL_EXPECTATION } from '../global-state';
import { useNavigate } from "react-router-dom";
import AssessmentCategory from './AssessmentCategory';
import ExpectationModal from './ExpectationModal';
import moment from 'moment';
import {API} from 'aws-amplify';
import ActionItemsModal from './ActionItemsModal';


export default function ExpectationsSimpleTable({category, loading, assessmentId, 
                      categoryItems, categoryCode, setReloadTrigger, setCategoryItems})  {

    const navigate = useNavigate();
    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)

    const [modalVisible,setModalVisible] = useState(false);
    const [expectation,setExpectation] = useState({tags: {}});
    const [modalEditMode,setModalEditMode] = useState(false);
    //for Action Items modal:
    const [activeAssessmentId, setActiveAssessmentId] = useState(null);
    const [activeAssessmentItemId, setActiveAssessmentItemId] = useState(null);
    const [actionItemsModalVisible, setActionItemsModalVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    //end of state for Action Items modal//
    
    //console.log(categoryCode);

    const expectations = categoryItems && categoryItems['CUSTOM_TARGET'] ? 
      categoryItems['CUSTOM_TARGET']
        .filter(e => (e.tags.contributionCategory && e.tags.contributionCategory==categoryCode))
        .sort(compareExpectations)
    : [];
    //console.log('eee',expectations, categoryItems);

    const showActionItemsModal = (expectation) => {
      setActiveAssessmentId(expectation.assessmentId);
      setActiveAssessmentItemId(expectation.id);
      setCurrentItem(expectation);
      setActionItemsModalVisible(true);
    }

    const clearModalStateValues = () => {
      setActiveAssessmentItemId(null);
    }

    const closeModal = () => {
      clearModalStateValues();
      setActionItemsModalVisible(false);
    }


    const updateItemsState = (assessmentItemId, newActionItems) => {
      /*
      const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
      if (itemIndex<=-1) return false;
      categoryItems[category][itemIndex].actionPlan = newActionItems;
      setCategoryItems(cloneCategoryItems(categoryItems));*/
      setReloadTrigger({reload: true});
    }

    function compareExpectations( a, b ) {
      let tsA = new Date(a.tags.dueDate);
      let tsB = new Date(b.tags.dueDate);
      if ( tsA > tsB ) return 1;
      else if ( tsA < tsB ) return -1;
      else return 0;
    }


    
    return (
      <>

        <ActionItemsModal category={category} globalState={globalState}
            categoryItems={categoryItems} setCategoryItems={setCategoryItems}
            modalVisible={actionItemsModalVisible} setModalVisible={setActionItemsModalVisible}
            currentItem={currentItem} updateItemsState={updateItemsState} />     


        <Table
          columnDefinitions={[
            {
              id: "title",
              header: "Title",
              cell: item => 
                <Link onFollow={()=>{
                  setExpectation(item);
                  setModalEditMode(true);
                  setModalVisible(true);
                }}>{item.tags.title}</Link>,
              maxWidth: 400,
            },
            {
              id: "dueDate",
              header: "Due Date",
              cell: item => {
                if (!item.tags.dueDate) return '';
                return moment(item.tags.dueDate).format('ll') + ' (' + moment(item.tags.dueDate).fromNow() + ')';
              },
            },
            {
              id: "openActionItems",
              header: "Open AIs",
              cell: item => {
                const num = item.actionPlan ? item.actionPlan.filter(a => a.status==='open').length : '0';
                return (
                  <Link onFollow={(ex)=>{
                    item.assessmentItemId = item.id;
                    showActionItemsModal(item);
                  }}>{num}</Link>
                )
              }
            },
            {
              id: "doneActionItems",
              header: "Done AIs",
              cell: item => {
                const num = item.actionPlan ? item.actionPlan.filter(a => a.status==='done').length : '0';
                return (
                  <Link onFollow={(e)=>{
                    item.assessmentItemId = item.id;
                    showActionItemsModal(item);
                    //console.log(JSON.parse(JSON.stringify(item)));
                    //dispatch({type: SET_GLOBAL_EXPECTATION, payload: item});
                    //showActionItemsModal(item);
                  }}>{num}</Link>
                )
              }
            },            
            {
              id: "attainment",
              header: "Attainment",
              minWidth: 180,
              cell: item => {
                //console.log(item.supportingDeliverables);
                const deliverablesCount = item.supportingDeliverables ? item.supportingDeliverables.length : 0;
                return (
                  <ProgressBar
                    value={item.tags.attainment ? item.tags.attainment : '0'}
                    additionalInfo={
                        <Link onFollow={(e) => {
                          //console.log(item.supportingDeliverables);
                          dispatch({ type: SET_DELIVERABLE_FILTER, payload: item.supportingDeliverables});
                        }}>{`${deliverablesCount} deliverables associated`}
                        </Link>  
                    }
                  />
                )
              }
            },
            {
              id: "actions",
              header: "Actions",
              cell: item => 
                <ButtonDropdown
                  items={[
                    { id: "actionItems", text: "Action Items" },
                    ... globalState.viewas_user_id ? [] : [{ id: "createDeliverable", text: "Create Deliverable" }],
                    { id: "edit", text: "Edit" },
                    { id: "delete", text: "Delete" },
                  ]}
                  ariaLabel="Actions"
                  variant="icon"
                  expandToViewport
                  onItemClick={ ({detail}) => {
                    //console.log('item', item);
                    switch (detail.id) {
                      case 'edit':
                        setExpectation(item);
                        setModalEditMode(true);
                        setModalVisible(true);
                        break;
                      case 'delete':
                        //console.log(item.assessmentId, item.id );
                        if (confirm('Are you sure you want to delete this Expecttion and all of its Action Items?')){
                          API.del('TikaAPI', 'employees/current/assessments/' + item.assessmentId + '/assessment-items/' + item.id).then(resp => {
                            setReloadTrigger({reload: true});
                          }).catch(error => { alert('Error deleting the expectation (check logs for details)'); console.log(error); });                            
                        }
                        break;                
                      case 'actionItems':
                        item.assessmentItemId = item.id;
                        showActionItemsModal(item);
                          /*const itemIndex =  categoryItems[category].map(e => e.assessmentItemId).indexOf(assessmentItemId);
                          if (itemIndex<=-1) return false;
                          const ap = categoryItems[category][itemIndex];
                          console.log(ap);
                          showActionItemsModal(item.target.assessmentId, item.target.id, ap);8/
                          */
                        /*showActionItemsModal(item.target.assessmentId, item.target.id, currentGuidelineItem, true);*/
                        break;
                      case 'createDeliverable':
                          navigate('/achievements/achievement', {state: {expectationFilter: item.id}});
                          break;
                      default:
                          break;
                    }
                  }}
                />
              }
              
            
          ]}
          items={expectations}
          loading={loading}
          loadingText="Loading expectations"
          empty={
            <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
              >
                No expectations to display.
              </Box>
              <Button disabled={globalState.viewas_user_id} onClick={(event => {
                  setExpectation({tags: {}});
                  setModalEditMode(false);
                  setModalVisible(true);
                })}>Create Expectation</Button>
            </Box>
          }
          header={<Header variant='h3' 
            description={`Your '${category}' expectations` }
            actions={
              <SpaceBetween direction='horizontal' size='l'>
                <Button disabled={globalState.viewas_user_id} variant='primary' onClick={(event => {
                  setExpectation({tags: {}});
                  setModalEditMode(false);
                  setModalVisible(true);
                })
                }>Create Expectation</Button>
              </SpaceBetween>
            }  
          > Expectations </Header>}
        />

        <ExpectationModal assessmentId={assessmentId} modalVisible={modalVisible} 
          setModalVisible={setModalVisible} expectation={expectation} setExpectation={setExpectation}
          setReloadTrigger={setReloadTrigger} category={category} categoryItems={categoryItems}
          editMode={modalEditMode} categoryCode={categoryCode} />

      </>
    )
}

