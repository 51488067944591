import React, { useState, useEffect, useContext, useCallback, useReducer, forwardRef, useImperativeHandle, useLayoutEffect, useRef } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, TextFilter, Pagination, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { DataStoreClass } from '@aws-amplify/datastore';
import {API} from 'aws-amplify';
import { useNavigate } from "react-router-dom";
import { TableHeader } from '../commons/common-components';
import ActionItemModal from './ActionItem';


function compare( a, b ) {

  const getscore = (status) =>{
    switch (status) {
      case 'archived':
        return 3
        break;
      case 'done':
        return 2
        break;
      case 'open':
        return 1
        break;    
      default:
        return 0
        break;
    }
  }
  let a_score = getscore(a.status);
  let b_score = getscore(b.status);
  if ( a_score < b_score ){
    return -1;
  } else if ( a_score> b_score ){
    return 1;
  } else return 0;
}


const ActionItemsTable = forwardRef( ({globalState, modalVisible, setModalVisible, currentItem, updateItemsState, headerLabel}, ref) =>  {

    const isInPdp = updateItemsState==undefined;

    const currentItems = currentItem.actionPlan ? currentItem.actionPlan : [];
    const [tempActionItems, setTempActionItems] = useState(currentItems.sort(compare));
    const [selectedItems, setSelectedItems] = useState([]);
  
    const [addAiModalVisible, setAddAiModalVisible] = useState(false);
    const emptyActionItem = {status: 'open'};
    const [tempActionItem, setTempActionItem] = useState(emptyActionItem);
    const [preloader, setPreloader] = useState(false);
    const [editMode, setEditMode] = useState(false);


    useEffect(() => {
      setTempActionItems(currentItems);
    }, [currentItem.actionPlan]);

    const saveActionItemsImpl = (close=true) => {
      let assessmentItemId = currentItem.assessmentItemId;
      //console.log(currentItem);
      if (isInPdp) {
        assessmentItemId = currentItem.id;
      }
      if ( !globalState.assessmentId || !assessmentItemId ) {
        if (setModalVisible && close) setModalVisible(false);
        return false;
      }
      if (tempActionItems.length==0) {
        //no items - don't issue a message and just close the modal
        if (setModalVisible && close) setModalVisible(false);
        return false;
      }
      setPreloader(true);
      API.put ('TikaAPI', 'employees/current/assessments/' + globalState.assessmentId + '/assessment-items/' + assessmentItemId + '/action-plan', {
        body: tempActionItems
      }).then(a => {
        //update the parent state, so that it will redraw (in assessment view, with the action items icon on the current item)
        if (updateItemsState) updateItemsState(assessmentItemId, tempActionItems);
        setTempActionItem(emptyActionItem);
        if (setModalVisible && close) setModalVisible(false);
        setPreloader(false);
      }).catch(e => {console.log(e); setPreloader(false);})
    }
    
    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
      if (firstUpdate.current) {
        firstUpdate.current = false;
        return;
      }
      //console.log('tempActionItems change!', tempActionItems);
      if (isInPdp) saveActionItemsImpl(); else saveActionItemsImpl(false);

    }, [tempActionItems]);


    useImperativeHandle(ref, () => ({

      closeModal() {
        setTempActionItem(emptyActionItem);
        setModalVisible(false);
      },

      saveActionItems() {
        saveActionItemsImpl();
      }

    }));


    const showAddActionItemModal = (e) => {
      setEditMode(false);
      setTempActionItem(emptyActionItem);
      setAddAiModalVisible(true);
    }

    const addActionItem = () => {
      setEditMode(false);
      
      /*setTempActionItems([...tempActionItems, tempActionItem], x => {
        saveActionItemsImpl(false);
      });*/
      setTempActionItems([...tempActionItems, tempActionItem]);
      saveActionItemsImpl(false);

      setTempActionItem(emptyActionItem);
      setAddAiModalVisible(false);
    }

    const editActionItem = () => {
      var foundIndex = tempActionItems.findIndex(x => x.text === tempActionItem.origText);
      if (foundIndex<=-1) {
        console.log("error - trying to edit but can't find original action item text (which acts as ID)")
        return;
      }
      tempActionItems[foundIndex] = tempActionItem;
      /*setTempActionItems([...tempActionItems], x => {
        saveActionItemsImpl(false);
      });*/
      setTempActionItems([...tempActionItems]);
      saveActionItemsImpl(false);

      setSelectedItems([]);
      setAddAiModalVisible(false);
    }


    const cloneObject = (ci) => {
      let newObj = {};
      for (var prop in ci) {
        if (Object.prototype.hasOwnProperty.call(ci, prop)) {
          newObj = {...newObj, [prop]: [...ci[prop]]};
        }
      }
      return newObj;
    }


    const markArchived = () => {
      if (confirm('Are you sure you want to archive this Action Item?')) setStatus('archived');
    }

    const markDone = () => {
      setStatus('done');
    }

    const setStatus = (status) => {
      const newItems = [];
      selectedItems.forEach(e => {
        e.status = status;
        newItems.push(cloneObject(e));
      });
      setSelectedItems(newItems);
      setTempActionItems(tempActionItems);
      saveActionItemsImpl(false);
    }

    const editItem = (selectedItem) => {
      setEditMode(true);
      selectedItem.origText = selectedItem.text;
      setTempActionItem(selectedItem);
      setAddAiModalVisible(true);
    }

    return (
        
        <>

              <ActionItemModal addAiModalVisible={addAiModalVisible} setAddAiModalVisible={setAddAiModalVisible}
                              tempActionItem={tempActionItem} setTempActionItem={setTempActionItem}
                              addActionItem={addActionItem} editActionItem={editActionItem} edit={editMode} />

              {preloader ? <Spinner /> : 
              
              <Table
                variant="borderless"
                onSelectionChange={({ detail }) => {
                    setSelectedItems(detail.selectedItems);
                    //setTempActionItem(detail.selectedItems[0]);
                  }
                }
                selectedItems={selectedItems}
                ariaLabels={{
                  selectionGroupLabel: "Items selection",
                  allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${
                      selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                  itemSelectionLabel: ({ selectedItems }, item) => {
                    const isItemSelected = selectedItems.filter(
                      i => i.name === item.name
                    ).length;
                    return `${item.name} is ${
                      isItemSelected ? "" : "not"
                    } selected`;
                  }
                }}
                columnDefinitions={[
                  {
                    id: "text",
                    header: "Action Item",
                    cell: e => {
                      let res = <div>{e.text}</div>;
                      if  (e.status=='open') res = <div><b>{e.text}</b></div>;
                      if  (e.status=='archived') res = <div><strike>{e.text}</strike></div>;
                      return res;
                    },
                    sortingField: "text"
                  },
                  {
                    id: "status",
                    header: "Status",
                    cell: e => {
                      let color = 'blue';
                      switch (e.status) {
                        case 'done':
                          color = 'green';
                          break;
                        case 'archived':
                          color = 'grey';
                          break;
                        default:
                          break;
                      }
                      return (<Badge color={color}>{e.status}</Badge>)
                    },
                    sortingField: "status"
                  },
                  {
                    id: "dueDate",
                    header: "Due Date",
                    cell: e => e.dueDate,
                    sortingField: "dueDate"
                  }
                ]}
                items={tempActionItems.filter(e => e.status&&e.status!='archived')}
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="text"
                visibleColumns={[
                  "text",
                  "status",
                  "dueDate",
                ]}
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No action items</b>
                    <Box
                      padding={{ bottom: "s" }}
                      variant="p"
                      color="inherit"
                    >
                      No action items to display.
                    </Box>
                    <Button onClick={showAddActionItemModal}>Add Action Item</Button>
                  </Box>
                }
                header={

                  <TableHeader
                    variant="awsui-h1-sticky"
                    title= {headerLabel? headerLabel : "Action Items"}
                    counter={
                      selectedItems.length
                        ? "(" + selectedItems.length + "/" + tempActionItems.length + ")"
                        : ""
                    }
                    actionButtons={
                      <SpaceBetween size="xs" direction="horizontal">
                        <Button disabled={selectedItems.length!=1}  onClick={() => {
                          editItem(selectedItems[0])}
                         }>Edit</Button>
                        <Button disabled={selectedItems.length==0} onClick={markDone}>Mark Done</Button>
                        <Button disabled={selectedItems.length==0}  onClick={markArchived}>Delete</Button>
                        <Button variant="primary" onClick={showAddActionItemModal}>Add</Button>
                      </SpaceBetween>
                    }
                  />
                  
                }
                
                
              /> }


        </>

    )
});

export default ActionItemsTable;
