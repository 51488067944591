import React, { useState, useEffect, useContex, forwardRef, useImperativeHandle } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, SpaceBetween, Button, FormField, Input, Textarea, Tabs, Select, Spinner, TextContent, 
} from '@amzn/awsui-components-react';
import {API} from 'aws-amplify';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { toSelectOption } from '../commons/utils';
import { useParams, useLocation } from "react-router-dom";
import '../../styles/servicehomepage.scss';
import { ACHIEVEMENT_REVIEW_STATUS_CODES } from '../commons/common-data';
import { v4 as uuidv4 } from 'uuid';
import { getStatusCell } from '../achievements/table-config';

const Review = forwardRef( ({achievement, setAchievment, globalState}, ref) => {
    
    //set selected status value
    const status = achievement.status ? achievement.status : 'UNREVIEWED';
    const [review, setReview] = useState({status: status});
    const [loading, setLoading] = useState(false);

    const isManagerViewing = achievement && globalState.user && achievement.owner != globalState.user.userNameClean();

    useImperativeHandle(ref, () => ({getReviewState: () => {return review}}), [review]);

    useEffect(() => {
      if (achievement.reviewId){
        setLoading(true);
        API.get('TikaAPI', 'reviews/' + achievement.reviewId).then(response => {
          if (response && response.Item){
            setReview({...review, ...response.Item});
            setAchievment({...achievement, reviewId: response.Item.id});
            setLoading(false);
          };
        }).catch(error => {console.error(error); setLoading(false);});
      }
    }, [achievement.reviewId]);

    const toOption = (val) => {
      return {
        value: val, 
        label: val,
      }
    }


    /** If a manager is veiwing, and the achievement is un-unreviewed, then create a new review automatically. */
    useEffect(() => {
      if (isManagerViewing && achievement.status === 'UNREVIEWED'){
        createReview();
      }
    }, [achievement.status]);



    const createReview = () => {
      //create a new ID, create a new review object with the reviews API, and update the achievement with this review ID, and then 
      setLoading(true);
      const newReviewId = uuidv4();
      API.post('TikaAPI', 'reviews/' + newReviewId).then(response => {
        let url = 'achievements/' + achievement.id + '/update-review-id?review_id=' + newReviewId;
        if (isManagerViewing) {
          url += '&owner=' + achievement.owner;
        }
        API.put('TikaAPI', url).then(response => {
          setAchievment({...achievement, reviewId: newReviewId, status: 'UNDER_REVIEW'});
          setLoading(false);
        }).catch(error => {console.error(error); setLoading(false);});
      }).catch(error => {console.error(error); setLoading(false);});

    }

    const handleFormSubmit = (event) => {
      event.preventDefault();
  

      API.put('TikaAPI', 'reviews/' + achievement.reviewId, {body: review}).then(response => {
        console.log(response);
      }).catch(error => console.error(error));

    }

    const setStatus = (status) => {
      let url = 'achievements/' + achievement.id + '/update-status?status=' + status;
      if (isManagerViewing) {
        url += '&owner=' + achievement.owner;
      }
      API.put('TikaAPI', url).then(response => {
        setAchievment({...achievement, status: status});
      }).catch(error => console.error(error));
    }

    //check if this is a manager vieweing, and if not reviewed - create  a new review

    let content = <SpaceBetween size='xl'>
                    <div>This achievement was not reviewed yet. Click on the &quot;Start Review&quot; button below to start a new review.</div>
                    <Button variant='primary' onClick={() => createReview()}>Start Review</Button>
                  </SpaceBetween>;
    
    if (achievement.status !== 'UNREVIEWED') {
      content = 
        <SpaceBetween direction='vertical' size='l'>

          <FormField label="Reviewer Feedback" description="Feedback from your direct manager" >
            <Textarea
                placeholder="Reviewer feedback text"
                value={review.reviewerFeedbacks}
                onChange={event => setReview({ ...review, reviewerFeedbacks: event.detail.value })}
            />
          </FormField>

          <FormField label="Action Items" description="Next steps and action items for this review" >
            <Textarea
                placeholder="Action items"
                value={review.actionItems}
                onChange={event => setReview({ ...review, actionItems: event.detail.value })}
            />
          </FormField>

          <FormField label="Lessons Learned" description="Specific areas of knowledge gained by this experience." >
            <Textarea
                placeholder="Lessons Learned"
                value={review.areasOfImprovements}
                onChange={event => setReview({ ...review, areasOfImprovements: event.detail.value })}
            />
          </FormField>

          {isManagerViewing && 
            <SpaceBetween direction='horizontal' size='l'>
              <Button variant='primary' disabled={achievement.status==='PROMOTED' || achievement.status==='NON_SUPPORTING'} iconName="status-positive" onClick={(e) => {
                e.preventDefault();
                setStatus('PROMOTED');
              }}>Achievement supports claims</Button>
              <Button variant='normal' disabled={achievement.status==='PROMOTED' || achievement.status==='NON_SUPPORTING'} onClick={(e) => {
                e.preventDefault();
                setStatus('NON_SUPPORTING');
              }}>Achievement does not support claims</Button>
            </SpaceBetween>
          }

        </SpaceBetween>



    }

    if (loading) content = <SpaceBetween size='s' direction='horizontal'>
          <Spinner />
          <TextContent>Loading review</TextContent>
        </SpaceBetween>

    return (
        <>
          
          {content}

          </>
    )

});

export default Review;

