import React, { useState, useEffect, useContext, useCallback, useReducer, useRef } from 'react';
import {
  Header, ColumnLayout, Grid, Cards, Link, Box, Container, SpaceBetween, Button, ButtonDropdown, Input,
  Textarea, Tabs, Badge, TextContent, Icon, Modal, FormField, Form, Spinner, Table, TextFilter, Pagination, 
} from '@amzn/awsui-components-react';
import '../../styles/assessments.scss';
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT } from '../global-state';
import { DataStoreClass } from '@aws-amplify/datastore';
import {API} from 'aws-amplify';
import { useNavigate } from "react-router-dom";



export default function TargetModal({assessmentId, modalVisible, setModalVisible, customTarget, setCustomTarget, setReloadTrigger})  {

    const [err1, setErr1] = useState('');
    const [loading, setLoading] = useState(false);

    const closeModal = () => {
      setErr1('');
      setModalVisible(false);
    };

    const addTarget = () => {
      const assessmentItems = [
        {
          refItemId: "CUSTOM_TARGET",
          tags: {
            'custom-target': true,
            'title':  customTarget.text
          },
          rank: {
            score: 5
           },
        }
      ];
      
      setLoading(true);
      API.post('TikaAPI', 'employees/current/assessments/' + assessmentId + '/assessment-items', {body: assessmentItems}).then(items => {
        setLoading(false);
        //reload, to refresh with the new item
        setReloadTrigger({reload: true});
        closeModal();
      }).catch(error => { setLoading(false); alert('Error creating the custom target (check logs for details)'); console.log(error); });

    }

    const editTarget = () => {
      //console.log(customTarget.id, customTarget.assessmentId);
      const change = {
        tags: {
          'custom-target': true,
          'title':  customTarget.text
        }
      };
      setLoading(true);
      API.patch('TikaAPI', 'employees/current/assessments/' + customTarget.assessmentId + '/assessment-items/' + customTarget.id, { body: change }).then(resp => {
        setLoading(false);
        //reload, to refresh with the updated item
        setReloadTrigger({reload: true});
        closeModal();
      }).catch(error => { setLoading(false); alert('Error updating the custom target (check logs for details)'); console.log(error); });
  
    }

    return (
        <Modal
          onDismiss={() => closeModal()}
          visible={modalVisible}
          closeAriaLabel="Close modal"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => closeModal()}>Cancel</Button>
                <Button variant="primary" onClick={()=> {

                  if (!customTarget.text){
                    setErr1('Please provide the text that describes your target');
                    return;
                  } else {
                    setErr1('');
                  }
                  
                  (customTarget.id && customTarget.assessmentId) ? editTarget() : addTarget();

                }}>Ok</Button>
              </SpaceBetween>
            </Box>
          }
          header={<Header description="Create a new custom target">Create Target</Header>}
        >

            {loading ? <Spinner />
             :            
              <Container>
                <SpaceBetween direction="vertical" size="l">

                  <FormField label="Custom Target" description="Text that describes your custom target" errorText={err1}>
                    <Input placeholder="Custom target text" value={customTarget.text} onChange={(e) =>{
                      setCustomTarget({...customTarget, text: e.detail.value })
                    }} />
                  </FormField>

                </SpaceBetween>

              </Container>
            }

        </Modal>

    )
}


