import React from "react";
import PropTypes from "prop-types";

// Set up global contexts
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

// Actions
export const SET_USER_GLOBAL = "SET_USER_GLOBAL";
export const SET_GLOBAL_ALERT = "SET_GLOBAL_ALERT";
export const SET_ROLE_GUIDELINES = "SET_ROLE_GUIDELINES";
export const SET_DIRECT_REPORTS = "SET_DIRECT_REPORTS";
export const SET_ASSESSMENT_ID = "SET_ASSESSMENT_ID";
export const SET_VIEWAS_USER_ID = "SET_VIEWAS_USER_ID";
export const SET_CURRENT_ROLE = "SET_CURRENT_ROLE";
export const SET_ROLE_GUIDELINES_OTHER_ROLE = "SET_ROLE_GUIDELINES_OTHER_ROLE";
export const SET_DELIVERABLE_FILTER = "SET_DELIVERABLE_FILTER";
export const CLEAR_DELIVERABLE_FILTER = "CLEAR_DELIVERABLE_FILTER";
export const SET_GLOBAL_EXPECTATION = "SET_GLOBAL_EXPECTATION";
export const SET_GLOBAL_CATEGORIES = "SET_GLOBAL_CATEGORIES";

// Reducer
export const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_GLOBAL:
      return {
        ...state,
        user: payload
      };
      break;
    case SET_GLOBAL_ALERT:
      return {
        ...state,
        alerts: payload
      };
      break;
    case SET_ROLE_GUIDELINES:
      return {
        ...state,
        /*role_guidelines: {
          ...state.role_guidelines,
          [payload.roleLevel]: payload.data,
        }*/
        role_guidelines: payload.data,
        roleGuidelinesDefaultRoleLevel: payload.roleLevel,
      };
      break;
    case SET_DIRECT_REPORTS:
      return {
        ...state,
        directReports: payload
      };
      break;
    case SET_ASSESSMENT_ID:
      return {
        ...state,
        assessmentId: payload
      };
      break;
    case SET_VIEWAS_USER_ID:
      return {
        ...state,
        viewas_user_id: payload
      };
      break;
    case SET_CURRENT_ROLE:
      return {
        ...state,
        current_role: payload
      }; 
      break;
    case SET_ROLE_GUIDELINES_OTHER_ROLE:
      return {
        ...state,
        otherRoleGuidelines: {
          ...state.otherRoleGuidelines,
          [payload.roleLevel]: payload.data,
        }
      };
      break;    // Add more here!
    case SET_DELIVERABLE_FILTER:
      return {
        ...state,
        deliverableFilter: {ids: payload}
      }
      break;
    case CLEAR_DELIVERABLE_FILTER:
      return {
        ...state,
        deliverableFilter: {}
      }
      break;
    case SET_GLOBAL_EXPECTATION:
      return {
        ...state,
        globalExpectation: payload.data,
      }
      break;
    case SET_GLOBAL_CATEGORIES:
      return {
        ...state,
        globalCategories: payload,
      }
      break;
    default:
      return state;
  }
};

function GlobalState(props) {
  const { initialState, dispatch } = props;
  return (
    <GlobalStateContext.Provider value={initialState}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {props.children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

GlobalState.propTypes = {
  // The state returned from setting up the reducer using the React Hook `useReducer`.
  initialState: PropTypes.object.isRequired,

  // The dispatch function returned from setting up the reducer using the React Hook `useReducer`.
  dispatch: PropTypes.func.isRequired,

  children: PropTypes.node
};

export default GlobalState;