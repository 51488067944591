import React, { useState, useEffect, useContext } from 'react';
import {
  Header, AppLayout, CollectionPreferences, HelpPanel, Form, Flashbar, Container, 
  SpaceBetween, Button, FormField, Input, Textarea, Tabs, Link, Spinner, TextContent, ProgressBar, Badge, ColumnLayout, Box, 
} from '@amzn/awsui-components-react';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalStateContext, GlobalDispatchContext, SET_GLOBAL_ALERT, SET_ROLE_GUIDELINES_OTHER_ROLE } from '../global-state';
import {API} from 'aws-amplify';
import moment from 'moment';
import '../../styles/assessments.scss';

export default function CounterDashboard({stats, userPdp, setPdpData})  {

    const [filtered, setFiltered] = useState('');
    const [origData, setOrigData] = useState([]);


    const globalState = useContext(GlobalStateContext)
    const isManager = globalState.user && (globalState.user.isManager() || globalState.user.isAdmin());
    let employeeId = isManager ? globalState.viewas_user_id : null;
    if (!employeeId) employeeId = globalState.user.userNameClean();

    useEffect(() => {
    
    }, []);



    const filter = (event) => {
        event.preventDefault();
        let pdp = [];
        let f = [];
        const src = event.currentTarget.id;
        switch (src) {
            case 'ear':
                //filter expectations at risk
                f = JSON.parse(JSON.stringify(userPdp));
                f.filter(e => e.category.id == 'Custom Targets');
                //check if t.tags.dueDate is in 30 days
                if (f.length==0) break;
                //§let a = t.tags.attainment ? t.tags.attainment : 0;
                f[0].targets = f[0].targets.filter(t => 
                        //parseInt(t)<30 &&
                        parseInt(t.tags.attainment || 0)<30 && 
                        t.tags.dueDate && moment(t.tags.dueDate).isBefore(moment().add(30, 'days'))
                    );
                pdp = f;
                break;
            case 'uai':
                //filter upcoming action items
                f = JSON.parse(JSON.stringify(userPdp));
                f.filter(e => e.category.id == 'Custom Targets');
                //check if t.tags.dueDate is in 30 days
                if (f.length==0) break;
                f[0].targets = f[0].targets.filter(t => 
                            t.actionPlan && 
                            t.actionPlan.filter(a => 
                                a.status && a.status!='done' && a.status!='archived' && 
                                a.dueDate && 
                                moment(a.dueDate).isAfter(moment()) &&
                                moment(a.dueDate).isBefore(moment().add(30, 'days'))
                            ).length>0
                        );
                //we have all expectations with an action plan that has upcoing. Now actually filter only the relevant action items
                f[0].targets.forEach(t => {
                    t.actionPlan = t.actionPlan.filter(a => 
                                a.status && a.status!='done' && a.status!='archived' && 
                                a.dueDate && 
                                moment(a.dueDate).isAfter(moment()) &&
                                moment(a.dueDate).isBefore(moment().add(30, 'days'))
                            );
                });
                pdp = f;
                break;
            case 'oai':
                //filter overdure action items
                f = JSON.parse(JSON.stringify(userPdp));
                f.filter(e => e.category.id == 'Custom Targets');
                if (f.length==0) break;
                f[0].targets = f[0].targets.filter(t => 
                            t.actionPlan && 
                            t.actionPlan.filter(a => 
                                a.status && a.status=='open' && 
                                a.dueDate && 
                                moment(a.dueDate).isBefore(moment())
                            ).length>0
                        );
                //we have all expectations with an action plan that has overdue items. Now actually filter only the relevant action items
                f[0].targets.forEach(t => {
                    t.actionPlan = t.actionPlan.filter(a => 
                                a.status && a.status=='open' && 
                                a.dueDate && 
                                moment(a.dueDate).isBefore(moment())
                            );
                });

                pdp = f;
                break;                
            default:
                break;
        }

        //console.log('BEFORE',filtered, userPdp.length, pdp.length>0&&pdp[0].targets ? pdp[0].targets.length : 0);
        if (filtered===src){
            //clear the filter
            setFiltered('');
            pdp = JSON.parse(JSON.stringify(origData));
        } else if (filtered==''){
            //apply filter
            setFiltered(src);
            setOrigData(JSON.parse(JSON.stringify(userPdp)));
        } else {
            //filter is not empty and is not our ID - apply a new filter with this ID
            setFiltered(src);
            //origData should have the whole list
            const p = JSON.parse(JSON.stringify(origData));
        }
        //console.log('AFTER',filtered, userPdp.length, pdp.length, pdp.length>0&&pdp[0].targets ? pdp[0].targets.length : 0);
        setPdpData(JSON.parse(JSON.stringify(pdp)));

    }

    let content = <Container header={ <Header variant="h2">Counters</Header> } >
                    <Spinner />
                </Container>
    
    if (stats && stats.stats){
        content = 
            <Container header={<Header variant="h2">Insights</Header>} >
                <ColumnLayout columns={4}>
                    <div style={filtered!=''&&filtered != 'ear' ? { opacity: '0.25' } : {}}>
                        <Box variant="awsui-key-label">Expectations at Risk</Box>
                        <div id="ear" onClick={(e) => filter(e)}>
                            <Link variant="awsui-value-large">
                                {stats.stats.expectationsAtRisk}
                            </Link>
                        </div>
                    </div>
                    <div style={filtered!=''&&filtered != 'uai' ? { opacity: '0.25' } : {}}>
                        <Box variant="awsui-key-label">Upcoming Action Items</Box>
                        <div id="uai" onClick={(e) => filter(e)}>
                            <Link variant="awsui-value-large" href="#">
                                {stats.stats.upcomingActionItems}
                            </Link>
                        </div>
                    </div>
                    <div style={filtered!=''&&filtered != 'oai' ? { opacity: '0.25' } : {}}>
                        <Box variant="awsui-key-label">Overdue Action Items</Box>
                        <div id="oai" onClick={(e) => filter(e)}>
                            <Link variant="awsui-value-large" href="#">
                                {stats.stats.overdueActionItems}
                            </Link>
                        </div>
                    </div>
                </ColumnLayout>
            </Container>
    }
    

    return (
        <div style={{paddingBottom: 10}}>
            {content}
        </div>
    )
}
