import React, { useState, useEffect, useContext } from 'react';
import {
  Header, Box, CounterLink, Link, Grid, BarChart, Container, SpaceBetween, Button, ExpandableSection,
  FormField, Input, Textarea, ColumnLayout, Spinner, Checkbox, Icon, TextContent, 
} from '@amzn/awsui-components-react';

import '../../styles/assessments.scss';

export default function InsightBlock({category, dateRangeText, questionTitle, data, globalState})  {

    return (
      <div style={{marginLeft: 20, marginTop: 5, marginRight: 20}}>
        <Container>
            <div style={{paddingLeft: 10}}>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 3 }, { colspan: 3 }]} >
                    <div style={{paddingLeft: 10, paddingTop: 10}}>
                        <SpaceBetween direction='horizontal' size='l'>
                            <TextContent><small>{category}</small></TextContent>
                            <TextContent><small>{dateRangeText}</small></TextContent>
                        </SpaceBetween>
                        <TextContent><h4>{questionTitle}</h4></TextContent>
                    </div>

                    <div>
                        <SpaceBetween direction='vertical' size='xxs'>
                            <PercentageBox number="14%" text="above" />
                            <TextContent><b>3.7</b> mean score</TextContent>
                        </SpaceBetween>
                    </div>

                    <div>
                        <AnswerDistributionBarChart />
                    </div>

                </Grid>
            </div>
        </Container>  
      </div>
    );
}

const PercentageBox = ({number, text}) => {
    return (
        <div className="mybox">
            <Box variant="awsui-key-label">{number}</Box>
            <Box variant="strong">{text}</Box>
        </div>
    )
}

const AnswerDistributionBarChart = () => {
    return (
        <BarChart
            hideFilter
            hideLegend
            series={[
                {
                    title: "My Team",
                    type: "bar",
                    data: [
                        { x: 1, y: 1 },
                        { x: 2, y: 0 },
                        { x: 3, y: 2 },
                        { x: 4, y: 5 },
                        { x: 5, y: 4 }
                    ],
                    valueFormatter: e => e.toLocaleString("en-US") + " People"
                },
            ]}
            xDomain={[
                1,2,3,4,5
            ]}
            yDomain={[0, 5]}
            height={100}
            loadingText="Loading chart"
            xScaleType="categorical"
            empty={
                <Box textAlign="center" color="inherit">
                <b>No data available</b>
                <Box variant="p" color="inherit">
                    There is no data available
                </Box>
                </Box>
            }
            noMatch={
                <Box textAlign="center" color="inherit">
                <b>No matching data</b>
                <Box variant="p" color="inherit">
                    There is no matching data to display
                </Box>
                <Button>Clear filter</Button>
                </Box>
            }
        />
    )

}