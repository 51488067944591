import React, { useContext } from 'react';
import { BreadcrumbGroup, SideNavigation } from '@amzn/awsui-components-react';
import { useNavigate } from "react-router-dom";
import { GlobalStateContext } from '../global-state'

import Cookies from 'js-cookie';


export const Breadcrumbs = (props) => (
  <BreadcrumbGroup
    ariaLabel="Breadcrumbs"
    items={[
      { text: 'Tika', href: '/' },
      ...props.items
    ]}
    onFollow={onFollowHandler.bind(this)}
  />
);

const onFollowHandler = (ev) => {
  //ev.preventDefault();
  //console.log("Navigate!", ev);
  //navigate(ev.detail, { replace: true }); 
};


export const Navigation = props => {
  const navigate = useNavigate();
  const globalState = useContext(GlobalStateContext)
  
  const [appActive, setAppActive] =  React.useState(Cookies.get('currentRoleName')!=undefined);

  let assessmentLink = '#/assessment';
  /*if (globalState.directReports && globalState.viewas_user_id){
    assessmentLink = `#/assessment/reports/${globalState.viewas_user_id}`;
  }*/

  let items = [
    { type: "link", text: "My dashboard", href: assessmentLink},
    { type: "link", text: "My plan", href: "#/pdp"},
    { type: "link", text: "My key deliverables", href: "#/achievements"},
  ];
  if (globalState.directReports){
    items.push({ type: "divider" });
    items.push( {
      type: "section",
      text: "Your Team",
      items: [
        { type: "link", text: "Insights", href: "#/manager/insights"},
        { type: "link", text: "My Team", href: "#/manager/people"},
        { type: "link", text: "Deliverables", href: "#/achievements/reports"},
        /*{ type: "link", text: "Employee Achievements", href: "#/achievements/reports"},
        { type: "link", text: "Employee Dashboard", href: "#/assessment/reports"},*/
      ]
    });
  };

  if (!appActive || !globalState.assessmentId) items=[];

  return (
    <SideNavigation
      header={{ text: "Amazon TIKA", href: "#/" }}
      items={items}
      activeHref={ props.activeHref ? props.activeHref : "/"}
      onFollow={ (ev) => {
        //console.log("Navigate!", ev, ev.detail.href);
        navigate(ev.detail.href, { replace: true }); 
      }}
    />
  )
};


